import React from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { DateUtils } from "utilities/date-utils";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { PhoneNumberUtils } from "utilities/phone-number-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { StringUtils } from "utilities/string-utils";
import { TimeZoneUtils } from "utilities/time-zone-utils";
import { t } from "utilities/localization/t";
import { useProvider } from "utilities/contexts/use-provider-context";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./user-providers-info-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserProviderInfoPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-providers-info-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserProviderInfoPage: React.FC<UserProviderInfoPageProps> = validatePageAccess(
    AccessControlKeys.UserProviderInfoPage
)((): JSX.Element => {
    const { record: provider } = useProvider();
    const locationIsEmpty = !(
        StringUtils.hasValue(provider.locationName) ||
        StringUtils.hasValue(provider.addressLineOne) ||
        StringUtils.hasValue(provider.addressLineTwo) ||
        StringUtils.hasValue(provider.city) ||
        StringUtils.hasValue(provider.state) ||
        StringUtils.hasValue(provider.zipCode) ||
        StringUtils.hasValue(provider.country)
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("enProviderInformation")}
                        </Heading>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                            {t("systemInformation")}
                        </Heading>
                        <div
                            className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__information`}>
                            <div>
                                <FormFieldReadonly label={t("netsuiteID")}>
                                    {StringUtils.valueOrDefault(provider?.netsuiteId)}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("lmsID")}>
                                    {provider?.id ?? "--"}{" "}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("dateCreated")}>
                                    {provider?.createdOn == null
                                        ? "--"
                                        : `${DateUtils.formatDateCustom(
                                              provider?.createdOn?.toString(),
                                              t("shortDateFormat")
                                          )}`}
                                </FormFieldReadonly>
                            </div>
                        </div>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                            {t("defaultEventInformation")}
                        </Heading>
                        <div
                            className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__information`}>
                            <div>
                                <FormFieldReadonly label={t("defaultEventContactInfo")}>
                                    {StringUtils.valueOrDefault(provider.name)}
                                    <br />
                                    {StringUtils.valueOrDefault(provider.email)}
                                    <br />
                                    {StringUtils.valueOrDefault(
                                        PhoneNumberUtils.formatPhoneNumber(provider.phoneNumber)
                                    )}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("defaultEventLocation")}>
                                    {locationIsEmpty && <>--</>}
                                    {!locationIsEmpty && (
                                        <>
                                            {StringUtils.hasValue(provider.locationName) && (
                                                <>
                                                    {provider.locationName}
                                                    <br />
                                                </>
                                            )}
                                            {StringUtils.hasValue(provider.addressLineOne) && (
                                                <>
                                                    {provider.addressLineOne}
                                                    <br />
                                                </>
                                            )}
                                            {StringUtils.hasValue(provider.addressLineTwo) && (
                                                <>
                                                    {provider.addressLineTwo}
                                                    <br />
                                                </>
                                            )}

                                            {(provider.city ||
                                                provider.state ||
                                                provider.zipCode) && (
                                                <>
                                                    {StringUtils.hasValue(provider.city)
                                                        ? `${provider.city}
                                                ${
                                                    StringUtils.hasValue(provider.state) ||
                                                    StringUtils.hasValue(provider.zipCode)
                                                        ? ", "
                                                        : ""
                                                }`
                                                        : ""}
                                                    {StringUtils.hasValue(provider.state)
                                                        ? provider.state + " "
                                                        : ""}
                                                    {StringUtils.hasValue(provider.zipCode)
                                                        ? provider.zipCode
                                                        : ""}
                                                    <br />
                                                </>
                                            )}
                                            {StringUtils.hasValue(provider.country)
                                                ? provider.country
                                                : ""}
                                        </>
                                    )}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("defaultEventTimezone")}>
                                    {StringUtils.valueOrDefault(
                                        TimeZoneUtils.formatTimeZone(provider?.timeZone)
                                    )}
                                </FormFieldReadonly>
                            </div>
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserProviderInfoPage };

// #endregion Exports
