import RadioButton, {
    RadioButtonLabelPosition,
} from "components/form/inputs/radio-button-input/radio-button/radio-button";
import React, { useCallback, useState } from "react";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import {
    ActiveStatusMap,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import {
    CatalogEnrollmentErrorType,
    CatalogEnrollmentErrorTypeDisplayNames,
} from "models/enumerations/enrollments/catalog-enrollment-log";
import { CatalogEnrollmentLogRecord } from "models/view-models/enrollments/catalog-enrollment-log-record";
import {
    CatalogEnrollmentStatus,
    CatalogEnrollmentStatusDisplayNames,
} from "models/enumerations/enrollments/catalog-enrollment-status";
import { CollectionUtils } from "andculturecode-javascript-core";
import { DataTable } from "components/tables/data-table/data-table";
import { DateUtils } from "utilities/date-utils";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Pager } from "components/pager/pager";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import {
    PublishStatusDisplayNames,
    PublishStatusMap,
} from "models/enumerations/publish-status/publish-status";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";
import { useCatalogEnrollmentLogs } from "utilities/hooks/models/enrollments/use-catalog-enrollment-logs";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./catalog-enrollment-log-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminCatalogEnrollmentLogPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "catalog-enrollment-log-page";
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminCatalogEnrollmentLogPage: React.FC<AdminCatalogEnrollmentLogPageProps> =
    validatePageAccess(AccessControlKeys.AdminCatalogEnrollmentLogPage)((): JSX.Element => {
        const [currentPage, setCurrentPage] = useState(1);

        const {
            catalogEnrollmentLogs: logs,
            setCatalogEnrollmentLogs,
            rowCount: totalLogs,
            handleStatusChange,
            handleErrorTypeChange,
            status,
            errorType,
            onClearFilterClick,
            isLoading,
            updateRecord,
        } = useCatalogEnrollmentLogs({
            skip: (currentPage - 1) * ITEMS_PER_PAGE,
            take: ITEMS_PER_PAGE,
        });

        const onPageClick = (pageNumber: number) => {
            setCurrentPage(pageNumber);
        };

        const updateStatus = useCallback(
            (
                catalogEnrollmentLog: CatalogEnrollmentLogRecord,
                status: CatalogEnrollmentStatus,
                idx: number
            ) => {
                const updatedLog = catalogEnrollmentLog.with({ status });
                const newLogs = [...logs];
                newLogs[idx] = updatedLog;

                setCatalogEnrollmentLogs(newLogs);
                updateRecord(updatedLog);
            },
            [logs, setCatalogEnrollmentLogs, updateRecord]
        );

        return (
            <div className={CSS_CLASS_NAME}>
                <HeaderBanner title={t("catalogEnrollmentLog")}></HeaderBanner>
                <SideContentLeftLayout
                    sidebarElement={
                        <div className={`${CSS_CLASS_NAME}__sidebar`}>
                            <Paragraph style={ParagraphStyle.Label}>{t("filterResults")}</Paragraph>
                            <FormSelect
                                disabled={isLoading}
                                ariaLabelledBy={t("filterByStatus")}
                                formFieldName={t("filterByStatus")}
                                id="userFilterByStatus"
                                label={t("filterByStatus")}
                                onChange={handleStatusChange}
                                options={[
                                    ...EnumUtils.numericEnumToSelectOptions(
                                        CatalogEnrollmentStatus,
                                        CatalogEnrollmentStatusDisplayNames
                                    ),
                                ]}
                                value={status?.toString()}
                            />
                            <FormSelect
                                disabled={isLoading}
                                ariaLabelledBy={t("filterByError")}
                                formFieldName={t("filterByError")}
                                id="userFilterByError"
                                label={t("filterByError")}
                                onChange={handleErrorTypeChange}
                                options={[
                                    ...EnumUtils.numericEnumToSelectOptions(
                                        CatalogEnrollmentErrorType,
                                        CatalogEnrollmentErrorTypeDisplayNames
                                    ),
                                ]}
                                value={errorType?.toString()}
                            />
                            <Button
                                disabled={false}
                                onClick={onClearFilterClick}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Quaternary}
                                text={t("clearAllFilters")}
                            />
                        </div>
                    }>
                    <div className="content-wrap">
                        <div className="content">
                            <SkipNavContent>
                                <DataTable>
                                    <thead>
                                        <tr>
                                            <th className="account">{t("account")}</th>
                                            <th className="orderInfo">{t("orderInfo")}</th>
                                            <th className="erpId">{t("erpId")}</th>
                                            <th className="product">{t("product")}</th>
                                            <th className="event">{t("event")}</th>
                                            <th className="error">{t("error")}</th>
                                            <th className="status">{t("status")}</th>
                                            <th className="action">
                                                <span className="sr-only">{t("action")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logs.map(
                                            (log: CatalogEnrollmentLogRecord, idx: number) => (
                                                <tr key={log.id}>
                                                    <td className="account">
                                                        <span>
                                                            <div
                                                                className={`${CSS_CLASS_NAME}__account-name`}>
                                                                {StringUtils.valueOrDefault(
                                                                    (
                                                                        log.firstName +
                                                                        " " +
                                                                        log.lastName
                                                                    ).trim()
                                                                )}
                                                            </div>
                                                            <div
                                                                className={`${CSS_CLASS_NAME}__account-email`}>
                                                                {StringUtils.valueOrDefault(
                                                                    log.userName
                                                                )}
                                                            </div>
                                                            <div>
                                                                <Tooltip
                                                                    content={t("legacyID")}
                                                                    placement={
                                                                        TooltipPlacement.Top
                                                                    }>
                                                                    <div>
                                                                        {StringUtils.valueOrDefault(
                                                                            log.externalIdentifier
                                                                        )}
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                            <div>
                                                                <Tooltip
                                                                    content={t("netsuiteID")}
                                                                    placement={
                                                                        TooltipPlacement.Top
                                                                    }>
                                                                    <span>
                                                                        {StringUtils.valueOrDefault(
                                                                            log.netSuiteUserId?.toString()
                                                                        )}
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        </span>
                                                    </td>
                                                    <td className="order">
                                                        <div>
                                                            {StringUtils.valueOrDefault(
                                                                log.orderNumber?.toString()
                                                            )}
                                                        </div>
                                                        <div>
                                                            {DateUtils.formatDateCustom(
                                                                log.createdOn?.toString(),
                                                                "MM/DD/yy hh:mm a"
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="erpId">
                                                        {StringUtils.valueOrDefault(log.erpId)}
                                                    </td>
                                                    <td className="product">
                                                        <div>
                                                            {StringUtils.valueOrDefault(
                                                                log.productName
                                                            )}
                                                        </div>
                                                        <div>{log.productId}</div>
                                                        <div>
                                                            {log.productStatus && (
                                                                <EnumStatusBadge
                                                                    displayNames={
                                                                        ActiveStatusStatusDisplayNames
                                                                    }
                                                                    statusMap={ActiveStatusMap}
                                                                    value={log.productStatus}
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="event">
                                                        <div>
                                                            {StringUtils.valueOrDefault(
                                                                log.eventName
                                                            )}
                                                        </div>
                                                        <div> {log.trainingSessionId}</div>
                                                        <div>
                                                            {log.eventStatus && (
                                                                <EnumStatusBadge
                                                                    displayNames={
                                                                        PublishStatusDisplayNames
                                                                    }
                                                                    statusMap={PublishStatusMap}
                                                                    value={log.eventStatus}
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="error">
                                                        <Tooltip
                                                            content={
                                                                <>
                                                                    {log.errorMessage ??
                                                                        t("notApplicableShort")}
                                                                </>
                                                            }
                                                            placement={TooltipPlacement.Top}>
                                                            <span>
                                                                {log.errorType !== null
                                                                    ? t(
                                                                          CatalogEnrollmentErrorTypeDisplayNames[
                                                                              log.errorType!
                                                                          ]
                                                                      )
                                                                    : "--"}
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                    <td className="status">
                                                        <div className={`${CSS_CLASS_NAME}__radio`}>
                                                            <RadioButton
                                                                key={`${log.id}-${CatalogEnrollmentStatus.Complete}`}
                                                                checked={
                                                                    log.status ===
                                                                    CatalogEnrollmentStatus.Complete
                                                                }
                                                                formFieldName={
                                                                    CatalogEnrollmentStatusDisplayNames[
                                                                        CatalogEnrollmentStatus
                                                                            .Complete
                                                                    ]
                                                                }
                                                                groupName={`enrollment-${log.id}-attended`}
                                                                id={`enrollment-${log.id}-attended`}
                                                                label={t(
                                                                    CatalogEnrollmentStatusDisplayNames[
                                                                        CatalogEnrollmentStatus
                                                                            .Complete
                                                                    ]
                                                                )}
                                                                labelPosition={
                                                                    RadioButtonLabelPosition.Bottom
                                                                }
                                                                onChange={() =>
                                                                    updateStatus(
                                                                        log,
                                                                        CatalogEnrollmentStatus.Complete,
                                                                        idx
                                                                    )
                                                                }
                                                            />
                                                            <RadioButton
                                                                key={`${log.id}-${CatalogEnrollmentStatus.Incomplete}`}
                                                                checked={
                                                                    log.status ===
                                                                    CatalogEnrollmentStatus.Incomplete
                                                                }
                                                                formFieldName={
                                                                    CatalogEnrollmentStatusDisplayNames[
                                                                        CatalogEnrollmentStatus
                                                                            .Incomplete
                                                                    ]
                                                                }
                                                                groupName={`enrollment-${log.id}-incomplete`}
                                                                id={`enrollment-${log.id}-incomplete`}
                                                                label={t(
                                                                    CatalogEnrollmentStatusDisplayNames[
                                                                        CatalogEnrollmentStatus
                                                                            .Incomplete
                                                                    ]
                                                                )}
                                                                labelPosition={
                                                                    RadioButtonLabelPosition.Bottom
                                                                }
                                                                onChange={() =>
                                                                    updateStatus(
                                                                        log,
                                                                        CatalogEnrollmentStatus.Incomplete,
                                                                        idx
                                                                    )
                                                                }
                                                            />{" "}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </DataTable>
                            </SkipNavContent>
                        </div>
                    </div>
                    {CollectionUtils.hasValues(logs) && (
                        <div className="footer">
                            <Pager
                                currentPage={currentPage}
                                totalPages={totalLogs / ITEMS_PER_PAGE}
                                onPageClick={onPageClick}
                                itemsPerPage={ITEMS_PER_PAGE}
                                totalItems={totalLogs}
                            />
                        </div>
                    )}
                </SideContentLeftLayout>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminCatalogEnrollmentLogPage };

// #endregion Exports
