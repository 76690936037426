import { ResultErrorRecord } from "andculturecode-javascript-core";
import { useCallback, useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import {
    CountEnrollmentsQueryParams,
    EnrollmentService,
} from "utilities/services/enrollments/enrollment-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface useContractEnrollmentCountHook {
    contractEnrollmentCount: number;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface useContractEnrollmentCountHookOptions {
    contractId?: number;
    isWithdrawn?: boolean;
}

export function useContractEnrollmentCount(
    props: useContractEnrollmentCountHookOptions
): useContractEnrollmentCountHook {
    const [isLoading, setIsLoading] = useState(true);
    const [contractEnrollmentCount, setContractEnrollmentCount] = useState<number>(0);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { get: enrollmentCountAPI } = EnrollmentService.useCount();
    const contractId = props.contractId;
    const isWithdrawn = props.isWithdrawn;

    const getEnrollmentCount = useCallback(async (): Promise<void> => {
        try {
            const countEnrollmentsQueryParams: CountEnrollmentsQueryParams = {
                contractId: contractId,
                isWithdrawn: isWithdrawn,
            };

            const enrollmentCountResponse = await enrollmentCountAPI(
                {},
                countEnrollmentsQueryParams
            );
            const enrollmentCountResult = enrollmentCountResponse?.result;
            setIsLoading(false);
            if (enrollmentCountResult?.hasErrors()) {
                setErrors([...(enrollmentCountResult?.errors ?? [])]);
            }

            setContractEnrollmentCount(enrollmentCountResult?.resultObject ?? 0);
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEnrollmentCount"));
            setContractEnrollmentCount(0);
        }
    }, [contractId, enrollmentCountAPI, isWithdrawn]);

    useEffect(() => {
        if (!contractId) {
            return;
        }

        getEnrollmentCount();
    }, [contractId, getEnrollmentCount]);

    return {
        contractEnrollmentCount,
        errors,
        isLoading,
    };
}
