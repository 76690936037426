import { Culture } from "andculturecode-javascript-core";
import { CultureCodes } from "constants/localization/culture-codes";
import { cultureFactory } from "utilities/localization/culture-factory";
import { CultureResources } from "utilities/interfaces/culture-resources";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";

// -------------------------------------------------------------------------------------------------
// #region Translations
// -------------------------------------------------------------------------------------------------

const placeholderArabicTranslations = {
    legacyID: "رقم الهوية القديم",
    onHoldDescription: "طلبك معلق. يرجى الرجوع إلى البريد الإلكتروني الذي تم إرساله إليك.",
    onHold: "في الانتظار",
    thereWasAnIssueClaimingYourTrial: "كانت هناك مشكلة في المطالبة بالتجربة الخاصة بك.",
    eventsWithANYIncompleteEnrollmentWithAnEndDateWithinTheLastDays: `الأحداث مع أي تسجيل غير مكتمل مع تاريخ انتهاء في الأيام الأخيرة`,
    eventsInTheFuture: "الأحداث في المستقبل",
    howWouldYouLikeToApplyThisNewVersionToInProgressLearners: `كيف ترغب في تطبيق هذا الإصدار الجديد على المتعلمين الذين لم يكملوا بعد؟`,
    thereWasAnIssueUpdatingTheCatalogEnrollmentLog: "حدثت مشكلة أثناء تحديث سجل تسجيل الكتالوج.",
    filterByError: "فلتر حسب الخطأ",
    cancelled: "ألغيت",
    remotely: "عن بعد",
    both: "كلاهما",
    aenDirectoryAvailabilityBoth:
        "كلا، سأكون متاحًا لجميع مزودي الخدمة في دليل المعلمين AEN ومعلمًا لمنظمة معينة.",
    aenDirectoryAvailabilityNo: "لا، سأكون متاحًا لجميع مزودي الخدمة في دليل المعلمين AEN.",
    aenDirectoryAvailabilityYes: "نعم، سأكون مدربًا فقط لمؤسسة معينة.",
    doYouWantToBeAnApprovedInstructorForASpecificAENProvider:
        "هل ترغب في أن تكون مدربًا معتمدًا لمزود AEN معين؟",
    doYouPlanToOfferTraining: "هل تخطط لتقديم تدريب؟",
    catalogPath: "AENbooks",
    ToMakeTheChange: "لإجراء التغيير ",
    contactTheAENAdministrator: "اتصل بمسؤول AEN",
    yourExclusiveDiscountCodeIsTiedToTheAdminEmailEnteredUponRegistrationForTheNFPAAuthorizedEducationNetworkIfYouWouldLikeToUseADifferentEmailPlease:
        "*يرتبط رمز الخصم الحصري الخاص بك بالبريد الإلكتروني للمشرف الذي تم إدخاله عند التسجيل في شبكة التعليم المعتمدة من NFPA. إذا كنت ترغب في استخدام بريد إلكتروني مختلف، من فضلك",
    ifYouWantToProvidePrintedNFPACodesStandardsToYourStudentsYouCanPurchaseThemAtADiscountedPriceFromNFPAPleaseNoteYouAreStillResponsibleForShippinghandlingAndApplicableTaxesOnOrdersPlacedFromNFPAAndYourProviderDiscountIsOnlyForPrintPublicationsUsedForTrainingClassesBooksAreNotForResaleForOrdersPlacedOutsideTheUSPleasePlanForAnAdditionalWeeksForDeliveryAndAnyAdditionalLocalAndImportTaxes:
        "إذا كنت ترغب في توفير رموز ومعايير NFPA المطبوعة لطلابك، فيمكنك شراؤها بسعر مخفض من NFPA*. يرجى ملاحظة أنك لا تزال مسؤولاً عن الشحن/المناولة والضرائب المطبقة على الطلبات المقدمة من NFPA، وأن خصم المزود الخاص بك مخصص فقط للمنشورات المطبوعة المستخدمة في الفصول التدريبية. الكتب ليست للبيع. بالنسبة للطلبات المقدمة خارج الولايات المتحدة، يرجى التخطيط لمدة 3 أسابيع إضافية للتسليم وأي ضرائب محلية وضرائب استيراد إضافية.",
    toRunThisReportYouMustSelectEitherAnEventsOrADateRangeYouMayAlsoSelectBoth:
        "لتشغيل هذا التقرير، يجب عليك تحديد حدث واحد على الأقل و/أو نطاق زمني أدناه.",
    notApplicable: "غير مطبق",
    typeToSearchByProviderIdOrName: "اكتب للبحث برقم معرف المزود أو الاسم",
    pleaseConfirmThatAllLearnersAreMarkedAsAttendedOrNoShow:
        "يرجى التأكد من أنه تم وضع علامة على جميع المتدربين على أنهم حضروا أو لم يحضروا.",
    noShow: "لا تظهر",
    notAttended: "لم يحضر",
    partiallyAttended: "حضر جزئيًا",
    youCanNotEditContentFromATrainTheTrainerProductInOrderToEditContentYouMustEditTheAssociatedProduct:
        "لا يمكنك تحرير محتوى من منتج تدريب المدرب. لتحرير المحتوى، يجب عليك تحرير المنتج المرتبط.",
    youAreViewingContentFromTheAssociatedTrainingProduct:
        "أنت تعرض محتوى من المنتج التدريبي المرتبط",
    aenInstructorInformationPage: "صفحة معلومات المعلم AEN",
    forMoreInformationOnBecomingAnNFPAInstructorPleaseVisit: `لمزيد من المعلومات حول كيفية تصبح مدرسًا في NFPA، يرجى زيارة `,
    forSpecificFeedbackPleaseReferToTheEmailThatWasSentToYou:
        "للحصول على ملاحظات محددة، يرجى الرجوع إلى البريد الإلكتروني الذي تم إرساله إليك.",
    thankYouForTakingTheTimeToApplyToBeAnApprovedNFPAInstructorWeAppreciateYourInterestAndTheEffortYouPutIntoYourApplicationUnfortunatelyWeHaveFoundThatYourQualificationsDoNotMeetTheRequirementsAtThisTime: `شكرًا لك على الوقت الذي قضيته في التقديم لتكون مدرسًا معتمدًا من NFPA. نحن نقدر اهتمامك والجهد الذي وضعته في طلبك. للأسف، وجدنا أن مؤهلاتك لا تلبي المتطلبات في هذا الوقت.`,
    approvedToTeachProduct: "تمت الموافقة على تدريس المنتج",
    instructorDirectory: "دليل المعلمين",
    youAreNowEditingACompleteEvent: "أنت الآن تقوم بتحرير حدث كامل.",
    thisEventIsComplete: "هذا الحدث كامل.",
    unableToEdit: "غير قادر على التحرير",
    goToAenHub: "انتقل إلى عين هب",
    onceYouHaveCompletedAllRequiredElementsOfTheTrainingYouWillReceiveAnEmailFromCredlyInvitingYouToClaimYourDigitalBadgeTheBadgeWillAllowYouToEasilyShareYourAchievementWithColleaguesManagersAndYourProfessionalNetworkOnLinkedInAndOtherPlatforms:
        "بمجرد الانتهاء من جميع العناصر المطلوبة للتدريب، ستتلقى رسالة بريد إلكتروني من Credly تدعوك للمطالبة بشارتك الرقمية. ستسمح لك الشارة بمشاركة إنجازك بسهولة مع الزملاء والمديرين وشبكتك المهنية على LinkedIn والمنصات الأخرى.",
    weHaveIntegratedWithCredlyToTranslateTheLearningOutcomesYouveDemonstratedIntoABadgeIssuedAndManagedThroughTheCompanyDigitalBadgingPlatformTheTechnologyCredlyUsesIsBasedOnTheOpenBadgeStandardsMaintainedByIMSGlobalThisEnablesYouToManageShareAndVerifyYourCompetenciesDigitally:
        "لقد قمنا بالتكامل مع Credly لترجمة نتائج التعلم التي أظهرتها إلى شارة، يتم إصدارها وإدارتها من خلال منصة الشارات الرقمية للشركة. تعتمد التكنولوجيا التي تستخدمها Credly على معايير Open Badge التي تحتفظ بها IMS Global. يمكّنك هذا من إدارة كفاءاتك ومشاركتها والتحقق منها رقميًا.",
    changesToContentWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "التغييرات على المحتوى ستُطبق على جميع المتعلمين على هذا الإصدار بغض النظر عن حالة الانتهاء ولن تؤثر أو تعيد تعيين تقدم المتعلم.",
    youCanOnlyMakeChangesToTheProductsContent: "يمكنك فقط إجراء تغييرات على محتوى المنتجات.",
    changesWillNotBeAppliedToInstructorsCurrentlyAssignedToThisProductYouWillNeedToManuallyReassignThisTrainingToTheInstructor:
        "لن يتم تطبيق التغييرات على المعلمين المعينين حاليًا لهذا المنتج. ستحتاج إلى إعادة تعيين هذا التدريب يدويًا إلى المعلم.",
    allCurrentlyEnrolledInstructorsWillRemainOnTheCurrentVersionToMigrateThemToTheNewVersionManuallyReassignThisTraining:
        "سيظل جميع المعلمين المسجلين حاليًا على الإصدار الحالي. لترحيلهم إلى الإصدار الجديد، أعيد تعيين هذا التدريب يدويًا.",
    applicationSubmittedDescription: "لقد تم استلام طلبك. سيبدأ NFPA قريبًا عملية المراجعة.",
    underReviewDescription:
        "تفاصيل طلبك قيد المراجعة حاليًا. سوف يتصل بك NFPA إذا كانت هناك حاجة لمزيد من المعلومات.",
    pendingAssessmentDescription:
        "لقد تم تكليفك بالتقييم (التقييمات) للدورة (الدورات) المطلوبة في طلبك. يجب عليك اجتياز التقييم (التقييمات) خلال ثلاث محاولات والحصول على درجة لا تقل عن 80% للانتقال إلى مرحلة المراجعة النهائية.",
    finalReviewInProgressDescription:
        "طلبك وإرسال الفيديو في مرحلة المراجعة النهائية. سيتم إعلامك بمجرد اتخاذ قرار بشأن موافقتك.",
    finalReviewInProgress: "المراجعة النهائية قيد التقدم",
    thereWasAnIssueReturningTheInstructorApplicationStatus: "حدثت مشكلة في إرجاع حالة طلب المدرس.",
    thereWasAProblemReturningTheInstructorApplicationEnrollments:
        "حدثت مشكلة أثناء إرجاع تسجيلات التطبيق للمعلم.",
    applicationSubmitted: "Application Submitted",
    myApplicationStatus: "حالة طلبي",
    requiredAssessment: "التقييمات المطلوبة",
    pendingApproval: "قيد الموافقة",
    instructorStatus: "حالة المعلم",
    instructorAssessment: "تقييم المعلم",
    forInvoiceStatus: "لمعرفة حالة الفاتورة...",
    invoiced: "تم إصدار الفاتورة",
    invoiceStatus: "حالة الفاتورة",
    invoiceStatusType: "نوع حالة الفاتورة",
    notInvoiced: "لم يتم إصدار الفاتورة",
    notInvoicedAndInvoiced: "لم يتم إصدار فاتورة له وتم إصدار فاتورة له",
    aProviderAlreadyExistsWithThisNetsuiteId: "يوجد موفر بالفعل بمعرف Netsuite هذا",
    enterNetsuiteId: "أدخل معرف Netsuite",
    netsuiteID: "معرف نتسويت",
    messageToApplicant: "رسالة إلى المتقدم",
    messageSentToApplicant: "تم إرسال الرسالة إلى المتقدم",
    sendMessageToApplicant: "إرسال رسالة إلى المتقدم",
    emailBody: "الجسم",
    applicationHasBeenUpdatedSuccessfully: "تم تحديث التطبيق بنجاح.",
    thereWasAnIssueUpdatingTheApplication: "حدثت مشكلة أثناء تحديث التطبيق.",
    updateStatus: "تحديث الحالة",
    reviewer: "مراجع",
    applicationSuccessfullyUpdated: "تم تحديث التطبيق بنجاح",
    applicationUpdateFailed: "فشل تحديث التطبيق",
    reviewCompleteAccepted: "اكتملت المراجعة - تم قبولها",
    reviewCompleteDeclined: "اكتملت المراجعة - مرفوضة",
    hasbeeninvoiced: "تمت الفوترة",
    eventInvoicing: "فوترة الحدث",
    enterNotes: "أدخل الملاحظات",
    activityBy: "النشاط بواسطة",
    addNotes: "إضافة ملاحظات",
    addNote: "إضافة ملاحظة",
    notes: "ملاحظات",
    thereWasAnIssueReturningActivityLogs: "حدثت مشكلة أثناء إرجاع سجلات النشاط.",
    thereWasAnIssueSavingTheNotes: "حدثت مشكلة أثناء حفظ الملاحظات.",
    noteHasBeenAddedSuccessfully: "تمت إضافة الملاحظة بنجاح.",
    resume: "السيرة الذاتية",
    thereWasAnIssueReturningApplicationFiles: "حدثت مشكلة أثناء إرجاع ملفات التطبيق.",
    thereWasAnIssueSavingTheFile: "حدثت مشكلة أثناء حفظ الملف.",
    trainingSpace: "مساحة التدريب",
    fileTypeSelection: "اختيار نوع الملف",
    enterDescription: "أدخل الوصف",
    addFile: "إضافة ملف",
    uploadDate: "تاريخ التحميل",
    uploadedBy: "تم التحميل بواسطة",
    fileType: "نوع الملف",
    addNewFile: "إضافة ملف جديد",
    fileUploads: "تحميل الملفات",
    linkedInProfile: "الملف الشخصي على لينكد إن",
    currentNfpaMember: "هل أنت عضوًا حاليًا في NFPA؟",
    nfpaTechnicalCommitteeMember: "هل أنت حاليًا عضوًا في اللجنة الفنية لـ NFPA؟",
    ifYesWhichOnes: "أي لجان فنية؟",
    areYouCurrentlyAnApprovedNfpaInstructor: "هل أنت حاليًا مدرس معتمد من NFPA؟",
    haveYouPreviouslyBeenAnApprovedNfpaInstructor: "هل سبق لك أن كنت مدربًا معتمدًا في NFPA؟",
    whyDoYouWantToBecomeAnNfpaInstructor: "لماذا تريد أن تصبح مدرب NFPA؟",
    areYouInterestedInDeliveringVirtualTraining: "هل أنت مهتم بتقديم التدريب الافتراضي",
    doYouHaveExperienceDeliveringTrainingBasedOnNfpaCodesAndStandards:
        "هل لديك خبرة في تقديم التدريب بناءً على قواعد ومعايير NFPA",
    ifYesPleaseBrieflyDescribeYourExperienceEGTopicsAudienceClassSize:
        "إذا كانت الإجابة بنعم، يرجى وصف تجربتك بإيجاز (على سبيل المثال، المواضيع، الجمهور، حجم الفصل)",
    doYouHaveExperienceDeliveringVirtualTraining: "هل لديك خبرة في تقديم التدريب الافتراضي؟",
    ifYesPleaseBrieflyDescribeYourExperienceAndSkillComfortLevelDoingSo:
        "إذا كانت الإجابة بنعم، يرجى وصف تجربتك ومستوى مهارتك/راحتك في القيام بذلك بإيجاز.",
    ifYesToWhere: "إذا كانت الإجابة بنعم، إلى أين؟",
    whichTopicAreasAreYouInterestedInTeaching: "ما هي المجالات المواضيعية التي تهتم بتدريسها؟",
    pleaseListYourQualifications: "يرجى ذكر مؤهلاتك",
    educationDegrees: "التعليم/الدرجات العلمية",
    certificationsLicenses: "الشهادات/التراخيص",
    listRelevantPositionsPositionRoleDates: "قائمة المناصب ذات الصلة (المنصب/الدور + التواريخ)",
    pleaseProvideContactInformationForAtLeastTwoProfessionalReferencesWithKnowledgeOfYourSubjectMatterExpertiseAndOrTeachingAbility:
        "يرجى تقديم معلومات الاتصال لاثنين على الأقل من المراجع المهنية التي لديها معرفة بخبرتك في الموضوع و/أو قدرتك التعليمية.",
    teachingModalities: "طرائق التدريس",
    teachingExperience: "الخبرة التدريسية",
    interestedTopicAreas: "مجالات المواضيع المهتمة",
    qualifications: "مؤهلات",
    languages: "اللغات",
    references: "مراجع",
    video: "شارك رابط التعليمات",
    ifYesWhichCourses: "إذا كانت الإجابة بنعم، أي الدورات؟",
    applicationDetails: "تفاصيل التطبيق",
    files: "الملفات",
    activityLogs: "سجلات النشاط",
    partIProviderInformation: "الجزء الأول: معلومات الموفر",
    website: "موقع الويب",
    providerType: "نوع الموفر",
    providerBusinessSegment: "قطاع أعمال المزود",
    whyDoYouWantToHost: "لماذا تريد الاستضافة؟",
    locations: "المواقع",
    partIITrainingAdministration: "الجزء الثاني: إدارة التدريب",
    isThereSomeoneElseBesidesTheProviderPrimaryContactWhoWillBeResponsibleForAdministratingNfpaTrainingEvents:
        "هل هناك شخص آخر إلى جانب جهة الاتصال الأساسية للموفر سيكون مسؤولاً عن إدارة فعاليات تدريب NFPA؟",
    ifYesPleaseProvideFullNameAndEmail:
        "إذا كانت الإجابة بنعم، يرجى تقديم الاسم الكامل وعنوان البريد الإلكتروني.",
    partIIITrainingSpace: "الجزء الثالث: مساحة التدريب",
    partIVMarketing: "الجزء الرابع: التسويق",
    asAProviderHowWillYouPromoteTrainingCourses:
        "باعتبارك مقدم خدمة، كيف يمكنك الترويج للدورات التدريبية؟",
    partVCertification: "الجزء الخامس: الشهادة",
    signature: "التوقيع",
    title: "العنوان",
    techReview: "مراجعة تقنية",
    pendingAssessment: "في انتظار التقييم",
    aenApplications: "تطبيقات AEN",
    filterByAssignedTo: "التصفية حسب المعين لـ",
    assignedTo: "مخصص ل",
    companyName: "اسم الشركة",
    contactEmail: "تواصل بالبريد الاكتروني",
    goToApplication: "اذهب إلى التطبيق",
    underReview: "قيد المراجعة",
    contractInProgress: "العقد قيد التقدم",
    declined: "انخفض",
    creditCheckInProgress: "التحقق من الائتمان قيد التقدم",
    moreInfoNeeded: "مزيد من المعلومات المطلوبة",
    thereWasAnIssueLoadingProviderApplications: "حدثت مشكلة أثناء تحميل تطبيقات الموفر.",
    thereWasAnIssueLoadingInstructorApplications: "حدثت مشكلة أثناء تحميل تطبيقات المدرس.",
    yourTrialWillBeAvailableOnTheFirstDayOfTheEvent: "سيكون تجربتك متاحة في اليوم الأول للحدث.",
    whatAreTheDigitalBadgeTermsOfUse: "ما هي شروط استخدام الشارة الرقمية؟",
    continueToLink: "المتابعة إلى LiNK",
    yourTrialHasAlreadyBeenClaimedDoYouWantToGoThereNow:
        "تمت مطالبة التجربة الخاصة بك بالفعل. هل تريد الانتقال إلى هناك الآن؟",
    yourTrialHasBeenClaimedSuccessfullyDoYouWantToGoThereNow:
        "تمت مطالبة التجربة الخاصة بك بنجاح. هل تريد الانتقال إلى هناك الآن؟",
    itLooksLikeYouAlreadyHaveAnActiveLinkSubscriptionDoYouWantToGoThereNow:
        "يبدو أن لديك اشتراك LiNK نشط بالفعل. هل تريد الانتقال إلى هناك الآن؟",
    exploreLink: "استكشف LiNK",
    joinLink: "انضم إلى LiNK",
    joinTodayToGetInstantAccessToOver1400NfpaCodesAndStandards:
        "انضم اليوم للحصول على الوصول الفوري إلى أكثر من 1400 كود ومعيار NFPA",
    theresMoreToExploreWithLink: "هناك المزيد لاستكشافه مع LiNK",
    youStillHaveInstantAccessToOver1400NfpaCodesAndStandards:
        "لديك لا تزال الوصول الفوري إلى أكثر من 1400 كود ومعيار NFPA",
    numDaysOfFreeAccessRemaining: "الوصول المجاني لمدة {{num}} يومًا متبقيًا",
    newUsersGetInstantAccessToOver1400NfpaCodesAndStandards:
        "المستخدمون الجدد يحصلون على الوصول الفوري إلى أكثر من 1400 كود ومعيار NFPA",
    freeAccessForNumberDays: "الوصول المجاني لمدة {{number}} يومًا",
    youMustManuallyDeleteTheExistingPackageBeforeReplacingIt:
        "يجب عليك حذف الحزمة الحالية يدويًا   {{externalScormCourse}} قبل استبدالها.",
    searchByNameEmailOrLocation: "البحث بالاسم، البريد الإلكتروني، أو الموقع",
    refreshing: "منعش",
    refreshingInNSeconds: "يتم التحديث خلال {{timeInSeconds}} ثانية",
    accessingYourCertificateAndBadge: "الوصول إلى شهادتك وشارتك",
    launchAnInstructorLedTraining: "إطلاق تدريب يقوده المعلم",
    launchAnOnlineLearning: "إطلاق تعلم عبر الإنترنت",
    dashboardNavigationAndProfile: "لوحة التحكم والتنقل والملف الشخصي",
    accessingYourTraining: "الوصول إلى التدريب الخاص بك",
    comingSoon: "قريبًا",
    clearYourInternetBrowsersCacheAndCookiesReloadTheLearningPortalAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAtClientAdmin:
        'امسح ذاكرة التخزين المؤقت وملفات تعريف الارتباط لمتصفح الإنترنت الخاص بك. أعد تحميل نظام إدارة التعلم (LMS) وحاول الوصول إلى التدريب الخاص بك مرة أخرى. إذا لم ينجح ذلك، يُرجى التواصل مع خدمة العملاء لدى NFPA عن طريق تحديد الزر "إرسال سؤال".',
    changesToCoursesAreNotAvailableWhenEditingAnActiveProductVersionToChangeCourses:
        "لا تتوفر التغييرات على الدورات التدريبية عند تحرير إصدار منتج نشط. لتغيير الدورات،",
    changesToAssessmentsAreNotAvailableWhenEditingAnActiveProductVersionToChangeAssessments:
        "لا تتوفر التغييرات على التقييمات عند تحرير إصدار منتج نشط. لتغيير التقييمات،",
    approved: "معتمد",
    failedToUnpublish: "فشل إلغاء النشر",
    unpublishedSuccessfully: "تم إلغاء النشر بنجاح",
    unpublishProduct: "إلغاء نشر المنتج",
    unpublishCourse: "إلغاء نشر الدورة الدراسية",
    pleaseDoNotMakeChangesToTheFormatOfThisTemplateOrItWillAffectTheUploadOfTheRegistrations:
        "الرجاء عدم إجراء تغييرات على تنسيق هذا القالب، أو سيؤثر ذلك على تحميل التسجيلات.",
    importingACSVFileIsAnEasyWayToBulkEnrollMultipleLearnersAtOnceToGetStarted:
        "يُعد استيراد ملف بتنسيق CSV طريقة سهلة لتسجيل العديد من المتعلمين بشكل مجمّع في وقت واحد. للبدء، ",
    emailsMustBeUniqueToTheIndividualRegistrant:
        "البريد الإلكتروني يجب أن يكون فريدًا لكل مسجل فرديًا.",
    orderInfo: "معلومات الطلب",
    invalidData: "بيانات غير صالحة",
    productNotFound: "المنتج غير موجود",
    thereWasAnIssueLoadingCatalogEnrollmentLogs: "حدثت مشكلة أثناء تحميل سجلات تسجيل الكتالوج.",
    noInvalidAttendees: "لا يوجد مشاركون غير صالحين.",
    noValidAttendees: "لا يوجد مشاركون صالحون.",
    errors: "أخطاء",
    invalidAttendees: "المشاركون غير الصالحين",
    validAttendees: "المشاركون الصالحون",
    errorParsingCSV: "حدث خطأ أثناء تحليل ملف CSV.",
    catalogEnrollmentLog: "سجل التسجيل في الكتالوج",
    saveValidAttendees: "حفظ المشاركين الصالحين",
    past: "ماضي",
    hideLocationInformation: "إخفاء معلومات الموقع",
    datePickerDateFormat: "dd/MM/yyyy",
    manageEnrollments: "إدارة التسجيل",
    anOrganizationAlreadyExistsWithThisNetSuiteCustomerId:
        "يوجد بالفعل منظمة بهذا معرِّف العميل في NetSuite.",
    copiedToClipboard: "تم النسخ إلى الحافظة",
    claimNow: "المطالبة الآن",
    freeAccessForNumDays: "الوصول المجاني لمدة {{trialDays}} يومًا",
    useCode: "استخدم الرمز",
    toGetInstantAccessToLiNK: "للحصول على الوصول الفوري إلى LiNK",
};

const professionalArabicTranslations = {
    SelectAnAENProviderIfTheInstructorIsAssociatedWithAnExistingAENProviderEgTheInstructorIsAnEmployeeOfTheAENProviderOrganization:
        "حدد موفر الاحتياجات التعليمية الإضافية (AEN) إذا كان المعلم مرتبطًا بموفر AEN موجود (على سبيل المثال، يكون المعلم موظفًا في مؤسسة موفر AEN)",
    aGroup: "مجموعة",
    aProviderAlreadyExistsWithThisName: "يوجد موفر بهذا الاسم بالفعل",
    aProviderAlreadyExistsWithThisNfpaId: "يوجد موفر بمُعرِّف NFPA هذا بالفعل",
    addGroup: "إضافة مجموعة",
    addGroups: "إضافة مجموعات",
    addNewContent: "إضافة محتوى جديد",
    addNewGroup: "إضافة مجموعة جديدة",
    addProductApproval: "إضافة الموافقة على المنتج",
    addUsers: "إضافة مستخدمين",
    aenProductCatalog: "كتالوج منتجات AEN",
    aenResources: "موارد AEN",
    allEvents: "كل الأحداث",
    approvedToTeachProductCreatedSuccessfully: "تم إنشاء المنتج المعتمد للتدريس بنجاح.",
    approvedToTeachProductRemovedSuccessfully: "تمت إزالة المنتج المعتمد للتدريس بنجاح.",
    areYouSureYouWantToRemoveContent: " هل تريد بالفعل إزالة {{content}}؟",
    areYouSureYouWantToRemoveThisUserFromThisGroup: " هل تريد بالفعل إزالة {user}} من {{group}}؟",
    areYouSureYouWantToRevokeApproval:
        "هل أنت متأكد من رغبتك في إلغاء موافقة {{instructorName}} على تدريس {{productName}}؟",
    associateThisUserToAnExistingENProvider: "ربط هذا المستخدم بموفر AEN موجود",
    associatedENProvider: "موفر AEN المرتبط",
    authorizedEducationNetworkTrainings: "تدريبات شبكة التعليم المعتمدة",
    availableForAEN: "متوفر بالنسبة إلى AEN",
    backToAllProducts: "العودة إلى جميع المنتجات",
    beforeBuildingYourSchedule: "قبل إنشاء الجدول الزمني الخاص بك.",
    bySelectingAnAENProviderTheInstructorCanStillBeAssignedToEventsForTheAssociatedAENProviderEvenIfTheyAreExcludedFromTheAENProviderDirectoryAndSearch:
        "من خلال تحديد موفر AEN، لا يزال من الممكن تعيين المعلم لأحداث موفر AEN المرتبط حتى إذا تم استبعاده من دليل موفر AEN والبحث",
    calendarView: "عرض التقويم",
    certificates: "الشهادات",
    changesToAssessmentsAreNotAvailableWhenEditingTheCurrentProductVersionToChangeAssessmentsCreateANewProductVersion:
        "لن يتوفر إجراء تغييرات على الدورات الدراسية عند تعديل إصدار الدورة الدراسية الحالي. لتغيير الدورات الدراسية، أنشئ إصدار منتج جديد.",
    changesToBadgesAndCertificatesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "سيتم تطبيق التغييرات على جميع المتعلمين في هذا الإصدار، بغض النظر عن حالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة الدراسية من جديد.",
    changesToCoursesAreNotAvailableWhenEditingTheCurrentProductVersionToChangeCourses:
        "لن يتوفر إجراء تغييرات على الدورات الدراسية عند تعديل إصدار المنتج الحالي. لتغيير الدورات الدراسية،",
    clientTrainingAdmin: "مسؤول تدريب العملاء",
    completeCoursesInOrder: "أكمل الدورات الدراسية بالترتيب",
    contentCreatedSuccessfully: "تم إنشاء المحتوى بنجاح.",
    contentDeletedSuccessfully: "تم حذف المحتوى بنجاح.",
    contentForInstructors: "محتوى للمعلمين",
    contentForProviders: "محتوى للموفرين",
    contentUpdatedSuccessfully: "تم تحديث المحتوى بنجاح.",
    courseObjectives: "أهداف الدورة الدراسية",
    courseObjectivesInclude: "تشمل أهداف الدورة الدراسية،",
    createSomethingNew: "إنشاء شيء جديد",
    creatingGroupUsersFailed: "فشل إنشاء مستخدمي المجموعة.",
    creatingaenresourcefailed: "فشل إنشاء مورد Aen",
    displaysTrainingsWhereAccessHasExpiredOrTrainingRequirementsAreComplete:
        "يعرض التدريبات التي انتهت صلاحية الوصول إليها أو متطلبات التدريب المكتملة.",
    download: "تنزيل",
    downloadAndPrint: "تنزيل وطباعة",
    downloadButtonForResourceResourceName: "زر التنزيل للمورد {{resourceName}}",
    editGroup: "تحرير المجموعة",
    emailManager: "مدير البريد الإلكتروني",
    emailSubject: "موضوع البريد الإلكتروني",
    enProviderAdmin: "مسؤول موفر AEN",
    enProvidersNeedToBeAddedBeforeTheyCanBeAssignedToAUser:
        "يجب إضافة موفري AEN قبل أن يتم تعيينهم لمستخدم.",
    enrollmentForTrainingAlreadyExists: "التسجيل للتدريب موجود بالفعل.",
    enterGroupDescription: "إدخال وصف المجموعة",
    enterGroupName: "إدخال اسم المجموعة",
    enterLongDescription: "إدخال وصف طويل",
    enterNetSuiteCustomerID: "إدخال مُعرِّف عميل NetSuite",
    errorFetchingProducts: "حدث خطأ أثناء جلب المنتجات",
    eventAttendees: "المشاركون في الحدث",
    eventViewType: "نوع عرض الحدث",
    excludeFromAenProviderDirectoryAndSearch: "الاستبعاد من دليل موفر AEN والبحث",
    failedToActivateProductVersion: "فشل في تنشيط إصدار المنتج.",
    failedtoupdateaenresource: "تعذر تحديث مورد AEN.",
    filterByAvailableForAEN: "التصفية حسب المتاح لموفر AEN",
    filterByType: "التصفية حسب النوع",
    goToYourDashboard: "الانتقال إلى لوحة التحكم",
    group: "المجموعة",
    groupDescription: "وصف المجموعة",
    groupHasBeenAddedSuccessfully: "تمت إضافة المجموعة بنجاح",
    groupHasBeenUpdatedSuccessfully: "تم تحديث بيانات المجموعة بنجاح",
    groupInformation: "معلومات المجموعة",
    groupName: "اسم المجموعة",
    groups: "المجموعات",
    hasAssessment: "يوجد تقييم",
    iWantToRunAnAllUserReportByThisUserRole:
        "أريد إنشاء تقرير عن جميع المستخدمين حسب دور/مجموعة المستخدم هذا...",
    iWantToRunAnEnrollmentReportBasedOn: "أريد إنشاء تقرير عن التسجيل استنادًا إلى...",
    iWantToSendAnEmailTo: "أريد إرسال بريد إلكتروني إلى",
    ifTheEventHoursWillExceedTheDefaultCEUValueTheCEUAmountMayBeAdjustedCommonValidReasonsTheEventMayRunLongerInclude:
        "كل ساعة من وقت الفصل الدراسي تساوي 0.1 من وحدات التعليم المستمر (CEU).إذا تجاوزت وحدات CEU الخاصة بالحدث قيمة وحدة CEU الافتراضية، فقد يتم تعديل مقدار الوقت المخصص. تتضمن الأسباب الشائعة الصالحة التي قد تجعل الحدث يستمر لفترة أطول،",
    instructorRoleWasAddedButSavingInstructorInformationFailed:
        "تمت إضافة دور المعلم، ولكن فشل حفظ معلومات المعلم.",
    intendedAudience: "الجمهور المستهدف",
    lastLoginDate: "تاريخ آخر تسجيل دخول",
    linkTrialId: "مُعرِّف التجربة",
    linkTrialInformationWithHTML: `رابط NFPA<sup>${UnicodeCharacterConstants.Registered}</sup>p معلومات التجربة`,
    linkTrialToken: "رمز التجربة",
    listView: "عرض القائمة",
    longDescription: "وصف طويل",
    nameOfGroup: "اسم المجموعة",
    nameOfOrganization: "اسم المؤسسة",
    netsuiteCustomerID: "مُعرِّف عميل NetSuite",
    newGroup: "مجموعة جديدة",
    nfpaWebId: "مُعرِّف ويب NFPA",
    noAssessmentsAdded: "لم تتم إضافة أي تقييم/تقييمات",
    noCertificateResultsFound: "لم يتم العثور على نتائج الشهادة",
    noContentResultsFound: "لم يتم العثور على نتائج المحتوى",
    noResourcesFound: "لم يتم العثور على موارد",
    notAvailableForAen: "غير متاح لموفر Aen",
    optionalAenProviderAssociationForInstructor: "جمعية موفري AEN الاختيارية للمعلم",
    pleaseAdjustYourScheduleOrContactNFPAForApproval:
        "يُرجى تعديل وحدات CEU الخاصة بك أو تواصل مع NFPA للحصول على الموافقة.",
    problemLoadingProvider: "مشكلة في تحميل الموفر",
    productCatalog: "كتالوج المنتجات",
    productImage: "صورة المنتج",
    productVersionIsNowActive: "إصدار المنتج نشط الآن.",
    providerHasBeenAddedSuccessfully: "تمت إضافة الموفر بنجاح",
    providerHasBeenUpdatedSuccessfully: "تم تحديث بيانات الموفر بنجاح",
    providers: "الموفرون",
    recipientType: "نوع المستلم",
    removeUser: "إزالة المستخدم",
    requirePassed: "يتطلب الاجتياز",
    revokeApproval: "إلغاء الموافقة",
    revoked: "تم الإبطال",
    searchByCourseNameOrID: "البحث حسب اسم أو مُعرِّف الدورة الدراسية",
    searchByProductNameOrID: "البحث حسب اسم المنتج أو مُعرِّفه",
    selectAGroup: "تحديد مجموعة",
    selectATemplate: "تحديد نموذج",
    selectAnEvent: "تحديد حدث",
    selectGroups: "تحديد مجموعات",
    sendAnEmail: "إرسال رسالة بريد إلكتروني",
    sendEmail: "إرسال بريد إلكتروني",
    includeAenNetwork: "تضمين شبكة AEN",
    tCreditHourEqualstCEUs: "{{creditHour}} من الساعات المعتمدة = {{ceu}} من وحدات CEU",
    theRecomendedCEUsForSelectedProductIs: "وحدات CEU الموصى بها للمنتج المحدد هي {{productCEUs}}.",
    theRecommendedCEUsForThisEventIsProductCEUsHoursAndShouldNotExceedMaxCEUs:
        "وحدات CEU الموصى بها لهذا الحدث هي {{productCEUs}} من وحدات CEU ويجب ألا تتجاوز {{maxCEUs}} من وحدات CEU.",
    theStatusAsAnActiveAenProviderIsContingentUponTheOrganizationsComplianceWithTheAenContractTimelyPaymentsAndFavorableCustomerEvaluations:
        "تتوقف الحالة كموفر AEN نشط حسب امتثال المؤسسة لعقد AEN، والمدفوعات في الوقت المناسب، وتقييمات العملاء الإيجابية.",
    thereWasAProblemSendingTheEmail: "حدثت مشكلة أثناء إرسال البريد الإلكتروني.",
    thereWasAProblemSyncingTheUser: "حدثت مشكلة في مزامنة المستخدم.",
    thereWasAnErrorCreatingTheContent: "حدث خطأ أثناء إنشاء المحتوى.",
    thereWasAnErrorSavingTheContent: "حدث خطأ أثناء حفظ المحتوى.",
    thereWasAnIssueCreatingTheApprovedToTeachProduct:
        "حدثت مشكلة أثناء إنشاء المنتج المعتمد للتدريس.",
    thereWasAnIssueCreatingTheGroup: "حدثت مشكلة أثناء إنشاء المجموعة",
    thereWasAnIssueCreatingTheProvider: "حدثت مشكلة أثناء إنشاء الموفر",
    thereWasAnIssueDeletingTheContent: "حدثت مشكلة أثناء حذف المحتوى",
    thereWasAnIssueLoadingAenResources: "حدثت مشكلة أثناء تحميل موارد AEN",
    thereWasAnIssueLoadingGroupUsers: "حدثت مشكلة أثناء تحميل مستخدمي المجموعة",
    thereWasAnIssueLoadingGroups: "حدثت مشكلة أثناء تحميل المجموعات.",
    thereWasAnIssueLoadingTheGroup: "حدثت مشكلة أثناء تحميل المجموعة.",
    thereWasAnIssueLoadingTheInstructorsApprovedToTeachProducts:
        "حدثت مشكلة أثناء تحميل المنتجات المعتمدة للتدريس الخاصة بالمعلم.",
    thereWasAnIssueLoadingUsers: "حدثت مشكلة أثناء تحميل المستخدمين.",
    thereWasAnIssueRemovingTheApprovedToTeachProduct:
        "حدثت مشكلة أثناء إزالة المنتج المعتمد للتدريس.",
    thereWasAnIssueRemovingTheUser: "حدثت مشكلة أثناء إزالة المستخدم.",
    thereWasAnIssueSavingTheGroup: "حدثت مشكلة أثناء حفظ المجموعة.",
    thereWasAnIssueUpdatingTheGroup: "حدثت مشكلة أثناء تحديث المجموع",
    thereWasAnIssueUpdatingTheProvider: "حدثت مشكلة أثناء تحديث الموفر",
    therewasanissuesavingthecontent: "حدثت مشكلة أثناء حفظ المحتوى.",
    therewasaproblemcreatinganewenrollment: "حدثت مشكلة أثناء إنشاء تسجيل جدي",
    theseEventsAreForTheAENOnlyAndRunByTheAENProvider:
        "هذه الأحداث مخصصة من أجل AEN فقط ويتم تشغيلها بواسطة موفر AEN",
    thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizerWhenUseDefaultDuringTheEventCreationProcess:
        "ستكون معلومات الاتصال هذه متاحة للمشاركين في الحدث باعتبارها الطريقة الأساسية للاتصال بمنظم الحدث عند تحديد استخدام الإعداد الافتراضي أثناء عملية إنشاء الحدث.",
    tileView: "عرض اللوحة",
    toCreateAnEventForAnotherProductGoToEventsAndSelectNewEvent:
        "لإنشاء حدث لمنتج آخر، انتقل إلى الأحداث وحدد حدث جديد.",
    trainingViewType: "نوع عرض التدريب",
    typeToSearchByInstructorName: "إدخال للبحث حسب اسم المعلم",
    typeToSearchByNameOrEmail: "إدخال للبحث حسب الاسم أو البريد الإلكتروني",
    updateAenResourceFailed: "فشل تحديث مورد AEN.",
    updateaenresourcesuccessful: "تم تحديث مورد AEN بنجاح.",
    uploadAProductImage: "تحميل صورة المنتج",
    useThisToggleToSettoAllDAyWhenSessionTimesAreUnknown:
        "استخدم مفتاح التبديل هذا لضبط وقت الجلسة على طوال اليوم عندما تكون أوقات الجلسة غير معروفة.",
    user: "المستخدم",
    userId: "مُعرِّف المستخدم",
    userName: "اسم المستخدم",
    userRemovedSuccessfully: "تم إزالة المستخدم بنجاح.",
    userWasUndefined: "لم يتم تعريف المستخدم",
    viewGroup: "عرض المجموعة",
    whatDoYouWantToSay: "ماذا تريد أن تقول؟",
    whoWillBenefit: "من سيستفيد،",
    youAreCreatingAnEventForPropsProductName: "أنت تقوم بإنشاء حدث من أجل {{propsProductName}}",
    youAreCurrentlyNotApprovedToTeachAnyTrainings: "أنت غير معتمد حاليًا لتدريس أي تدريبات.",
    youHaveNoRequiredTrainings: "ليس لديك أي تدريبات مطلوبة.",
    yourContactInformationWillComeDirectlyFromYourNFPAProfileYourContactInformationWillBeAvailableToLMSAdministratorsForSchedulingPurposesItWillAlsoBeVisibleToEnrolledLearnersForEventsInWhichYouAreTheInstructor:
        "ستأتي معلومات الاتصال الخاصة بك مباشرةً من ملف تعريف NFPA الخاص بك. وستكون معلومات الاتصال الخاصة بك متاحة لمسؤولي LMS لأغراض الجدولة. وستكون مرئية أيضًا للمتعلمين المسجلين للأحداث التي تكون أنت المعلم فيها.",
    yourEmailHasBeenSent: "تم إرسال البريد الإلكتروني الخاص بك.",
    yourLocationInformationWillComeDirectlyFromYourNFPAProfileIfYouWouldLikeYourLocationInformationVisibleToAENProvidersPleaseMoveTheToggleToTheRightToTurnOnTheShareMyLocationInformationFeature: `ستأتي معلومات الموقع الخاصة بك مباشرةً من ملف تعريف NFPA الخاص بك. إذا كنت تريد أن تكون معلومات موقعك مرئية لموفري AEN، فيرجى تحريك مفتاح التبديل إلى اليمين لتشغيل ميزة "مشاركة معلومات موقعي".`,
    yourOrganizationHasNoPastOnlineTrainings: "لا تتوفر لدى مؤسستك تدريبات سابقة عبر الإنترنت",
    yourReportIsReady: "تقريرك جاهز",
    youveBeenGrantedAnExceptionToExceedMaxCEUsHoursForYourEvent:
        "لقد تم منحك استثناءً لتجاوز {{maxCEUs}} من وحدات CEU للحدث الخاص بك.",

    instructorsFAQs: "الأسئلة الشائعة لمعلمي الاحتياجات التعليمية الإضافية",
    AENProviderFAQ: "الأسئلة الشائعة لموفر الاحتياجات التعليمية الإضافية (AEN)",
    allCourseMaterialsWillBeAvailableDigitallyToParticipantsAndTheInstructorOnTheLMS:
        "‫ستتم إتاحة مواد الدورة الدراسية‬ رقميًا للمشاركين والمعلم على نظام إدارة التعلم (LMS).",
    aPastTrainingIsATrainingEventWhereAllOfTheEventDatesareInThePast:
        "التدريب السابق هو حدث تدريبي انقضت فيه جميع تواريخ الحدث في الماضي.",
    areYouSureYouWouldLikeToLeaveWithoutCreatingANewVersion:
        "هل تريد بالفعل المغادرة دون إنشاء إصدار جديد؟",
    badgeFAQs: "الأسئلة الشائعة حول الشارة",
    buildingLifeSafety: "البناء وسلامة الحياة",
    byDeactivatingThisCourseItWillNoLongerBeAvailableToAddToAProductTheCourseWillStillAppearInTheListViewOfCourses:
        "عند إلغاء تنشيط هذه الدورة الدراسية، لن تكون متاحة للإضافة إلى أحد المنتجات. وستظل الدورة الدراسية تظهر في عرض قائمة الدورات الدراسية.",
    canIaddmoreparticipantstotheclassroster:
        "هل يمكنني إضافة المزيد من المشاركين إلى قائمة الحصص الدراسية؟",
    changesToCEUsWillBeAppliedToTheRecommendedClassTimeForAnEventAndWillNotImpactOrResetTheEventScheduleForPublishedOrDraftEvents:
        "سيتم تطبيق التغييرات التي يتم إجراؤها على وحدات CEU على وقت الفصل الدراسي الموصى به لحدث ما، ولن تؤثر على جدول الأحداث أو إعادة تعيينه للأحداث المنشورة أو مسودة الأحداث.",
    changesToContentBadgesAndCertificatesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "سيتم تطبيق التغييرات التي يتم إجراؤها على المحتوى والشارات والشهادات على جميع المتعلمين في هذا الإصدار، بغض النظر عن حالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة الدراسية من جديد.",
    changesToTheEvaluationAreNotAvailableFollowingInitialCreationOfTheProduct:
        "لا تتوفر التغييرات على التقييمات بعد الإنشاء الأولي للمنتج.",
    clearYourInternetBrowsersCacheAndCookiesReloadTheLMSAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        'امسح ذاكرة التخزين المؤقت وملفات تعريف الارتباط لمتصفح الإنترنت الخاص بك. (LMS) وحاول الوصول إلى التدريب الخاص بك مرة أخرى. إذا لم ينجح ذلك، يُرجى التواصل مع خدمة العملاء لدى NFPA عن طريق تحديد الزر "إرسال سؤال',
    clickContinueToCreateANewVersionThisActionWillReturnYouToTheProductVersionListWhereYouCanActivateAndDetermineHowToApplyThisNewVersionToInProgressLearners:
        'انقر فوق "متابعة" لإنشاء إصدار جديد. سيعيدك هذا الإجراء إلى قائمة إصدارات المنتج حيث يمكنك تنشيط وتحديد كيفية تطبيق هذا الإصدار الجديد على المتعلمين الموجودين حاليًا.',
    clientAdminFAQ: "الأسئلة الشائعة حول ‏‫مسؤول العميل‬",
    completeAssessmentsInOrder: "أكمل التقييمات بالترتيب",
    courseseries: "دورة دراسية/سلسلة",
    deactivatingThisProductWillBeReflectedONLYWithinTheLMSBeSureToUpdateAllAssociatedSystemsIncludingTheNFPACatalogOnceDeactivatedThisProductWillNoLongerBeAvailableForNewEnrollmentsOrContracts:
        "سينعكس إلغاء تنشيط هذا المنتج في نظام إدارة التعلم (LMS) فقط. تأكد من تحديث جميع الأنظمة المرتبطة، بما في ذلك كتالوج NFPA. بمجرد إلغاء التنشيط، لن يكون هذا المنتج متاحًا للتسجيلات أو العقود الجديدة.",
    emergingTechnology: "التقنية الناشئة",
    erpId: "مُعرِّف ERP",
    fireProtection: "أنظمة الحماية من الحرائق",
    howChangesAreApplied: "كيفية تطبيق التغييرات",
    ifTheCertificateIsSpecificallyForHotWorkSelectYes:
        'إذا كانت الشهادة مُعدة للأشغال الحرارية خصيصًا، فحدد "نعم".',
    industrialChem: "المخاطر الصناعية",
    learnerFAQs: "الأسئلة الشائعة للمتعلم",
    LocateTheEventFromEitherTheEventCalendarOrTheEventsQuickLinkFromYourDashboardIfLocatingFromTheEventCalendarSelectTheSpecificEventAndThenSelectTheEditEventButtonThatWillAppearOnTheRighthandSideOfTheCalendarIfLocatingFromTheEventsQuickLinkUseTheEllipsisAndChooseEditEventNavigateThroughTheEventToEditAsNecessaryOrSelectTheCancelEventButtonAtTheBottomOfTheLefthandSidePaneAllLearnersAndInstructorsWillAutomaticallyReceiveBannerUpdatesConcerningTheChangesOrCancellation:
        'حدد موقع الحدث إما من تقويم الأحداث أو من الرابط السريع للأحداث من لوحة المعلومات. في حالة تحديد الموقع من تقويم الأحداث، حدد الحدث المعين ثم حدد زر "تعديل الحدث" الذي سيظهر على الجانب الأيمن من التقويم. في حالة تحديد الموقع من الرابط السريع للأحداث، استخدم علامة الثلاث نقاط واختر "تعديل الحدث". تصفح الحدث لتعديله حسب الضرورة أو حدد زر "إلغاء الحدث" الموجود أسفل الجزء الأيسر. سيتلقى جميع المتعلمين والمعلمين تلقائيًا تحديثات الشعار المتعلقة بالتغييرات أو الإلغاء.',
    newAndDraftEventsWillReceiveThisNewVersion:
        "ستتلقى الأحداث الجديدة ومسودة الأحداث هذا الإصدار الجديد.",
    newlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion:
        "ستتلقى الأحداث المنشورة حديثًا أحدث إصدار نشط من منتج التدريب تحت إشراف معلم (ILT).",
    NoAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactYourSalesRepresentative:
        "لا، لقد تمت مراجعة جميع مواد الدورة الدراسية من قِبل الموظفين الفنيين في NFPA.",
    noContentAdded: "لم تتم إضافة أي محتوى",
    notAtThisTime: "ليس في هذا الوقت.",
    noThisIsAServiceWeProvideToYouAtNoCost: "لا، هذه خدمة نقدمها لك مجانًا.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersion:
        "بمجرد نشر الحدث، فإنه يرتبط بإصدار منتج ILT وسيظل على هذا الإصدار.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatus:
        "بمجرد نشر الحدث، فإنه يرتبط بإصدار منتج ILT وسيظل على هذا الإصدار بغض النظر عن الجدول الزمني أو حالة الاكتمال.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatusNewlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion:
        "بمجرد نشر الحدث، فإنه يرتبط بإصدار منتج ILT وسيظل على هذا الإصدار بغض النظر عن الجدول الزمني أو حالة الاكتمال. ستتلقى الأحداث المنشورة حديثًا أحدث إصدار نشط من منتج التدريب تحت إشراف معلم (ILT).",
    ourPrimaryContactIsYourNFPASalesRepresentative:
        "جهة الاتصال الأساسية لدينا هي ممثل مبيعات NFPA لديك.",
    PleaseContactYourNFPASalesRepresentative: "يرجى الاتصال بممثل مبيعات NFPA الخاص بك.",
    pleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "",

    pleaseContactTheProviderOfTheEventForTechnologyIssuesDuringClass:
        "يُرجى التواصل مع موفر الحدث بخصوص المشكلات التقنية التي تحدث أثناء الحصة الدراسية.",
    PleaseWorkWithYourNFPASalesRepresentativeToProvideTheDetailsOfAnyTrainingYouWouldLikeScheduled:
        "يُرجى العمل مع مندوب مبيعات NFPA لديك لتقديم تفاصيل حول أي تدريب ترغب في جدولته.",
    publicEducation: "التعليم العام",
    selectNoIfTheTrainingDoesNotHaveAnExpirationDateANoWillDisplayAnNAInTheCertificateExpirationDateField:
        'حدد "لا" إذا لم يكن للتدريب تاريخ انتهاء الصلاحية. سيؤدي الخيار "لا" إلى عرض "غير متاح" في حقل تاريخ انتهاء صلاحية الشهادة.',
    selectYesIfTheTrainingHasAnExpirationDateAYesWillDisplayACertificateExpirationDateCalculatedBasedOnTheTrainingCompletionDateAndTheNumberOfYearsInputInTheHowLongIsTheCertificateValidField:
        'حدد "نعم" إذا كان للتدريب تاريخ انتهاء الصلاحية. ستعرض "نعم" تاريخ انتهاء صلاحية الشهادة المحسوب بناءً على تاريخ إكمال التدريب وعدد السنوات المدخلة في الحقل "مدة صلاحية الشهادة".',
    takeAMomentToReviewYourNewVersionAndPopulateTheChangeLogBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "خذ دقيقة من وقتك لمراجعة الإصدار الجديد وملء سجل التغييرات قبل التنشيط. إذا كنت تريد إجراء أي تغييرات، فارجع إلى الصفحة المناسبة من مسيرة الدورة الدراسية.",
    theSelectedILTProductIsNoLongerAvailablePleaseSelectANewILTProductToPublishYourEvent:
        "لم يعد منتج ILT المحدد متاحًا. الرجاء تحديد منتج ILT جديد لنشر الحدث الخاص بك.",
    thisProductHasNoContent: "هذا المنتج لا يشتمل على محتوى",
    wildfire: "حرائق الغابات",
    YesFromTheCurrentTrainingsOnTheDashboardSelectEitherTheOnlineOrInstructorLedTabSelectViewTrainingFromTheAppropriateCourseNavigateToTheEnrollmentSectionFromTheLeftSidePaneSelectEitherTheAddNewButtonOrTheImportCSVButtonToAddParticipantsToTheRoster:
        'نعم. من التدريبات الحالية في لوحة المعلومات، حدد إما علامة التبويب "عبر الإنترنت" أو "تحت إشراف مدرب". حدد عرض التدريب من الدورة الدراسية المناسبة. انتقل إلى قسم التسجيل من الجزء الأيسر. حدد إما الزر "إضافة جديد" أو الزر "استيراد ملف CSV" لإضافة مشاركين إلى القائمة.',
    yesHoweverWeCannotGuaranteeTheInstructorsAvailability:
        "نعم، ولكن، لا يمكننا ضمان توفر المعلم.يرجى العمل مع مندوب مبيعات NFPA الخاص بك. ",
    yesPleaseWorkWithYourNFPASalesRepresentativeToOrderPhysicalMaterialsForTheTraining:
        "نعم، يُرجى العمل مع ممثل مبيعات NFPA لديك لطلب المواد المادية للتدريب.",
    YesSummaryResultsOfTheCourseEvaluationsForInstructorledTrainingAreProvidedRealTimeSelectViewTrainingFromEitherCurrentUpcomingTrainingsOrFromTheEllipsisIconUnderPastTrainingsOnceYouAreViewingTheDesiredTrainingTheEvaluationSectionCanBeAccessedUsingTheLefthandPane:
        'نعم، يتم توفير ملخص لنتائج تقييمات الدورة الدراسية للتدريب تحت إشراف المدرب في الوقت الفعلي. حدد عرض التدريب إما من التدريبات الحالية والقادمة أو من أيقونة علامة الثلاث نقاط ضمن التدريبات السابقة. بمجرد عرض التدريب المطلوب، يمكن الوصول إلى قسم "التقييم" باستخدام الجزء الأيسر.',
    YesYouCanViewOverallAssessmentDataForAClassHoweverYouCannotSeeIndividualLearnerResultsSelectViewTrainingFromEitherCurrentUpcomingTrainingsOrFromTheEllipsisIconUnderPastTrainingsOnceYouAreViewingTheDesiredTrainingTheAssessmentSectionCanBeAccessedUsingTheLefthandPane:
        "نعم، يمكنك عرض بيانات التقييم الشاملة للحصة الدراسية. ومع ذلك، لا يمكنك رؤية نتائج المتعلمين الفرديين. حدد عرض التدريب إما من التدريبات الحالية والقادمة أو من أيقونة علامة الثلاث نقاط ضمن التدريبات السابقة. بمجرد عرض التدريب المطلوب، يمكن الوصول إلى قسم التقييم من خلال الجزء الأيسر.",
    youAreAboutToArchiveThisCourseArchivingTheCourseWillRemoveItFromTheCourseListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOnInOrderToArchiveThisCourseYouNeedTo:
        'أنت على وشك أرشفة هذه الدورة الدراسية. ستؤدي أرشفة الدورة الدراسية إلى إزالتها من عرض قائمة الدورات الدراسية ما لم يتم تشغيل زر التبديل "تضمين المؤرشف". لتتمكن من أرشفة هذه الدورة الدراسية، يجب عليك:',
    youAreAboutToArchiveThisProductArchivingTheProductWillRemoveItFromTheProductListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOn:
        'أنت على وشك أرشفة هذا المنتج. ستؤدي أرشفة المنتج إلى إزالته من عرض قائمة المنتج ما لم يتم تشغيل زر التبديل "تضمين المؤرشف".',
    youCanConfigureYourPrivacySettingsInCredlyYoureInCompleteControlOfTheInformationAboutYourselfThatIsMadePublic:
        "يمكنك تكوين إعدادات الخصوصية لديك في Credly. أنت تتحكم بشكل كامل في المعلومات التي يتم نشرها عنك.",
    youCanContinueToAccessAndUseThoseBadgesThrough:
        "يمكنك الاستمرار في الوصول إلى تلك الشارات واستخدامها.",
    YourFirstPointOfContactWillBeNFPACustomerServicePleaseSelectTheSubmitAQuestionButtonOnThisScreenOrCallCustomerServiceDirectlyAt:
        'ستكون نقطة الاتصال الأولى لديك هي خدمة عملاء NFPA. يُرجى تحديد الزر "إرسال سؤال" الموجود على هذه الشاشة أو الاتصال بخدمة العملاء مباشرةً على:',
    youWillReceiveAnEmailNotificationFromCredlyAdmincredlycomWithInstructionsForClaimingYourBadgeAndSettingUpYourAccount:
        "ستتلقى إشعارًا بالبريد الإلكتروني من Credly (‏admin@credly.com) يتضمن تعليمات المطالبة بشارتك وإعداد حسابك.",

    activateNewVersion: "تنشيط الإصدار الجديد",
    activatingProductVersionFailed: "فشل تنشيط إصدار المنتج.",
    addALearner: "إضافة متعلم",
    AllInstructorledClassesProvideAccessToDigitalMaterialsTheHostOfTheEventWillDetermineIfPhysicalMaterialsWillAlsoBeProvided:
        "توفر جميع الفصول التي يديرها المعلم إمكانية الوصول إلى المواد الرقمية. سيحدد مضيف الحدث ما إذا كان سيتم توفير المواد المادية أيضًا.",
    anyUpcomingEventsUsingThePreviousVersionWillReceiveThisNewVersion:
        "ستتلقى أي أحداث قادمة تستخدم الإصدار السابق هذا الإصدار الجديد.",
    browseNfpaTrainingCatalog:
        "تصفح كتالوج التدريب الخاص بمنظمة NFPA للحصول على خيارات التدريب من أجل زيادة معرفتك واكتساب الثقة أثناء العمل وتحسين مطالبك المهنية.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion:
        "بإنشاء هذا الإصدار الجديد، سيظل المتعلمون الذين أكملوا الإصدار السابق في ذلك الإصدار. أي متعلم قام بالتسجيل في الإصدار السابق ولكنّه لم يبدأ، سيتلقى هذا الإصدار الجديد. ستتمكن من اختيار كيفية تطبيق هذا الإصدار على المتعلمين الذين يحرزون تقدمًا في الإصدار الحالي.",
    canIChangeOrUpdateTheCourseMaterials: "‫هل يمكنني تغيير مواد الدورة الدراسية أو تحديثها‬؟",
    canIExportBadgesIssuedThroughCredlyToOtherBadgestoringPlatforms:
        "هل يمكنني تصدير الشارات الصادرة من خلال Credly إلى منصات أخرى لتخزين الشارات؟",
    canIImportBadgesIssuedFromOtherPlatformsIntoCredly:
        "هل يمكنني استيراد الشارات الصادرة من منصات أخرى إلى Credly؟",
    canIRequestAParticularInstructor: "هل يمكنني طلب معلم معين؟",
    canIRequestPhysicalMaterialsForAClass: "هل يمكنني طلب مواد مادية للحصة الدراسية؟",
    canISeeHowParticipantsScoredOnTheAssessments:
        "هل يمكنني أن أرى النتائج التي حققها المشاركون في التقييم (التقييمات)؟",
    canIViewTheCourseEvaluations: "‫هل يمكنني الاطلاع على تقييمات الدورة الدراسية‬؟",
    canMyOrganizationOrTheApprovedInstructorChangeOrUpdateTheCourseMaterials:
        "هل يمكن لمؤسستي أو المعلم المعتمد تغيير مواد الدورة الدراسية أو تحديثها؟",
    changesToAssessmentsAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeAssessments:
        "لن يتوفر إجراء تغييرات على التقييمات عند تعديل إصدار الدورة الدراسية الحالي. لتغيير التقييمات،",
    changesToAssessmentsAreTheTriggerForNewVersions:
        "تؤدي التغييرات في التقييمات إلى تنشيط الإصدارات الجديدة.",
    changesToAssessmentsMustBeMadeInOrderToUnlockTheOtherSections:
        "يجب إجراء تغييرات على التقييمات لفتح الأقسام الأخرى.",
    changesToAssessmentsOrEvaluationAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheseItemsYouMustCreateANewCourseVersion:
        "لن يتوفر إجراء تغييرات على التقييمات أو التقديرات عند تعديل إصدار الدورة الدراسية الحالي. لتغيير هذه العناصر، يجب عليك إنشاء إصدار جديد للدورة الدراسية.",
    changesToCoursesAreTheTriggerForNewVersions:
        "‏‫تؤدي التغييرات في الدورات الدراسية إلى تنشيط الإصدارات الجديدة.‬",
    changesToCoursesMustBeMadeInOrderToUnlockTheOtherSections:
        "يجب إجراء تغييرات على الدورات الدراسية لفتح الأقسام الأخرى.",
    changesToEvaluationAreUnavailableFollowingInitialCreationOfTheProduct:
        "لا تتوفر التغييرات على التقييم بعد الإنشاء الأولي للمنتج.",
    changesToRecommendedCEUsWillOnlyBeAppliedToNewAndUnpublishedEventsAsFinalEventCEUsAreDeterminedByTheIndividualEventsSchedule:
        "سيتم تطبيق التغييرات التي تم إجراؤها على ‏‫وحدات التعليم المستمر (CEU)‬ الموصى بها فقط على الأحداث الجديدة وغير المنشورة، حيث يتم تحديد وحدات CEU للحدث النهائي من خلال جدول الحدث الفردي.",
    changesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "سيتم تطبيق التغييرات على جميع المتعلمين في هذا الإصدار، بغض النظر عن حالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة الدراسية من جديد.",
    CompletedTrainingCanBeViewedOnTheMyLearningDashboardForTrainingWhereAccessHasNotExpiredViewTheAssignmentsSectionOnEitherTheOnlineOrInstructorledTabsAndSelectViewTrainingForTrainingWhereAccessHasExpiredViewThePastTrainingsListAtTheBottomOfTheAssignmentsPageAndSelectViewSummary:
        '‫يمكن الاطلاع على التدريب المكتمل على لوحة معلومات "تعلّمي‬". ‫بالنسبة للتدريب الذي لم تنته صلاحية الوصول إليه، قم بعرض قسم "المهام"‬ في علامتي التبويب "عبر الإنترنت" أو "تحت إشراف المعلم" وحدد "عرض التدريب". بالنسبة للتدريب الذي انتهت صلاحية الوصول إليه، قم بعرض قائمة "التدريبات السابقة" أسفل صفحة "المهام" وحدد "عرض الملخص".',
    contentBadgeAndCertificateExpirationChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "سيتم تطبيق التغييرات على المحتوى والشارة و‏‫تاريخ انتهاء الشهادة‬ على جميع المتعلمين في هذا الإصدار، بغض النظر عن حالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة الدراسية من جديد.",
    contentChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "سيتم تطبيق تغييرات المحتوى على جميع المتعلمين في هذا الإصدار، بغض النظر عن حالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة الدراسية من جديد.",
    createANewProductVersion: "إنشاء إصدار منتج جديد",
    credlyIsTheEndtoendSolutionForIssuingAndManagingDigitalCredentialsCredlyWorksWithCredibleOrganizationsToProvideDigitalCredentialsToIndividualsWorldwide:
        "يعتبر Credly الحل الشامل لإصدار بيانات الاعتماد الرقمية وإدارتها. يعمل Credly مع منظمات ذات مصداقية لتوفير بيانات الاعتماد الرقمية للأفراد في جميع أنحاء العالم.",
    CurrentlyYouMayChangeTheDefaultLanguageOfMyLearningFromEnglishToSpanishOrArabicOnlyYouCanDoThisInTheMyProfilePage:
        '‫حاليًا، يمكنك تغيير اللغة الافتراضية في "تعلّمي‬" من الإنجليزية إلى الإسبانية أو العربية فقط. يمكنك القيام بذلك في صفحة "ملفي الشخصي".',
    EachTrainingContainsACourseEvaluationWhereYouWillAnswerSpecificQuestionsAboutTheCourseAsWellAsHaveAnOpportunityToProvideOpenendedFeedback:
        "يحتوي كل تدريب على تقييم للدورة الدراسية حيث ستجيب عن أسئلة محددة حول الدورة الدراسية بالإضافة إلى إتاحة الفرصة لتقديم ملاحظات مطلقة.",
    EachTrainingContainsACourseEvaluationWhereYouWillAnswerSpecificQuestionsAboutTheInstructorAndEventEnvironmentAsWellAsHaveAnOpportunityToProvideOpenendedFeedback:
        "يحتوي كل تدريب على تقييم للدورة الدراسية حيث ستجيب عن أسئلة محددة حول المدرب وبيئة الحدث بالإضافة إلى إتاحة الفرصة لتقديم ملاحظات مطلقة.",
    editProductDetails: "تعديل تفاصيل المنتج",
    editVersionProductVersionVersionId: "تعديل الإصدار {{productVersionVersionId}}",
    eventsThatHaveCompletedThePreviousVersionWillRemainOnThatVersion:
        "ستظل الأحداث التي أكملت الإصدار السابق في هذا الإصدار.",
    expirationDuration: "مدة انتهاء الصلاحية",
    fromYourTeachingAssignmentsSelectTheViewTrainingButtonOnTheAboutSectionOfTheEventYouWillFindTheContactInformationForTheEventHost:
        'من "مهامي التعليمية"، حدد زر "عرض التدريب". في القسم "نبذة عن" الخاص بالحدث، ستجد معلومات الاتصال الخاصة بمضيف الحدث.',
    fromYourTeachingAssignmentsSelectTheViewTrainingButtonOnTheEvaluationSectionOfTheEventYouWillFindASummaryOfTheEvaluationResultsParticipantsAreAskedToProvideFeedbackAboutTheInstructorOnTheFirstSixQuestionsTheSixthQuestionIsHowLikelyWouldYouBeToRecommendThisInstructorThisIsIndicativeOfYourOverallInstructorRating:
        'من "مهامي التعليمية"، حدد زر "عرض التدريب". في قسم "التقييم" الخاص بالحدث، ستجد ملخصًا لنتائج التقييم. يُطلب من المشاركين تقديم ملاحظات حول المعلم في الأسئلة الستة الأولى. السؤال السادس هو: ما مدى احتمالية أن توصي بهذا المعلم؟ وهذا مؤشر على التقييم العام للمعلم الخاص بك.',
    howCanIChangeTheDefaultLanguage: "كيف يمكنني تغيير اللغة الافتراضية؟",
    howCanIOrderPhysicalMaterialsForAnUpcomingClass:
        "كيف يمكنني طلب المواد المادية للحصة الدراسية القادمة؟",
    howCanIUpdateMyProfileInformation: "كيف يمكنني تحديث معلومات ملفي الشخصي؟",
    howCanIViewMyCompletedTraining: "كيف يمكنني الاطلاع على التدريب المكتمل الخاص بي؟",
    howCanIViewprintMyCertificatesbadges: "كيف يمكنني عرض/طباعة شهاداتي/شاراتي؟",
    howDoesMyCertificationGetDisplayedAsABadge: "كيف يتم عرض شهادتي كشارة؟",
    howDoIGetAClassScheduled: "كيف يمكنني تحديد موعد للحصة الدراسية؟",
    howDoIJoinALiveVirtualTrainingSession: "كيف يمكنني الانضمام إلى جلسة تدريبية افتراضية مباشرة؟",
    howWillIKnowIfIveEarnedABadge: "كيف أعرف أنني حصلت على شارة؟",
    howWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVersionWillResetTheLearnersProgressForTheCoursesThatHaveBeenEdited:
        "كيف تريد تطبيق هذا الإصدار الجديد على المتعلمين قيد التقدم؟ لن تتم إعادة التقدم المحرز من جديد في أي دورة دراسية في الإصدار الجديد.",
    ifYouWouldLikeToApplyThisNewVersionToInprogressEventsOnlyItemsThatHaveBeenChangedWillBeEffected:
        "إذا كنت ترغب في تطبيق هذا الإصدار الجديد على الأحداث قيد التقدم، فلن تتأثر سوى العناصر التي تم تغييرها.",
    ifYouWouldLikeToApplyThisNewVersionToInprogressLearnersOnlyItemsThatHaveBeenChangedWillBeEffected:
        "إذا كنت ترغب في تطبيق هذا الإصدار الجديد على المتعلمين قيد التقدم، فلن تتأثر سوى العناصر التي تم تغييرها.",
    iHaveAQuestionAboutCredlyWhereCanIFindSupport:
        "لدي سؤال حول Credly. أين يمكنني الحصول على الدعم؟",
    isThereAFeeToUseCredly: "هل هناك رسوم لاستخدام Credly؟",
    laborMarketInsightsArePulledFromLiveJobRequisitionsBasedOnYourSkillsYouCanLearnWhichEmployersAreHiringWhatJobTitlesYouMightBeQualifiedForSalaryRangesAndMoreSearchActiveJobListingsAndEvenApplyForThemWithJustAFewClicksThroughCredlyAccessTheLaborMarketInsightsFromYourBadgeDetailsPageByClickingOnRelatedJobsOrByClickingOnTheSkillTagsAssignedToYourBadge:
        'يتم استنتاج الرؤى بشأن سوق العمل من طلبات العمل المباشرة. بناءً على مهاراتك، يمكنك التعرّف على أصحاب العمل الذين يقومون بالتوظيف، والمسميات الوظيفية التي قد تكون مؤهلاً لها، وحدود الرواتب، وغير ذلك الكثير. ابحث في قوائم الوظائف النشطة وقم بالتقدم لها عن طريق بضع نقرات فقط من خلال Credly. قم بالوصول إلى رؤى سوق العمل من صفحة تفاصيل شارتك من خلال النقر على "الوظائف المناسبة"، أو من خلال النقر على علامات المهارات المخصصة لشارتك.',
    learners: "المتعلمون",
    liveEvents: "أحداث مباشرة",
    liveEventsRegardlessOfProgressAndAllPastCompletedEventsWillContinueToUseThisProduct:
        "ستستمر الأحداث المباشرة، بغض النظر عن التقدم المحرز، وجميع الأحداث السابقة/المكتملة، في استخدام هذا المنتج.",
    liveOrPastCompletedEvents: "الأحداث المباشرة أو السابقة/المكتملة",
    LocateYourCompletedTrainingSeeFAQHowCanIViewMyCompletedTrainingUponViewingYourCompletedTrainingNavigateToTheAchievementsSectionFromTheLefthandPaneSelectTheButtonToDownloadCertificateAndorGoToCredly:
        'حدد موقع التدريب المكتمل الخاص بك. راجع الأسئلة الشائعة (FAQ): كيف يمكنني الاطلاع على التدريب المكتمل الخاص بي؟ عند عرض التدريب المكتمل، انتقل إلى قسم "الإنجازات" من الجزء الأيسر. حدد الزر لتنزيل الشهادة و/أو انتقل إلى Credly.',
    needFurtherAssistanceWithTheLMSOrAccessingYourTraining:
        "هل تحتاج إلى مزيد من المساعدة بشأن نظام LMS أو بشأن الوصول إلى التدريب الخاص بك؟",
    newEvents: "أحداث جديدة",
    noAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "لا، لقد تمت مراجعة جميع مواد الدورة الدراسية من قِبل الموظفين الفنيين في NFPA. ",
    noContractResultsFound: "لم يتم العثور على نتائج العقد",
    noCourseResultsFound: "لم يتم العثور على نتائج الدورة الدراسية",
    noEventResultsFound: "لم يتم العثور على نتائج الحدث",
    noHistoryAvailable: "لا يوجد تاريخ متاح",
    noInstructorResultsFound: "لم يتم العثور على نتائج المعلم",
    noLearnersEnrolled: "لم يتم تسجيل أي متعلمين",
    noProductResultsFound: "لم يتم العثور على نتائج المنتج",
    noTrainingResultsFound: "لم يتم العثور على نتائج التدريب.",
    openBadgesAreWebenabledVersionsOfACredentialCertificationOrLearningOutcomeThatCanBeVerifiedInRealTimeOnline:
        "الشارات المفتوحة هي إصدارات متاحة على الويب لبيانات الاعتماد أو الشهادات أو النتائج التعليمية التي يمكن التحقق منها في الوقت الفعلي عبر الإنترنت.",
    pastOnlineLearningTrainings: "‫التدريبات‬ السابقة المتعلقة بالتعلّم عبر الإنترنت",
    pleaseContactNFPAYouCanSelectTheGoToSupportButtonOnTheAboutPageOfTheOnlineTrainingOnceOnTheSupportPagePleaseSelectTheButtonToSubmitYourRequestToNFPATheNFPACustomerSupportTeamWillGetBackToYouWithinBusinessHoursYouCanAlsoCallCustomerServiceDirectlyAt:
        'يُرجى التواصل مع NFPA. يمكنك تحديد زر "الانتقال إلى الدعم" في صفحة "نبذة عن" الخاصة بالتدريب عبر الإنترنت. بمجرد الوصول إلى صفحة الدعم، يُرجى تحديد الزر لإرسال طلبك إلى NFPA. سيقوم فريق دعم العملاء في NFPA بالرد عليك خلال 24 ساعة عمل. ',
    PleaseContactTheHostForTheEventTheHostContactInformationIsLocatedOnTheAboutPageOfTheEventUnderTheContactSection:
        "يُرجى التواصل مع مضيف هذا الحدث. توجد معلومات التواصل مع المضيف في صفحة حول الحدث ضمن قسم جهة الاتصال.",
    pleaseContactTheProviderOfTheEventToCancelOrReschedule:
        "يُرجى التواصل مع موفر الحدث للإلغاء أو إعادة الجدولة.",
    pleaseEnsureInstructorAvailabilityPriorToSettingUpATrainingTheLMSWillNotLetYouPublishAnEventUntilYouVerifyThatTheInstructorAvailabilityHasBeenConfirmed:
        "يُرجى التأكد من توفر المدرب قبل إعداد التدريب. لن يسمح لك نظام إدارة التعلم (LMS) بنشر حدث حتى تتحقق من أنه تم التأكيد على توفر المدرب.",
    pleaseReferenceYourContractWithTheInstructorAndWorkDirectlyWithThemToRectifyTheIssue:
        "يُرجى الرجوع إلى تعاقدك مع المدرب والعمل معه مباشرةً لتصحيح المشكلة.",
    pleaseSelectTheButtonToSubmitYourQuestionToNFPATheNFPACustomerSupportTeamWillGetBackToYouWithinBusinessHours:
        "يُرجى تحديد الزر لإرسال سؤالك إلى NFPA. سيقوم فريق دعم العملاء في NFPA بالرد عليك خلال 24 ساعة عمل.",
    pleaseSelectTheEventLinkOrCopyTheLinkToYourInternetBrowserTheEventLinkIsLocatedOnTheAboutPageOfTheEventUnderTheLocationSection:
        'يُرجى تحديد رابط الحدث أو نسخ الرابط ولصقه في متصفح الإنترنت لديك. يوجد رابط الحدث في صفحة "حول الحدث" ضمن قسم "الموقع".',
    productMaterials: "مواد المنتج",
    aenHub: "مركز شبكة التعليم",
    representingYourSkillsWithABadgeGivesYouAMeansToShareYourAbilitiesOnlineInAWayThatIsSimpleTrustedAndCanBeEasilyVerifiedInRealTimeBadgesProvideEmployersAndPeersConcreteEvidenceOfWhatYouHadToDoToEarnYourCredentialAndWhatYoureNowCapableOfCredlyAlsoOffersLaborMarketInsightsBasedOnYourSkillsYouCanSearchAndApplyForJobOpportunitiesRightThroughCredly:
        "يمنحك تمثيل مهاراتك من خلال شارة وسيلة لمشاركة قدراتك عبر الإنترنت بطريقة بسيطة وموثوقة ويمكن التحقق منها بسهولة في الوقت الفعلي. كما توفر الشارات لأصحاب العمل والأقران دليلاً ملموسًا على المهام التي كان عليك القيام بها للحصول على بيانات الاعتماد الخاصة بك والمهام التي يمكنك القيام بها الآن. يقدم Credly أيضًا رؤى حول سوق العمل، وفقًا لمهاراتك. يمكنك البحث عن فرص العمل والتقدم لها مباشرةً عبر Credly.",
    selectTheEducationNetworkHubEitherFromTheQuickLinksOnYourDashboardOrFromTheHamburgerIconOnTheRighthandSideInTheHubYouWillFindALinkToTheNFPACatalogSpecificallyForAENMaterialsWhereYouCanPlaceYourOrder:
        "حدد مركز شبكة التعليم إما من الروابط السريعة الموجودة على لوحة المعلومات لديك أو من أيقونة القائمة المضغوطة الموجودة على الجانب الأيمن. ستجد في المركز رابطًا إلى كتالوج NFPA المخصص لمواد AEN، يمكنك من خلاله تقديم طلبك.",
    thereAreCurrentlyNoOnlineLearningContractsForThisOrganization:
        "لا توجد حاليًا تعاقدات للتعلّم عبر الإنترنت لهذه المؤسسة‬.",
    thereWasAnIssueLoadingLearners: "حدثت مشكلة أثناء تحميل المتعلمين.",
    thereWasAnIssueUpdatingTheProductVersion: "حدثت مشكلة أثناء تحديث إصدار المنتج.",
    thereWasAProblemReturningPastContractSummaries:
        "حدثت مشكلة أثناء إرجاع ملخصات التعاقدات السابقة.",
    thisNewVersionHasBeenPrepopulatedBasedOffOfThePreviousVersionToUseAsAStartingPoint:
        "تم تحديد هذا الإصدار الجديد مسبقًا استنادًا إلى الإصدار السابق لاستخدامه كنقطة بداية.",
    thisProductWillNoLongerBeAvailableToUseForNewEventsIncludingAnyCurrentDraftEvents:
        "لن يكون هذا المنتج متاحًا للاستخدام في الأحداث الجديدة، بما في ذلك أي مسودة للأحداث الحالية.",
    viewAnnouncement: "عرض الإعلان",
    viewVersion: "عرض الإصدار",
    whatAreLaborMarketInsightsAndHowCanIAccessThemThroughCredly:
        "ما الرؤى المتعلقة بسوق العمل وكيف يمكنني الوصول إليها عبر Credly؟",
    whatAreTheBenefitsOfABadge: "ما فوائد الشارة؟",
    whatIfCourseMaterialsDontArriveOnTimeForClass:
        "ماذا لو لم تتوفر مواد الدورة الدراسية في الوقت المحدد في الحصة الدراسية؟",
    whatIfIDontWantMyBadgeToBePublic: "ماذا لو أردت ألا تكون شارتي مرئية للعامة؟",
    whatIfIHaveFeedbackAboutAnInstructorOrTheEnvironmentOfAnInstructorledEvent:
        "ماذا لو كانت لديّ ملاحظات حول أحد المدربين أو بيئة الحدث الذي يديره المدرب؟",
    whatIfIHaveFeedbackAboutCourseContent: "ماذا لو كانت لديّ ملاحظات حول محتوى الدورة الدراسية؟",
    whatIfIHaveQuestionsAboutAnInstructorledEventEgStartTimeLocation:
        "ماذا لو كانت لديّ أسئلة حول أحد الأحداث التي يديرها المدرب (على سبيل المثال، وقت البدء والموقع)؟",
    whatIfIHaveTroubleAccessingMyTrainingInTheLMS:
        "ماذا لو واجهت مشكلة في الوصول إلى تدريبي في نظام إدارة التعلم (LMS)؟",
    whatIfIHaveTroubleAccessingTheLMS: "ماذا لو واجهت مشكلة في الوصول إلى نظام إدارة التعلم (LMS)؟",
    whatIfIHaveTroubleAccessingTheLMSOrTakingAttendance:
        "ماذا لو واجهت مشكلة في الوصول إلى نظام إدارة التعلم (LMS) أو تسجيل الحضور؟",
    whatIfIHaveTroubleSettingUpOrManagingEventsInTheLMS:
        "ماذا لو واجهت مشكلة في إعداد الأحداث أو إدارتها في نظام إدارة التعلم (LMS)؟",
    whatIfINeedToCancelrescheduleAnEventDate:
        "ماذا لو كنت بحاجة إلى إلغاء/إعادة جدولة تاريخ الحدث؟",
    whatIfINeedToChangeOrCancelAClass: "ماذا لو كنت بحاجة إلى تغيير أو إلغاء الحصة الدراسية؟",
    whatIfINeedToChangeOrCancelAnEvent: "ماذا لو كنت بحاجة إلى تغيير أو إلغاء أحد الأحداث؟",
    whatIfINeedToWithdrawFromAnInstructorledCourse:
        "ماذا لو كنت بحاجة إلى الانسحاب من دورة دراسية يديرها مدرب؟",
    whatIfINeedToWithdrawFromAnOnlineLearningCourse:
        "ماذا لو كنت بحاجة إلى الانسحاب من دورة دراسية عبر الإنترنت؟",
    whatIfTheInstructorDoesntShowUpForClass: "ماذا لو لم يحضر المدرب الحصة الدراسية؟",
    whatIfThereAreNoInstructorsAvailableOnTheDateOfMyClass:
        "ماذا لو لم يكن هناك مدربين متاحين في تاريخ حصتي الدراسية؟",
    whatIsAnOpenBadge: "ما هي الشارة المفتوحة؟",
    whatsToKeepSomeoneElseFromCopyingMyBadgeAndUsingIt:
        "ما الذي يمنع شخصًا آخر من نسخ شارتي واستخدامها؟",
    whatWillHappenToMyNFPABadgeFromBADGR: "ماذا سيحدث لشارة NFPA الخاصة بي من BADGR؟",
    whereAndHowCanIShareMyBadgeThroughCredly:
        "أين يمكنني مشاركة شارتي من خلال Credly وكيف يمكنني القيام بذلك؟",
    whereCanIFindMyInstructorRatingForAClass:
        "أين يمكنني العثور على تقييم المدرب الخاص بي للحصة الدراسية؟",
    whereCanIFindThePrimaryContactOfTheTrainingProvider:
        "أين يمكنني العثور على جهة الاتصال الرئيسية لموفر التدريب؟",
    whereYouCanProcessTheUpdatesThisWillAutomaticallyUpdateYourInformationInTheOnlineLearningPortalWithinHours:
        "المكان الذي يمكنك فيه معالجة التحديثات. سيؤدي هذا إلى تحديث معلوماتك تلقائيًا في بوابة التعلم عبر الإنترنت خلال 24 ساعة.",
    whileBadgesAreSimplyDigitalImageFilesTheyAreUniquelyLinkedToDataHostedOnCredlyThisLinkToVerifiedDataMakesThemMoreReliableAndSecureThanAPaperbasedCertificateItAlsoEliminatesThePossibilityOfAnyoneClaimingYourCredentialAndYourAssociatedIdentity:
        "على الرغم من أن الشارات هي مجرد ملفات صور رقمية، إلا إنها مرتبطة بشكل فريد بالبيانات المستضافة على Credly. وهذا الارتباط بالبيانات التي تم التحقق منها يجعلها أكثر موثوقية وأمانًا من الشهادة الورقية. كما أنه يلغي احتمالية مطالبة أي شخص ببيانات اعتمادك وهويتك المرتبطة بها.",
    whoDoIContactIfThereAreTechnologyIssuesDuringClass:
        "ما الجهة التي يمكنني التواصل معها إذا كانت هناك مشكلات تقنية أثناء الحصة الدراسية؟",
    whoDoIContactWithFeedbackOnTheTrainingMaterials:
        "ما الجهة التي يمكنني التواصل معها للإبلاغ عن الملاحظات حول المواد التدريبية؟",
    whatIsCredly: "ما هي Credly؟",
    whoIsMyPrimaryContactAtNFPA: "ما جهة الاتصال الأساسية لديّ في NFPA؟",
    whyWasIOnlyGivenDigitalMaterialsForMyInstructorledClass:
        "لماذا تم منحي فقط المواد الرقمية للحصة الدراسية التي يديرها المدرب؟",
    yesYouCanDownloadYourBadgeFromTheShareBadgePageYourDownloadedBadgeContainsOpenBadgeInfrastructureOBICompliantMetadataEmbeddedIntoTheImageThisAllowsYouToStoreYourBadgeOnOtherOBIcompliantBadgeSitesSuchAsTheBadgrBackpack:
        'نعم، يمكنك تنزيل شارتك من صفحة "مشاركة الشارة". تحتوي الشارة التي تم تنزيلها على بيانات تعريف متوافقة مع بنية Open Badge (‏OBI) المضمنة في الصورة. يتيح لك هذا تخزين شارتك على مواقع شارات أخرى متوافقة مع OBI، مثل Badgr Backpack.',
    youAreActivatingProductVersionVersionId: "‏‫أنت تقوم بتنشيط {{productVersionVersionId}}",
    youAreGoingToEditTheProductDetails: "ستقوم بتعديل تفاصيل المنتج.",
    youAreViewingAnArchivedProduct: "أنت تشاهد منتجًا مؤرشفًا",
    youCanEditTheProductsContentAndAchievements: "يمكنك تعديل محتوى المنتج والإنجازات.",
    youCanFindTutorialsAndAnswersToAdditionalQuestionsHere:
        "يمكنك العثور على البرامج التعليمية والإجابات على الأسئلة الإضافية هنا:",
    youCannotSaveThisVersionUntilAllSectionsAreComplete:
        "لا يمكنك حفظ هذا الإصدار حتى تكتمل جميع الأقسام.",
    youCanShareYourBadgeDirectlyFromCredlyToLinkedInTwitterAndFacebookOverEmailEmbeddedInAWebsiteOrInYourEmailSignature:
        "يمكنك مشاركة شارتك مباشرةً من Credly إلى LinkedIn أو Twitter أو Facebook؛ أو عبر البريد الإلكتروني؛ أو التضمين في موقع الويب؛ أو في توقيع البريد الإلكتروني لديك.",
    youCanUpdateYourInformationSuchAsTheSpellingOfYourNameOrYourEmailAddressByGoingToMyProfileWhichCanBeAccessedByUsingTheHamburgerIconWithinTheOnlineLearningPortalALinkWithinTheBasicInformationSectionWillDirectYouToYourNFPAAccountAt:
        "يمكنك تحديث معلوماتك بالانتقال إلى ملفي الشخصي، والذي يمكن الوصول إليه باستخدام رمز الهامبرغر (☰) داخل بوابة التعلم عبر الإنترنت. سيوجهك رابط داخل قسم المعلومات الأساسية إلى حساب NFPA الخاص بك على",
    youHaveNoCurrentInstructorLedTrainings: "ليس لديك أي تدريبات حالية تحت إشراف المدرب",
    youHaveNoCurrentOnlineTrainings: "ليس لديك أي تدريبات حالية عبر الإنترنت",
    youHaveNoPastTrainings: "ليس لديك أي تدريبات سابقة",
    yourInstructorProfileCanBeViewedByNFPAStaffAsWellAsAENProvidersYourProfileIsUsedToAssistInTheSelectionOfInstructorAssignments:
        "يمكن الاطلاع على الملف الشخصي للمدرب الخاص بك من قبل موظفي NFPA وكذلك مقدمي خدمة AEN. يتم استخدام ملفك الشخصي للمساعدة في اختيار مهام المدرب.",
    yourOrganizationHasNoCurrentInstructorLedTrainings:
        "لا تتوفر لدى مؤسستك تدريبات حالية تحت إشراف مدرب",
    yourOrganizationHasNoCurrentOnlineTrainings: "لا تتوفر لدى مؤسستك تدريبات حالية عبر الإنترنت",
    yourOrganizationHasNoPastInstructorLedTrainings:
        "لا تتوفر لدى مؤسستك تدريبات سابقة تحت إشراف مدرب",
    youveCreatedVersionVersionNumber: "لقد قمت بإنشاء الإصدار {{versionNumber}}",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToEventsThatAreCurrentlyInProgress:
        "سيكون لديك خيار بشأن كيفية تطبيق هذا الإصدار الجديد على الأحداث قيد التقدم حاليًا.",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToLearnersThatAreCurrentlyInProgress:
        "سيكون لديك خيار بشأن كيفية تطبيق هذا الإصدار الجديد على المتعلمين الموجودين حاليًا.",
    activateVersion: "تنشيط الإصدار",
    activatingCourseFailed: "فشل تنشيط الدورة الدراسية.",
    activatingProductFailed: "فشل تنشيط المنتج.",
    addAnInstructor: "إضافة معلّم",
    addAnOrganization: "إضافة مؤسسة",
    addInstructors: "إضافة معلمين",
    advanceYourCareerWithTraining: "تقدم في حياتك المهنية من خلال التدريب من المصدر مباشرة.",
    AnErrorOccurredWhileDownloadingTheContractCertificates: "حدث خطأ أثناء تنزيل شهادات العقد.",
    AnErrorOccurredWhileDownloadingTheEventCertificates: "حدث خطأ أثناء تنزيل شهادات الحدث.",
    announcementMustHaveAnEnglishTitleAndEnglishBody:
        "يجب أن يكون للإعلان عنوان باللغة الإنجليزية ونص باللغة الإنجليزية.",
    applyToLearners: "ينطبق على المتعلمين",
    archivingAnnouncementFailed: "فشلت أرشفة الإعلان.",
    archivingCourseFailed: "فشلت أرشفة الدورة الدراسية.",
    archivingProductFailed: "فشلت أرشفة المنتج.",
    AreYouSureYouWouldLikeToCancelWithoutSavingYourChanges:
        "هل أنت متأكد من رغبتك في الإلغاء دون حفظ التغييرات؟",
    assessmentsWillBeAvailableToCompleteOnLastEventDate:
        "سيكون التقييم/التقييمات متاحة لإكمالها في {{lastEventDate}}.",
    attended: "تم الحضور",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersion:
        "بإنشاء هذا الإصدار الجديد، سيظل المتعلمون الذين أكملوا الإصدار السابق في ذلك الإصدار. أي متعلم قام بالتسجيل في الإصدار السابق ولكنّه لم يبدأ، سيتلقى هذا الإصدار الجديد.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersionHowWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVesionWillResetTheLearnersProgressInThisCourse:
        "بإنشاء هذا الإصدار الجديد، سيظل المتعلمون الذين أكملوا الإصدار السابق في ذلك الإصدار. أي متعلم قام بالتسجيل في الإصدار السابق ولكن لم يبدأ، سيتلقى هذا الإصدار الجديد. كيف تريد تطبيق هذا الإصدار الجديد على المتعلمين الذين يحرزون تقدمًا في الإصدار الحالي؟ سيؤدي تطبيق الإصدار الجديد إلى بدء المتعلم هذه الدورة الدراسية‬ من جديد.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion:
        "بإنشاء هذا الإصدار الجديد، سيظل المتعلمون الذين أكملوا الإصدار السابق في ذلك الإصدار. أي متعلم قام بالتسجيل في الإصدار السابق ولكن لم يبدأ، سيتلقى هذا الإصدار الجديد. ستتمكن من اختيار كيفية تطبيق هذا الإصدار على المتعلمين الذين يحرزون تقدمًا في الإصدار الحالي.",
    cannotEmulateUser: "لا يمكن محاكاة المستخدم",
    certificateExpDateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString:
        "انتهاء صلاحية الشهادة {{DateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString}}",
    certificateExpirationDateVariable:
        "تاريخ انتهاء صلاحية‬ الشهادة: {{certificateExpirationDate}}",
    changeDescription: "تغيير الوصف",
    completionDateVariable: "تاريخ الإكمال‬: {{completionDate}}",
    contactInformationHidden: "معلومات جهة الاتصال مخفية",
    contactNFPA: "التواصل مع NFPA",
    continue: "متابعة",
    courseCompletionCriteria: "معايير إكمال الدورة الدراسية",
    courseCompletionCriteriaILT:
        "دورة دراسية تحت إشراف مدرب: يجب عليك الحضور لمدة كاملة للدورة الدراسية وإكمال التقييم لتلقي شهادة الإكمال.",
    courseCompletionCriteriaOLL:
        "دورة دراسية عبر الإنترنت: يجب عليك إكمال جميع الوحدات والتقييمات بنجاح لتلقي شهادة الإكمال.",
    createAnAccount: "إنشاء حساب",
    deactivatingCourseFailed: "فشل إلغاء تنشيط الدورة الدراسية.",
    deactivatingProductFailed: "فشل إلغاء تنشيط المنتج.",
    deleteFile: "حذف ملف",
    downloadCertificates: "تنزيل الشهادات",
    duplicateEmailsWillBeRemovedFromTheFileImportAndWillNotBeIncludedOnTheImportSummaryReport:
        "ستتم إزالة رسائل البريد الإلكتروني المكررة من واردات الملفات ولن يتم تضمينها في تقرير ملخص الواردات.",
    emailMustBeInFirstColumn: "يجب أن يكون البريد الإلكتروني في العمود الأول.",
    enrolledLearners: "المتعلمون المسجلون",
    enterSearchCriteria: "أدخِل معايير البحث...",
    eventDoesNotExist: "الحدث غير موجود.",
    eventMustHaveANameAndType: "يجب أن يكون للحدث اسم ونوع.",
    expExpirationDate: "انتهاء الصلاحية {{expirationDate}}",
    failedToRetrieveContents: "فشلت استعادة المحتويات.",
    failedToRetrieveInstructors: "فشلت استعادة المعلمين.",
    fileSizeIsTooLarge: "حجم الملف كبير جدًا.",
    generateAndDownloadCertificate: "إنشاء الشهادة وتنزيلها",
    gettingProductsCountFailed: "فشل حصر عدد المنتجات.",
    hour: "الساعة",
    howToFacilitateLearningTheNFPAWay: "كيفية تسهيل تعلّم نهج NFPA",
    howWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVersionWillResetTheLearnersProgressInTheCourse:
        "كيف تريد تطبيق هذا الإصدار الجديد على المتعلمين الذين يحرزون تقدمًا في الإصدار الحالي؟ سيؤدي تطبيق الإصدار الجديد إلى بدء المتعلم الدورة الدراسية‬ من جديد.",
    IApproveAnException: "أوافق على استثناء",
    instructors: "المعلمون",
    isThisCertificateForHotWork: "هل هذه الشهادة للأعمال الحرارية؟",
    lastSync: "آخر مزامنة",
    launchNotYetImplementedForOnlineTraining:
        "لم يتم ‏‫إطلاق الدورة الدراسية‬ بعد للتدريب عبر الإنترنت.",
    learningPortal: "بوابة التعلّم",
    nfpaLearningPortal: "بوابة التعلّم لدى NFPA",
    notSeeingChangesReflectedAbove: "ألا تتمكن من رؤية التغييرات الأخيرة الموضحة أعلاه؟",
    notSeeingLocationOrContactInformation:
        "ألا تتمكن من رؤية التغييرات الأخيرة في معلومات الموقع أو جهة الاتصال الموضحة أعلاه؟",
    or: "أو",
    packageImportStatus: "حالة استيراد الحزمة",
    privacyPolicy: "سياسة الخصوصية",
    productMustHaveANameTypeAndProductType: "يجب أن يكون للمنتج اسم ونوع ونوع منتج.",
    ReasonForException: "سبب الاستثناء",
    replaceFile: "استبدال الملف",
    saveChangesSummary: "حفظ ملخص التغييرات",
    selectAssignment: "تحديد المهمة‬",
    selectInstructors: "تحديد المعلمين",
    signIn: "تسجيل الدخول",
    startTime: "وقت البدء",
    syncData: "مزامنة البيانات",
    termsOfUse: "شروط الاستخدام",
    theEvaluationWillBeAvailableToCompleteOnLastEventDate:
        "سيكون التقييم متاحًا لإكماله في {{lastEventDate}}.",
    thereWasAnIssueCreatingTheNewVersion: "حدثت مشكلة أثناء إنشاء الإصدار الجديد.",
    thereWasAnIssueUpdatingTheCourseDetails: "حدثت مشكلة أثناء تحديث تفاصيل الدورة الدراسية.",
    thereWasAnIssueUpdatingTheCourseVersion: "حدثت مشكلة أثناء تحديث إصدار الدورة الدراسية.",
    thereWasAProblemReturningCourseDetailsArchive:
        "حدثت مشكلة أثناء إرجاع أرشيف تفاصيل الدورة الدراسية.",
    thisEventWasCanceledCanceledOnByCanceledByNameCanceledById:
        "تم إلغاء هذا الحدث في {{canceledOn}} بواسطة {{canceledByName}} {{canceledById}}",
    thisUsersInstructorRoleWasRemovedDeletedOnByFirstAndLastNameThisContentIsProvidedForHistoricalContextOnly:
        "تمت إزالة دور المعلم لهذا المستخدم في {{deletedOn}} بواسطة {{firstAndLastName}}. يتم توفير هذا المحتوى للسياق التاريخي فقط.",
    trainingAccessExpDateUtilsFormatDatetrueenrollmentExpirationDateToString:
        "انتهاء صلاحية الالتحاق بالتدريب {{DateUtilsFormatDatetrueenrollmentExpirationDateToString}}",
    typeToSearchByInstructorNameOrID: "إدخال للبحث حسب اسم المعلم أو المعرّف الخاص به",
    typeToSearchByOrganizationNameOrID: "إدخال للبحث حسب اسم المؤسسة أو المعرّف الخاص بها",
    unableToUpdateStatus: "تعذّر تحديث الحالة.",
    unsupportedCultureCodeLocation: "موقع القواعد الثقافية غير مدعوم.",
    versionStatus: "حالة الإصدار",
    withdrawingEnrollmentFailed: "فشل سحب التسجيل.",
    youAreActivatingVersionCourseVersionVersionId:
        "أنت تقوم بتنشيط الإصدار {{courseVersionVersionId}}",
    youAreBuildingAProductCourseTypeDisplayNamesproductOnlineLearningTypeToChangeToACourseOrSeries:
        "أنت تقوم بإنشاء {{ProductCourseTypeDisplayNamesproductOnlineLearningType}}. للتغيير إلى دورة دراسية أو سلسلة، ",
    youAreBuildingAProductCourseTypeDisplayNamesproductOnlineLearningTypeToChangeToALearningPath:
        "أنت تقوم بإنشاء {{ProductCourseTypeDisplayNamesproductOnlineLearningType}}. للتغيير إلى مسار التعلّم، ",
    youAreEditingTheCurrentVersion: "أنت تقوم بتحرير الإصدار الحالي",
    youMustAddAScormPackageToCreateANewVersion: "يجب عليك إضافة حزمة Scorm لإنشاء إصدار جديد!",
    yourNewVersionHasBeenCreatedAndTheZIPPackageIsCurrentlyProcessingOnceTheUploadIsCompleteMoveForwardToActivatingTheVersionToApplyIt:
        "تم إنشاء الإصدار الجديد الخاص بك وتتم حاليًا معالجة الحزمة المضغوطة. بمجرد اكتمال التحميل، انتقل إلى تفعيل الإصدار لتطبيقه.",
    youveCreatedVersionCourseVersionVersionId: "لقد قمت بإنشاء الإصدار {{courseVersionVersionId}}",
    youWereWithdrawnFromThisTrainingOnWithdrawnDateForAnyQuestionsPleaseContactNFPASupport:
        "لقد تم استبعادك من هذا التدريب بتاريخ {{withdrawnDate}}. إذا كان لديك أي أسئلة، يرجى الاتصال بقسم الدعم في NFPA.",
    fileFormatMustBePropsFileFormatAndLessThanPropsMaxFileSizeInMBMB:
        "يتعين أن يكون تنسيق هذا الملف {{propsFileFormat}} وبحجم أقل من {{propsMaxFileSizeInMB}} ميجابايت.",
    about: "حول",
    aboutMe: "معلومات حولي",
    aboutThisCourse: "معلومات حول هذه الدورة الدراسية",
    aboutThisTraining: "معلومات حول هذا التدريب",
    aboutThisUnit: "معلومات حول هذه الوحدة",
    accessLocked: "الوصول مقفل",
    account: "الحساب",
    accountFirstName: "الاسم الأول للحساب",
    accountLastName: "الاسم الأخير للحساب",
    achievements: "الإنجازات",
    aContractAlreadyExistsWithThisNumber: "هناك تعاقد بالفعل بهذا الرقم.",
    action: "الإجراء",
    activateCourse: "تنشيط الدورة الدراسية",
    activateProduct: "تنشيط المنتج",
    activatingTheProductFailedPleaseEnsureThereIsAProductNameDescriptionAndCoursesConfigured:
        "فشل تنشيط المنتج. يُرجى التأكد من تكوين اسم المنتج والوصف والدورات الدراسية.",
    activationSummary: "ملخص عملية التنشيط",
    active: "نشِط",
    activity: "النشاط",
    add: "إضافة",
    addAContract: "إضافة تعاقد",
    addADay: "إضافة يوم",
    addAnEvent: "إضافة حدث",
    addAProduct: "إضافة منتج",
    addASession: "إضافة جلسة",
    addAssessment: "إضافة تقييم",
    addAssessments: "إضافة تقييمات",
    addAttempt: "إضافة محاولة",
    addBulkEnrollment: "إضافة التسجيل الجماعي",
    addContent: "إضافة محتوى",
    addContract: "إضافة التعاقد",
    addContracts: "إضافة تعاقدات",
    addCourses: "إضافة دورة (دورات) دراسية",
    addCoursesToStartBuildingOutTheUnit: "أضف الدورات الدراسية لبدء بناء الوحدة.",
    addEnProvider: "إضافة موفر الدورة الدراسية بالغة الإنجليزية‬",
    addEnrollment: "إضافة تسجيل",
    addEvents: "إضافة أحداث",
    addInstructor: "إضافة معلم",
    additionalDetails: "تفاصيل إضافية",
    additionalMaterialHasBeenAddedSpecificToTheContract: "تمت إضافة مواد إضافية خاصة بالتعاقد",
    additionalRequirements: "متطلبات إضافية",
    AdditionalResourcesMayBeFoundWithinEachCourse:
        "يمكن العثور على موارد إضافية داخل كل دورة دراسية.",
    addLeaner: "إضافة متعلم",
    addLearners: "إضافة متعلمين",
    addNew: "إضافة جديد",
    addNewAnnouncement: "إضافة إعلان جديد",
    addNewContract: "إضافة تعاقد جديد",
    addNewContractOrganization: "إضافة مؤسسة بتعاقد جديد",
    addNewCourse: "إضافة دورة دراسية جديدة",
    addNewENProvider: "إضافة موفر جديد للدورة الدراسية باللغة الإنجليزية‬",
    addNewEvent: "إضافة حدث جديد",
    addNewProduct: "إضافة منتج جديد",
    addNewUser: "إضافة مستخدم جديد",
    addOrganization: "إضافة مؤسسة",
    addOrganizations: "إضافة مؤسسات",
    addPackage: "إضافة حزمة",
    addProduct: "إضافة المنتج",
    addProducts: "إضافة منتجات",
    address: "العنوان",
    addressLine1: "سطر العنوان 1",
    addressLine1IsRequired: "سطر العنوان 1 مطلوب",
    addressLine2: "سطر العنوان 2",
    addToSchedule: "إضافة إلى الجدول الزمني",
    addUnit: "إضافة وحدة",
    addUser: "إضافة مستخدم",
    administratorSlashInstructor: "مدير/معلم",
    aenAdministrator: "مدير AEN",
    aenProvider: "مزود AEN",
    alabama: "ألاباما",
    alaska: "ألاسكا",
    all: "الكل",
    allDay: "طوال اليوم",
    allRowsMustHaveCompleteData: "يجب أن تحتوي جميع الصفوف على بيانات كاملة.",
    allTrainingsOllAndILT: "جميع التدريبات (OLL وILT)",
    allTrainingsReport: "تقرير حول جميع التدريبات",
    allUsersReport: "تقرير حول جميع المستخدمين",
    alreadyEnrolled: "مسجل بالفعل",
    anAccountWithThisEmailAlreadyExists: "هناك حساب بهذا البريد الإلكتروني بالفعل.",
    andDisplayedAs: "ويظهر على هيئة...",
    anErrorOccurredCancelingThisEvent: "حدث خطأ أثناء إلغاء هذا الحدث.",
    anErrorOccurredPublishingThisEvent: "حدث خطأ أثناء نشر هذا الحدث.",
    anErrorOccurredSavingYourSelection: "حدث خطأ أثناء حفظ اختيارك.",
    anErrorOccurredUpdatingThisEvent: "حدث خطأ أثناء تحديث هذا الحدث.",
    anErrorOccurredWhileAddingTheAttempt: "حدث خطأ أثناء إضافة المحاولة.",
    anErrorOccurredWhileSavingTheAttempt: "حدث خطأ أثناء حفظ المحاولة.",
    announcementBody: "نص الإعلان",
    announcementName: "اسم الإعلان",
    announcements: "الإعلانات",
    announcementTitle: "عنوان الإعلان",
    answer: "الإجابة",
    anyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersion:
        "أي متعلم قام بالتسجيل في الإصدار السابق ولكن لم يبدأ، سيتلقى هذا الإصدار الجديد.",
    approvedEventScheduleExceptionGetLastUpdatedText:
        "تم اعتماده {{eventScheduleExceptionGetLastUpdatedText}}",
    approvedInstructorsForThisProduct: "المعلمون المعتمدون لهذا المنتج",
    approvedToTeach: "المعتمدون للتدريس",
    arabic: "اللغة العربية",
    archive: "أرشفة",
    archiveCourse: "أرشفة الدورة الدراسية",
    archived: "مؤرشفة",
    archivedAnnouncements: "الإعلانات المؤرشفة",
    archiveProduct: "أرشفة المنتج",
    areYouSureYouWantToClearAllLanguageData: "هل تريد بالفعل مسح جميع بيانات اللغة؟",
    areYouSureYouWantToDeleteThisEvent: "هل تريد بالفعل حذف هذا الحدث؟",
    areYouSureYouWantToExit: "هل تريد بالفعل الخروج؟",
    areYouSureYouWantToRemoveCourseContentToDeleteContentName:
        "هل تريد بالفعل إزالة {{courseContentToDeleteContentName}}؟",
    areYouSureYouWantToRemoveEventDayToDeleteGetEventDateTextFromYourSchedule:
        "هل تريد بالفعل إزالة {{eventDayToDeleteGetEventDateText}} من جدولك الزمني؟",
    areYouSureYouWantToRemoveProductContentToDeleteContentName:
        "هل تريد بالفعل إزالة {{productContentToDeleteContentName}}؟",
    areYouSureYouWantToRemoveProductScormPackageToDeleteScormPackageName:
        "هل تريد بالفعل إزالة {{productScormPackageToDeleteScormPackageName}}؟",
    areYouSureYouWantToRemoveUnit: "هل تريد بالفعل إزالة {{unit}}؟",
    areYouSureYouWantToRemoveUnitCourseToDeleteCourseName:
        "هل تريد بالفعل إزالة {{unitCourseToDeleteCourseName}}؟",
    areYouSureYouWantToWithdrawThisLearner: "هل تريد بالفعل استبعاد هذا المتعلم؟",
    areYouSureYouWouldLikeToCancelWithoutSavingYourChanges:
        "هل أنت متأكد من رغبتك في الإلغاء دون حفظ التغييرات؟",
    areYouSureYouWouldLikeToChangeTheAssociatedProduct:
        "هل أنت متأكد من رغبتك في تغيير المنتج المرتبط؟ ",
    areYouSureYouWouldLikeToChangeTheEventSalesType:
        "هل أنت متأكد من رغبتك في تغيير نوع مبيعات الحدث؟",
    areYouSureYouWouldLikeToChangeYourScheduleToAllDayEvents:
        'هل أنت متأكد من رغبتك في تغيير جدولك الزمني إلى أحداث "طوال اليوم"؟',
    areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges:
        "هل أنت متأكد من رغبتك في المغادرة دون حفظ التغييرات؟",
    areYouSureYouWouldLikeToStartOver: "هل تريد بالفعل البدء من جديد؟",
    arizona: "أريزونا",
    arkansas: "أركنساس",
    arrowColumn: "عمود السهم",
    aShortDescriptionAboutYou: "وصف موجز عن نفسك.",
    assessment: "تقييم",
    assessmentDeletedSuccessfully: "تم حذف التقييم بنجاح.",
    assessmentMarkedComplete: "تم تمييز التقييم بعلامة كمكتمل.",
    assessmentName: "اسم التقييم",
    assessmentResult: "نتيجة التقييم",
    assessmentResults: "نتائج التقييم",
    assessments: "التقييمات",
    assessmentScore: "نقاط التقييم",
    assignATraining: "تعيين تدريب",
    assignEventContactInformation: "تعيين معلومات الاتصال بالحدث",
    assignments: "المهام",
    assignTraining: "تعيين تدريب (تدريبات)",
    associatedEnProvider: "موفر الدورة الدراسية باللغة إنجليزية المرتبط",
    associatedOrganization: "المؤسسة المرتبطة",
    associatedProduct: "المنتج المرتبط",
    associatedProductCannotBeEditedAfterContractIsCreated:
        "لا يمكن تعديل المنتج المرتبط بعد إنشاء التعاقد.",
    associatedProductSelection: "تحديد المنتج المرتبط",
    associatedUsers: "المستخدمون المرتبطون",
    associateThisUserToAnExistingOrganization: "ربط هذا المستخدم بمؤسسة موجودة.",
    asThePrimaryMethodToContactTheEventOrganizer: "كطريقة أساسية للاتصال بمنظم الحدث.",
    asYouAddDaysAndSessionsToYourEventScheduleTheyWillAppearHere:
        "عند قيامك بإضافة أيام وجلسات إلى جدول الأحداث الخاص بك، ستظهر هنا.",
    attempt: "المحاولة",
    attemptCreatedSuccessfully: "تم إنشاء المحاولة بنجاح.",
    attemptCreationFailed: "فشلت عملية إنشاء المحاولة.",
    attempted: "تمت المحاولة",
    attempts: "المحاولات",
    attemptUpdatedSuccessfully: "تم تحديث المحاولة بنجاح.",
    attendance: "الحضور",
    attendanceTrackedSuccessfully: "تم تتبع الحضور بنجاح.",
    attendanceTrackingIsNotAvailableUntilTheDayOfTheEvent:
        "تتبع الحضور سيكون غير متاح حتى يوم الحدث.",
    attendanceTrackingIsNotAvailableUntilTheStartOfTheEvent:
        "تتبع الحضور سيكون غير متاح حتى بداية الحدث.",
    authenticateAs: "المصادقة باسم",
    availabilityConfirmed: "تم تأكيد التوفر",
    availableForAen: "متوفر لـ AEN",
    backToAll: "العودة إلى الكل",
    backToAllAssessments: "العودة إلى كل التقييمات",
    backToAllAttendance: "العودة إلى كل الحضور",
    backToAllInstructorLedEventContracts: "العودة إلى جميع تعاقدات الأحداث تحت إشراف المعلم",
    backToAllOnlineLearningContracts: "العودة إلى جميع تعاقدات التعلّم عبر الإنترنت",
    backToAllTrainings: "العودة إلى جميع التدريبات",
    backToAllVersions: "العودة إلى جميع الإصدارات",
    backToAssessmentResults: "العودة إلى نتائج التقييم",
    backToCourse: "العودة إلى الدورة الدراسية",
    backToEvaluationSummary: "العودة إلى ملخص التقييم",
    backToParent: "العودة إلى {{parent}}",
    backToSyllabus: "العودة إلى المنهج",
    badge: "شارة",
    badgeName: "اسم الشارة",
    badges: "الشارات",
    basicInformation: "المعلومات الأساسية",
    beforeChoosingYourInstructor: "قبل اختيار معلمك.",
    bio: "السيرة الذاتية",
    browseCatalog: "تصفح الكتالوج",
    by: "من خلال",
    byDoingSoAnyInformationEnteredInTheEventsDetail:
        "من خلال القيام بذلك، ستتم إعادة تعيين أي معلومات تم إدخالها في أقسام تفاصيل الحدث والجدول الزمني ومعلم اللغة الإنجليزية.",
    byDoingSoAnyInformationEnteredInTheSchedule:
        "من خلال القيام بذلك، ستتم إعادة تعيين أي معلومات تم إدخالها في قسمي الجدول الزمني ومعلم اللغة الإنجليزية.",
    byDoingSoEventTimesWillBeRemoved: "من خلال القيام بذلك، ستتم إزالة أوقات الأحداث.",
    byReplacingTheAssociatedENProviderAnyInformationEnteredForEventContactInformationWillBeReset:
        "من خلال استبدال موفر الدورة الدراسية باللغة الإنجليزية المرتبط، ستتم إعادة تعيين أي معلومات تم إدخالها لمعلومات الاتصال الخاصة بالحدث.",
    byReplacingTheAssociatedOrganizationAnyInformationEnteredForEventContactInformationWillBeReset:
        "من خلال استبدال المؤسسة المرتبطة، ستتم إعادة تعيين أي معلومات تم إدخالها لمعلومات الاتصال الخاصة بالحدث.",
    byReplacingTheAssociatedProductAnyInformationEnteredInTheScheduleAndInstructorSectionsWillBeReset:
        "من خلال استبدال المنتج المرتبط، ستتم إعادة تعيين أي معلومات تم إدخالها في قسمي الجدول الزمني والمعلم.",
    california: "كاليفورنيا",
    cancel: "إلغاء",
    canceled: "تم الإلغاء",
    cancelEdit: "إلغاء التعديل",
    cancelEvent: "إلغاء الحدث",
    cancellationMessage: "رسالة الإلغاء",
    cancelledonBYCancelledByNameCancelledById:
        "{{cancelledByName}} {{cancelledById}} بواسطة {{cancelledOn}}",
    cancelVersion: "إلغاء الإصدار",
    ceCREDITS: "أرصدة CE",
    certificate: "الشهادة",
    certificateExp: "تاريخ انتهاء الشهادة",
    certificateExpiration: "تاريخ انتهاء الشهادة",
    certificateNameWarning:
        "هذا هو الاسم الذي سيظهر على الشهادات التي ستحصل عليها في نظام إدارة التعلم (LMS). إذا كنت بحاجة إلى إعادة إصدار الشهادة لأي سبب من الأسباب، يرجى التواصل مع NFPA.",
    certificationPrep: "الإعداد للشهادة",
    ceus: "وحدات التعليم المستمر (CEU)",
    ceusEnrollmentProductCreditHoursToString:
        "وحدات CEU:‏ {{enrollmentProductCreditHoursToString}}",
    ceusEventCEUs: "وحدات CEU:‏ {{eventCEUs}}",
    ceusEventProductCreditHoursToString: "وحدات CEU:‏ {{eventProductCreditHoursToString}}",
    ceusProductCreditHoursToString: "وحدات CEU:‏ {{productCreditHoursToString}}",
    ceusWillBeAvailableForDownloadOnceYouHaveCompletedAllRequiredElementsOfTheTraining:
        "ستكون وحدات CEU متاحة للتنزيل بمجرد إكمال جميع العناصر اللازمة للتدريب.",
    change: "تغيير",
    changedBy: "تم التغيير بواسطة",
    changeHistory: "تغيير التاريخ",
    changeLog: "تغيير السجل",
    changeOrder: "تغيير الترتيب",
    changeStatus: "تغيير الحالة",
    changeStatusTo: "تغيير الحالة إلى",
    changesToZIPPackageAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheZIPPackageCreateANewCourseVersion:
        "لن يتوفر إجراء تغييرات على الحزمة المضغوطة عند تعديل إصدار الدورة الدراسية الحالي. ولتغيير الحزمة المضغوطة، يجب عليك إنشاء إصدار جديد للدورة الدراسية.",
    changesWereMadeToThisSection: "تم إجراء تغييرات على هذا القسم.",
    changesWillBeAppliedToAllLearnersOnThisVersion:
        "سيتم تطبيق تغييرات المحتوى على جميع المتعلمين في هذا الإصدار، بغض النظر عن حالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة الدراسية من جديد.",
    changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "سيتم تطبيق تغييرات المحتوى على جميع المتعلمين، بغض النظر عن الإصدار وحالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة الدراسية من جديد.",
    changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrRestALearnersProgress:
        "سيتم تطبيق تغييرات المحتوى على جميع المتعلمين، بغض النظر عن حالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة من جديد.",
    checkBackSoon: "تحقق بعد قليل.",
    checklist: "القائمة المرجعية",
    checkmarkChecked: "تم تحديد علامة الاختيار",
    checkmarkUnchecked: "لم يتم تحديد علامة الاختيار",
    chooseDate: "اختيار موعد",
    chooseFile: "اختيار ملف",
    cityIsRequired: "المدينة مطلوبة",
    citySlashTown: "المدينة/البلدة",
    clearAll: "مسح الكل",
    clearAllFilters: "مسح كل عوامل التصفية",
    clientAdmin: "مسؤول العميل",
    clientAdminRoleCurrentlyHasAnActiveContractInTheLMS:
        "يمتلك دور مسؤول العميل حاليًا تعاقدًا نشطًا في نظام إدارة التعلم (LMS).",
    close: "إغلاق",
    colorado: "كولورادو",
    community: "المجتمع",
    complete: "إكمال",
    completed: "مكتمل",
    completedTrainings: "التدريبات المكتملة",
    completeUnitBeforeMovingForward: "أكمل الوحدة قبل المضي قدمًا.",
    completeUnitsInOrder: "إكمال الوحدات بالترتيب",
    completion: "الإتمام",
    completionDate: "تاريخ الإكمال",
    confirm: "تأكيد",
    connecticut: "كونيتيكت",
    contact: "جهة الاتصال",
    contactInfo: "معلومات جهة الاتصال",
    content: "المحتوى",
    contentAudience: "جمهور المحتوى",
    contentForAdministatorAndInstructor: "محتوى للمسؤول والمعلمين",
    contentForParticipants: "محتوى للمشاركين",
    continueToEdit: "متابعة للتعديل",
    continueToVersion: "متابعة إلى الإصدار",
    contract: "تعاقد",
    contractContractContractNumber: "رقم التعاقد {{contractContractNumber}}",
    contractDate: "تاريخ التعاقد",
    contractEvent: "حدث التعاقد",
    contractIDProductIDAndFileAreNeeded: "يلزم توفير مُعرِّف التعاقد ومُعرّف المنتج والملف.",
    contractInformation: "معلومات التعاقد",
    contractNumber: "رقم التعاقد",
    contractOrganization: "تعاقد المؤسسة",
    contractOrganizationInformation: "معلومات تعاقد المؤسسة",
    contractOrganizations: "تعاقد المؤسسات",
    contracts: "التعاقدات",
    correct: "صحيح",
    country: "الدولة",
    countryIsRequired: "الدولة مطلوبة",
    course: "الدورة الدراسية",
    courseAbbreviated: "ج",
    courseDescription: "وصف الدورة الدراسية",
    courseDetails: "تفاصيل الدورة الدراسية",
    courseEucUnitCourseSortOrder: "الدورة الدراسية {{eucUnitCourseSortOrder}}",
    courseId: "مُعرّف الدورة الدراسية",
    courseIdIsAutomaticallyGenerated:
        "يتم إنشاء مُعرِّف الدورة الدراسية تلقائيًا وهو غير قابل للتعديل.",
    courseIsNowActive: "الدورة الدراسية نشطة الآن.",
    courseIsNowArchived: "تمت أرشفة الدورة الدراسية الآن.",
    courseIsNowDeactivated: "تم إلغاء تنشيط الدورة الدراسية.",
    courseMaterials: "مواد الدورة الدراسية",
    courseMustHaveANameAndType: "يجب أن يكون للدورة الدراسية اسم ونوع.",
    courseName: "اسم الدورة الدراسية",
    courseOrSeries: "دورة دراسية أو سلسلة",
    courseProductReturnedAnUnexpectedResult: "أعاد منتج الدورة الدراسية نتيجة غير متوقعة.",
    courseResources: "موارد الدورة الدراسية",
    courses: "الدورات الدراسية",
    courseTopicSelection: "اختيار موضوع الدورة الدراسية",
    courseWasUndefined: "الدورة الدراسية كانت غير محددة.",
    createAContract: "إنشاء تعاقد",
    createACourse: "إنشاء دورة دراسية",
    createANewEvent: "إنشاء حدث جديد",
    createANewProduct: "إنشاء منتج جديد",
    createANewVersion: "إنشاء إصدار جديد",
    createCourse: "إنشاء الدورة الدراسية",
    createdondatetimeByThisCreatedByGetFirstAndLastName:
        "{{thisCreatedByGetFirstAndLastName}} بواسطة {{createdOnDateTime}} ",
    createEvent: "انشاء حدث",
    createNewVersion: "إنشاء الإصدار الجديد",
    createProduct: "إنشاء المنتج",
    createScormPackageFailed: "فشل إنشاء حزمة SCORM.",
    createUnit: "إنشاء وحدة",
    createVersion: "إنشاء الإصدار",
    createYourFirstUnitToStartOrganizingCourses: "أنشئ وحدتك الأولى لبدء تنظيم الدورات الدراسية.",
    creatingAnEnrollmentFailed: "فشل إنشاء التسجيل.",
    creditHour: "ساعة معتمدة",
    creditHours: "ساعات معتمدة",
    currentAndUpcomingTrainings: "التدريبات الحالية والقادمة",
    currentAnnouncements: "الإعلانات الحالية",
    currentRequiredTrainings: "التدريبات المطلوبة الحالية",
    currentStatus: "الحالة الحالية",
    currentTrainings: "التدريبات الحالية",
    customizedContact: "الاتصال المخصص",
    customizeForThisEvent: "تخصيص لهذا الحدث",
    dashboard: "لوحة المعلومات",
    date: "التاريخ",
    dateCreated: "تاريخ الإنشاء",
    dateOfCompletion: "تاريخ الإكمال",
    dateRan: "نطاق التاريخ",
    dateRange: "نطاق التاريخ",
    dateXXXXXXXX: "التاريخ: XX/XX/XXXX",
    day: "اليوم",
    dayName: "اسم اليوم",
    deactivateCourse: "إلغاء تنشيط دورة دراسية",
    deactivateProduct: "إلغاء تنشيط منتج",
    deactivateTheCourse: "إلغاء تنشيط الدورة الدراسية",
    deactivateTheProduct: "إلغاء تنشيط المنتج",
    deactivatingTheProductFailed: "فشلت عملية إلغاء تنشيط المنتج.",
    default: "افتراضي",
    defaultContactInformation: "معلومات الاتصال الافتراضية",
    defaultEventContactInfo: "معلومات جهة الاتصال الافتراضية الخاصة بالحدث",
    defaultEventContactInformation: "معلومات جهة الاتصال الافتراضية الخاصة بالحدث",
    defaultEventInformation: "معلومات الحدث الافتراضية",
    defaultEventLocation: "الموقع الافتراضي للحدث",
    defaultEventTimezone: "المنطقة الزمنية الافتراضية للحدث",
    delaware: "ولاية ديلاوير",
    delete: "حذف",
    deleteAssessment: "حذف التقييم",
    deleteEvent: "حذف الحدث",
    deleteSession: "حذف الجلسة",
    describeYourChanges: "وصف التغييرات",
    description: "الوصف",
    descriptionColumn: "عمود الوصف",
    deselect: "إلغاء التحديد",
    detailedBreakdown: "بيان تفصيلي",
    displayType: "نوع العرض",
    domestic: "محلي",
    done: "تم",
    doubleDash: "--",
    downloadButtonForName: "زر التنزيل من أجل {{name}}",
    downloadButtonForPropsContentItemName: "زر التنزيل من أجل {{propsContentItemName}}",
    downloadCertificate: "تنزيل الشهادة",
    downloadCSVImportSummaryFile: "تنزيل ملف ملخص الاستيراد بتنسيق CSV",
    downloadLinkForCSVImportSummaryFile: "رابط التنزيل لملف ملخص الاستيراد بتنسيق CSV",
    downloadResume: "تنزيل السيرة الذاتية",
    downloadsForAdministratorAndInstructor: "التنزيلات للمسؤول والمعلم",
    downloadsForParticipants: "التنزيلات للمشاركين",
    doYouWantToAssociateABadge: "هل تريد ربط شارة بهذا المنتج؟",
    draft: "مسودة",
    duration: "المدة",
    duringThisTimePeriod: "خلال هذه الفترة الزمنية..",
    edit: "تعديل",
    editAnInstructorLedTrainingProduct: "تعديل منتج التدريب تحت إشراف المعلم",
    editAnnouncement: "تعديل إعلان",
    editAnOnlineLearningProduct: "تعديل منتج التعلّم عبر الإنترنت",
    editAttempt: "تعديل محاولة",
    editCEUs: "تعديل وحدات التعليم المستمر (CEU)",
    editContract: "تعديل التعاقد",
    editContractOrganization: "تعديل مؤسسة التعاقد",
    editCourse: "تعديل الدورة الدراسية",
    editCourseDetails: "تعديل تفاصيل الدورة الدراسية",
    editDay: "تعديل اليوم",
    editEnProvider: "تعديل موفر الدورة الدراسية باللغة الإنجليزية‬",
    editEvent: "تعديل الحدث",
    editingAnAttemptMayResultInAnUpdateToTheLearnersProgress:
        "قد يؤدي تعديل المحاولة إلى تحديث تقدم المتعلم.",
    editingMode: "وضع التعديل",
    editLearner: "تعديل بيانات المتعلم",
    editName: "تعديل الاسم",
    editProduct: "تعديل المنتج",
    editUnit: "تعديل الوحدة",
    editUser: "تعديل المستخدم",
    editVersion: "تعديل الإصدار",
    educationNetwork: "شبكة التعليم",
    educationNetworkEvent: "حدث شبكة التعليم",
    educationNetworkHub: "مركز شبكة التعليم",
    educationNetworkResources: "موارد شبكة التعليم",
    electrical: "الكهربائي",
    email: "البريد الإلكتروني",
    emailAddress: "عنوان البريد الإلكتروني",
    emailIsInAValidFormatExampleEmaildomain: "البريد الإلكتروني بتنسيق صالح (مثال: email@domain).",
    emergencyResponse: "الاستجابة لحالات الطوارئ",
    emptyDate: "00/00/0000",
    emptyString: "",
    emulateUser: "محاكاة المستخدم",
    emulationNotAvailableForAdministrators: "المحاكاة غير متاحة للمسؤولين.",
    emulationNotAvailableForAdmins: "المحاكاة غير متاحة للمسؤولين.",
    endingEmulationSession: "إنهاء جلسة المحاكاة",
    endSession: "إنهاء الجلسة",
    endTime: "وقت الإنهاء",
    english: "الإنجليزية",
    enInstructor: "معلم باللغة الإنجليزية",
    enProductCatalog: "كتالوج المنتجات باللغة الإنجليزية",
    enProvider: "موفر الدورة الدراسية باللغة الإنجليزية",
    enProviderEvent: "حدث موفر الدورة الدراسية باللغة الإنجليزية",
    enProviderInformation: "معلومات موفر الدورة الدراسية باللغة الإنجليزية",
    enProviderName: "اسم موفر الدورة الدراسية باللغة الإنجليزية",
    enProviders: "موفرو الدورات الدراسية باللغة الإنجليزية",
    enProvidersOrgName: "اسم مؤسسة موفر الدورة الدراسية باللغة الإنجليزية",
    enResource: "موارد الدورة الدراسية باللغة الإنجليزية",
    instructorAssessment: "تقييم المعلم",
    enrolled: "تم التسجيل",
    enrolledAndCompleted: "تم التسجيل والانتهاء",
    enrolledButNotStartedEnrolledAndInProgress:
        "تم التسجيل ولكن لم يتم البدء/تم التسجيل وقيد التقدم",
    enrolledFirstName: "الاسم الأول للمُسجَّل",
    enrolledLastName: "الاسم الأخير للمُسجَّل",
    enrolledName: "اسم المُسجَّل",
    enrollment: "التسجيل",
    enrollmentCSVRequirements: "متطلبات التسجيل بتنسيق CSV:",
    enrollmentHistory: "سجل التسجيل",
    enrollmentImportStatus: "حالة استيراد التسجيل:",
    enrollmentImportSummaryColon: "ملخص استيراد التسجيل:",
    enrollmentLimit: "حد التسجيل",
    enrollmentLimitCannotBeLessThanTheCurrentEnrollmentCount:
        "لا يمكن أن يكون حد التسجيل أقل من عدد التسجيل الحالي.",
    enrollmentReport: "تقرير التسجيل",
    enrollmentsForAEvent: "التسجيلات للحدث",
    enrollmentsForProductName: "عمليات التسجيل في {{productName}}",
    enrollmentsForProductNameEventName: "عمليات التسجيل في {{productName}} - {{eventName}}",
    enrollmentStatusWillAppearAsInvitePending:
        'ستظهر حالة التسجيل كـ "دعوة معلقة" إذا لم يكن لدى المتعلم ملف شخصي مكتمل لدى NFPA. بمجرد إضافة المتعلم إلى التسجيل، سيتلقى المتعلم رسالة بريد إلكتروني تطالبه بإنهاء إعداد الملف الشخصي في NFPA. بمجرد الانتهاء، سيظهر اسم المتعلم ويحل محل "دعوة معلقة".',
    enrollmentSuccessful: "تم إجراء التسجيل بنجاح.",
    enrollNewTraining: "التسجيل في تدريب جديد",
    enrollTraining: "تسجيل التدريب",
    enterAdditionalDetails: "إدخال تفاصيل إضافية",
    enterAdditionalRequirements: "إدخال متطلبات إضافية",
    enterAddressLine1: "إدخال سطر العنوان 1",
    enterAddressLine2: "إدخال سطر العنوان 2",
    enterADescription: "إدخال وصف",
    enterAnnouncementBody: "إدخال نص الإعلان",
    enterAnnouncementTitle: "إدخال عنوان الإعلان",
    enterChangeLog: "إدخال سجل التغيير",
    enterCitySlashTown: "إدخال المدينة/البلدة",
    enterContentDescription: "إدخال وصف المحتوى",
    enterContentName: "إدخال اسم المحتوى",
    enterContractNumber: "إدخال رقم التعاقد",
    enterCourseDescription: "إدخال وصف الدورة الدراسية",
    enterCourseName: "إدخال اسم الدورة الدراسية",
    enterCreditHours: "إدخال الساعات المعتمدة",
    enterDayName: "إدخال اسم اليوم",
    enterDescribeYourChanges: "إدخال وصف التغييرات",
    enterEmail: "إدخال البريد الإلكتروني",
    enterEnrollmentLimit: "إدخال حد التسجيل",
    enterEventEmailAddress: "إدخال عنوان البريد الإلكتروني للحدث",
    enterEventName: "إدخال اسم الحدث",
    enterEventPhoneNumber: "إدخال رقم الهاتف للحدث",
    enterFirstName: "إدخال الاسم الأول",
    enterLastName: "إدخال الاسم الأخير",
    enterLocationName: "إدخال اسم الموقع",
    enterMaximumRegistrationLimit: "إدخال الحد الأقصى للتسجيل",
    enterMeetingUrl: "إدخال عنوان URL للاجتماع",
    enterName: "إدخال الاسم",
    enterNfpaContactEmail: "إدخال البريد الإلكتروني لجهة اتصال NFPA",
    enterNfpaContactName: "إدخال اسم جهة اتصال NFPA",
    enterNfpaContactPhone: "إدخال هاتف جهة اتصال NFPA",
    enterNfpaID: "إدخال مُعرِّف NFPA",
    enterOrganizationName: "إدخال اسم المؤسسة",
    enterPackageName: "إدخال اسم الحزمة",
    enterProductDescription: "إدخال وصف المنتج",
    enterProductName: "إدخال اسم المنتج",
    enterRegistrationURL: "إدخال عنوان URL للتسجيل",
    enterResponse: "إدخال الرد",
    enterUnitDescription: "إدخال وصف الوحدة",
    enterUnitName: "إدخال اسم الوحدة",
    enterYourEmailAddress: "إدخال عنوان بريدك الإلكتروني…",
    enterYourFeedback: "إدخال ملاحظاتك",
    enterYourFirstName: "إدخال اسمك الأول…",
    enterYourLastName: "إدخال اسمك الأخير…",
    enterYourPhoneNumber: "إدخال رقم هاتفك…",
    enterZipCodePostalCode: "إدخال ‏‫الرمز البريدي/رمز المنطقة‬",
    enterZipcodeSlashPostalCode: "إدخال ‏‫الرمز البريدي/رمز المنطقة‬",
    error: "خطأ",
    errorDeletingFile: "حدث خطأ أثناء حذف الملف.",
    errorFetchingArchivedAnnouncements: "حدث خطأ أثناء جلب الإعلانات المؤرشفة.",
    errorMessageColon: "رسالة خطأ:",
    errorWhenFetchingUnits: "حدث خطأ أثناء جلب الوحدات.",
    evaluation: "التقييم",
    evaluationReport: "تقرير التقييم",
    evaluationSubmittedSuccessfully: "تم إرسال التقييم بنجاح.",
    event: "الحدث",
    eventCalendar: "تقويم الأحداث",
    eventCEUS: "وحدات التعليم المستمر (CEU) للحدث",
    eventContact: "جهة اتصال الحدث",
    eventContactInformation: "معلومات جهة الاتصال المخصصة للحدث",
    eventdaygetcredithoursdescriptionEventDayGetCEUsCEUs:
        "وحدات التعليم المستمر لـ {{eventDayGetCreditHoursDescription}} | {{eventDayGetCEUs}}",
    eventDayName: "اسم يوم الحدث",
    eventDaysCannotOccurOnTheSameDay: "لا يمكن أن تكون أيام الأحداث في اليوم ذاته.",
    eventDeletedSuccessfully: "تم حذف الحدث بنجاح.",
    eventDetails: "تفاصيل الحدث",
    eventEnrollments: "تسجيلات الحدث",
    eventID: "معرف الحدث",
    eventInformation: "معلومات الحدث",
    eventName: "اسم الحدث",
    eventPhoneNumber: "رقم الهاتف للحدث",
    events: "الأحداث",
    eventType: "نوع الحدث",
    exceededEnrollmentLimit: "تم تجاوز حد التسجيل",
    exceptionGranted: "تم منح الاستثناء",
    exceptionShouldOnlyBeGrantedBasedOnAValidExceptionReasonORAfterSpeakingWithTheRequestorToFullyUnderstandTheNeedForExtraHours:
        "يجب ألا يتم منح الاستثناء إلا بناءً على سبب استثناء صالح أو بعد التحدث مع مقدم الطلب لفهم الحاجة إلى ساعات إضافية بشكل كامل. ",
    expiration: "الانتهاء",
    expirationDate: "تاريخ الانتهاء",
    expirationExtendedSuccessfully: "تم تمديد تاريخ الانتهاء بنجاح.",
    expirationExtensionFailed: "فشل تمديد تاريخ الانتهاء.",
    expired: "منتهٍ",
    failed: "فشل",
    failedToActivateCourse: "فشل تنشيط الدورة الدراسية.",
    failedToActivateProduct: "فشلت عملية تنشيط المنتج.",
    failedToArchiveAnnouncement: "فشلت أرشفة الإعلان.",
    failedToArchiveCourse: "فشلت أرشفة الدورة الدراسية.",
    failedToArchiveProduct: "فشلت أرشفة المنتج.",
    failedToCreateANewEnrollment: "فشل إنشاء تسجيل جديد.",
    failedToDeactivateCourse: "فشل إلغاء تنشيط الدورة الدراسية.",
    failedToDeactivateProduct: "فشل إلغاء تنشيط المنتج.",
    failedToEnroll: "فشل التسجيل",
    failedToGetProductsCount: "فشل حصر عدد المنتجات.",
    failedToLinkSCORMPackageToProduct: "فشلت عملية ربط حزمة SCORM بالمنتج.",
    failedToRetrieveProductScormPackages: "فشل استرداد حزم scorm للمنتج.",
    failedToSaveAttempt: "فشل حفظ المحاولة.",
    failedToUpdateUser: "فشل تحديث المستخدم",
    feedback: "الملاحظات",
    fieldsChanged: "تم تغيير الحقول",
    fileMayContainAHeaderRow: "قد يحتوي الملف على صف عناوين.",
    fileMustBeACSV: "يجب أن يكون الملف بتنسيق CSV.",
    fileName: "اسم الملف",
    fileNameColumn: "عمود اسم الملف",
    fileUpload: "تحميل الملف",
    filterByEventType: "التصفية حسب نوع الحدث",
    filterByLanguage: "التصفية حسب اللغة",
    filterByOrganization: "التصفية حسب المؤسسة",
    filterByProduct: "التصفية حسب المنتج",
    filterByProvider: "التصفية حسب الموفر",
    filterByRole: "التصفية حسب الدور",
    filterBySalesType: "التصفية حسب نوع المبيعات",
    filterByStatus: "التصفية حسب الحالة",
    filterByTeachingMethods: "التصفية حسب طرق التدريس",
    filterByTopic: "التصفية حسب الموضوع",
    filterByTravelPreferences: "التصفية حسب تفضيلات السفر",
    filterResults: "تصفية النتائج",
    firstName: "الاسم الأول",
    firstNameIsRequired: "الاسم الأول مطلوب.",
    florida: "فلوريدا",
    forAnyQuestionsPleaseContactNFPASupport:
        "إذا كانت لديك أي أسئلة، يرجى الاتصال بقسم الدعم في NFPA.",
    forQuestionsAboutTheEventContact: "إذا كانت لديك أسئلة حول الحدث، فتواصل مع:",
    forQuestionsAboutTheLMSContact: "إذا كانت لديك أسئلة حول نظام إدارة التعلم (LMS)، فتواصل مع:",
    forQuestionsAboutTheTrainingContact: "إذا كانت لديك أسئلة حول التدريب، فتواصل مع:",
    frequentlyAskedQuestions: "الأسئلة الشائعة",
    fridayAbbreviated: "الجمعة",
    generalInformation: "معلومات عامة",
    generating: "إنشاء",
    georgia: "جورجيا",
    getStarted: "البدء",
    getStartedLearningPathButton: "زر بدء مسار التعلم",
    getStartedSeriesButton: "زر بدء السلسلة",
    goToCredly: "الانتقال إلى Credly",
    goToEvent: "الانتقال إلى الحدث",
    goToEvents: "الانتقال إلى الأحداث",
    goToReports: "الانتقال إلى التقارير",
    goToSupport: "الانتقال إلى الدعم",
    grantException: "منح الاستثناء",
    haveFeedback: "هل لديك ملاحظات؟",
    haveYouConfirmedThisInstructorsAvailability: "هل تأكدت من توفر هذا المعلم؟",
    hawaii: "هاواي",
    hideContactInformation: "إخفاء معلومات جهة الاتصال",
    highScore: "نتيجة عالية",
    host: "مضيف",
    hostTimeZone: "المنطقة الزمنية للمضيف",
    hours: "الساعات",
    howItWorks: "كيفية العمل",
    howLongIsTheCertificateValidForInYears: "كم مدة صلاحية الشهادة (بالسنوات)؟",
    iAmAbleToTravelDomestically: "يمكنني السفر محليًا",
    iAmAbleToTravelInternationally: "يمكنني السفر دوليًا",
    iAmAvailableToTeachInPerson: "أنا متاح للتدريس بشكل شخصي",
    iAmAvailableToTeachVirtually: "أنا متاح للتدريس بشكل افتراضي",
    iConfirmTheExceptionReasonIsValidAndorThatIHaveSpokenWithTheRequestorAndApproveThisException:
        "أؤكد أن سبب الاستثناء صالح و/أو أنني تحدثت مع مقدم الطلب وأوافق على هذا الاستثناء.",
    id: "المُعرِّف",
    idaho: "أيداهو",
    ids: "المعرفات",
    ifALearnerRequiresAdditionalTimeToCompleteTheTrainingTheExpirationDateMayBeExtended:
        "إذا احتاج المتعلم إلى وقت إضافي لإكمال التدريب، فقد يتم تمديد تاريخ الانتهاء.",
    ifYouBelieveThisIsAnErrorPleaseContact: "إذا كنت تعتقد أن هذا خطأ، يرجى الاتصال",
    iHaveConfirmedThisInstructorsAvailability: "لقد تأكدت من توفر هذا المعلم *",
    illinois: "إلينوي",
    importCSV: "استيراد ملف CSV",
    importEnrollmentCSV: "استيراد ملف التسجيل بتنسيق CSV",
    importStatus: "حالة الاستيراد",
    inactive: "غير نشط",
    includeArchived: "تضمين المؤرشف",
    includedInThisUnit: "مُتضمَّن في هذه الوحدة",
    incomplete: "غير مكتمل",
    incorrect: "غير صحيح",
    indiana: "إنديانا",
    inHyphenProgress: "قيد التقدم",
    inOrderToArchiveThisCourseYouNeedTo: "لتتمكن من أرشفة هذه الدورة الدراسية، يجب عليك:",
    inOrderToArchiveThisProductYouNeedTo: "لتتمكن من أرشفة هذا المنتج، يجب عليك:",
    inOrderToDeactivateThisCourseYouNeedTo: "لتتمكن من إلغاء تنشيط هذه الدورة الدراسية، يجب عليك:",
    inPerson: "بشكل شخصي",
    inPersonEvent: "حدث بشكل شخصي",
    inPersonTraining: "تدريب بشكل شخصي",
    inProgress: "قيد التقدم",
    inprogressLearners: "المتعلمون قيد التقدم",
    inProgressLearners: "المتعلمون قيد التقدم",
    inputTheEventClassNameTheClassNameWillNotBeSeen:
        "أدخل اسم فصل الحدث. لن يرى المتعلمون اسم الفصل وهو يستخدَم للأغراض التنظيمية الداخلية فقط.",
    instructor: "المعلم",
    instructorAverageRating: "متوسط ​​تقييم المعلم",
    instructorInfo: "معلومات المعلم",
    instructorInformation: "معلومات المعلم",
    instructorLed: "تحت إشراف المعلم",
    instructorLedEventsContracts: "تعاقدات الأحداث تحت إشراف المعلم",
    instructorLedTraining: "تدريب تحت إشراف المعلم",
    instructorLedTrainingEvent: "حدث التدريب تحت إشراف المعلم",
    instructorName: "اسم المعلم",
    instructorProfile: "الملف الشخصي للمعلم",
    instructorRoleCannotBeRemovedDueToIncompleteEventAssignments:
        "لا يمكن إزالة دور المعلم بسبب عدم اكتمال تعيين (تعيينات) الأحداث.",
    international: "دولي",
    inThisTimePeriod: "في هذه الفترة الزمنية…",
    inverted: "معكوس",
    invitePending: "دعوة معلقة",
    iowa: "آيوا",
    issueChangingPreferredLanguage: "حدثت مشكلة أثناء تغيير لغتك المفضلة.",
    iWantToRunATrainingStatisticsReportFor: "أرغب في تشغيل تقرير إحصائيات التدريب لـ...",
    iWantToRunAn: "أريد إنشاء...",
    iWantToRunAnAllTrainingReportBasedOn: "أريد إنشاء تقرير عن التدريب بأكمله استنادًا إلى...",
    iWantToRunAnEvaluationReportBasedOn: "أريد إنشاء تقرير تقييم بناءً على...",
    iWantToRunAReport: "أريد إنشاء تقرير حول...",
    iWantToRunATrainingMaterialsOutlineBasedOn: "أريد إنشاء مخطط مواد التدريب بناءً على...",
    iWantToRunATranscriptReportFor: "أريد إنشاء تقرير بشأن السجل الدراسي من أجل...",
    kansas: "كانساس",
    kentucky: "كنتاكي",
    language: "اللغة",
    languageBarrierIsAnExampleWhereTrainingMayExtend:
        "يعتبر حاجز اللغة مثالًا حيث قد يتجاوز التدريب ساعات التواصل الدراسي الموصى بها (وحدات CEU)، (أي أن لغة التدريب والمواد ليست اللغة الأساسية للمشاركين).",
    languageBarriers: "حواجز اللغة",
    languageSelect: "حدد اللغة التي ترغب في استخدامها في نظام إدارة التعلم (LMS).",
    languageSelection: "اختيار اللغة",
    languageUpdatedSuccessfully: "تم تحديث اللغة بنجاح.",
    lastChange: "التغيير الأخير",
    lastChanged: "آخر تغيير",
    lastName: "الاسم الأخير",
    lastNameIsRequired: "الاسم الأخير مطلوب.",
    launchAssessment: "بدء التقييم",
    launchCourse: "بدء الدورة الدراسية",
    launchTraining: "بدء التدريب",
    learner: "المتعلم",
    learnerAssessment: "تقييم المتعلم",
    learnerBreakdown: "البيان التفصيلي للمتعلم",
    learnerName: "اسم المتعلم",
    learnerRegisteredSuccessfully: "تم تسجيل المتعلم بنجاح.",
    learnersInThisGroupMatchedRecordsForLearnersAlreadyOnTheEnrollmentListForTheTraining:
        "تطابقت بيانات المتعلمين في هذه المجموعة مع سجلات متعلمين موجودين بالفعل في قائمة التسجيل للتدريب.",
    learnersInThisGroupWereNotAddedToTheEnrollmentListDueToExceedingTheRegistrationLimitForThisTraining:
        "لم تتم إضافة المتعلمين في هذه المجموعة إلى قائمة التسجيل بسبب تجاوز حد التسجيل لهذا التدريب.",
    learnersInThisGroupWereNotAddedToTheEnrollmentListForThisTrainingDueToAPotentialDataError:
        "لم تتم إضافة المتعلمين في هذه المجموعة إلى قائمة التسجيل لهذا التدريب بسبب خطأ محتمل في البيانات.",
    learnersInThisGroupWereSuccessfullyAddedToTheEnrollmentListForTheTraining:
        "تمت إضافة المتعلمين في هذه المجموعة بنجاح إلى قائمة التسجيل للتدريب.",
    learnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersion:
        "سيحتفظ المتعلمون الذين أكملوا الإصدار السابق بهذا الإصدار.",
    learnerTranscriptReport: "تقرير ‏‫نسخة المتعلم المكتوبة‬",
    learning: "التعلّم",
    learningPath: "مسار التعلّم",
    letUsKnowHowWeCanHelp: "أخبرنا كيف يمكننا المساعدة.",
    licensee: "صاحب الترخيص",
    linkingCourseToAUnitHasFailed: "فشل ربط الدورة الدراسية بالوحدة.",
    linkingSCORMPackageToProductFailed: "فشل ربط حزمة SCORM بالمنتج",
    listAndLast: "{{list}}، و{{last}}",
    live: "‏‫البث المباشر‬",
    liveVirtual: "البث الافتراضي المباشر",
    liveVirtualEvent: "الحدث الافتراضي المباشر",
    liveVirtualTraining: "التدريب الافتراضي المباشر",
    lmsID: "مُعرِّف LMS",
    lmsId: "مُعرِّف LMS",
    loadingEmulationSession: "تحميل جلسة المحاكاة",
    location: "الموقع",
    locationName: "اسم الموقع",
    locationNameIsRequired: "اسم الموقع مطلوب",
    logIn: "تسجيل الدخول",
    logOut: "تسجيل الخروج",
    louisiana: "لويزيانا‬",
    mainContent: "المحتوى الرئيسي",
    maine: "ماين",
    manageYourProfile: "إدارة ملفك الشخصي على www.nfpa.org",
    markComplete: "وضع علامة اكتمال",
    markingNotificationAsReadFailed: "فشل وضع علامة على الإشعار كمقروء.",
    markPassed: "وضع علامة ‏‫تم الاجتياز‬",
    maryland: "ماريلاند",
    massachusetts: "ماساتشوستس",
    maximumRegistration: "أقصى حد للتسجيل",
    maximumRegistrationLimit: "الحد الأقصى للتسجيل",
    maxScore: "أعلى درجة",
    meetingUrl: "عنوان URL للاجتماع",
    memberOf: "عضو في",
    menu: "القائمة",
    menuColumn: "عمود القائمة",
    messageByUpdatedByGetFirstAndLastName: "{{updatedByGetFirstAndLastName}} بواسطة {{message}}",
    michigan: "ميشيغان",
    minnesota: "مينيسوتا",
    mississippi: "مسيسيبي",
    missouri: "ميسوري",
    mondayAbbreviated: "الإثنين",
    montana: "مونتانا",
    moveAssessmentProductAssessmentScormPackageNameDown:
        "نقل التقييم {{productAssessmentScormPackageName}} إلى الأسفل",
    moveAssessmentProductAssessmentScormPackageNameUp:
        "نقل التقييم {{productAssessmentScormPackageName}} إلى الأعلى",
    moveContentCourseContentContentNameDown: "نقل المحتوى {{courseContentContentName}} إلى الأسفل",
    moveContentCourseContentContentNameUp: "نقل المحتوى {{courseContentContentName}} إلى الأعلى",
    moveContentPcContentNameDown: "نقل المحتوى {{pcContentName}} إلى الأسفل",
    moveContentPcContentNameUp: "نقل المحتوى {{pcContentName}} إلى الأعلى",
    moveContentProductContentContentNameDown:
        "نقل المحتوى {{productContentContentName}} إلى الأسفل",
    moveContentProductContentContentNameUp: "نقل المحتوى {{productContentContentName}} إلى الأعلى",
    moveCourseUnitCourseCourseNameDown: "نقل الدورة الدراسية {{unitCourseCourseName}} إلى الأسفل",
    moveCourseUnitCourseCourseNameUp: "نقل الدورة الدراسية {{unitCourseCourseName}} إلى الأعلى",
    moveUnitProductUnitNameDown: "نقل الوحدة {{productUnitName}} إلى الأسفل",
    moveUnitProductUnitNameUp: "نقل الوحدة {{productUnitName}} إلى الأعلى",
    myLearning: "تعلّمي",
    myProfile: "ملفي الشخصي",
    myTeachingAssignments: "مهامي‬ التعليمية",
    name: "الاسم",
    nameColumn: "عمود الاسم",
    nationalFireProtectionAssociation: "الجمعية الوطنية للحماية من الحرائق",
    nationalFireProtectionAssociationCatalog: "كتالوج الجمعية الوطنية للحماية من الحرائق",
    nebraska: "نبراسكا",
    needFurtherAssistance: "هل تحتاج إلى مزيد من المساعدة؟",
    needToAddOrRemoveTeamMembers: "هل تحتاج إلى إضافة أو إزالة أعضاء الفريق؟",
    neutral: "محايد",
    nevada: "نيفادا",
    new: "جديد",
    newAnnouncement: "إعلان جديد",
    newContractOrganization: "مؤسسة التعاقد الجديد",
    newCourse: "دورة دراسية جديدة",
    newENProvider: "الموفر الجديد للدورة الدراسية باللغة الإنجليزية‬",
    newEnrollments: "التسجيلات الجديدة",
    newExpirationDate: "تاريخ الانتهاء الجديد",
    newHampshire: "نيوهامبشير",
    newJersey: "نيو جيرسي",
    newMexico: "نيو مكسيكو",
    newOnlineLearningContract: "تعاقد جديد للتعلّم عبر الإنترنت",
    newUser: "مستخدم جديد",
    newVersionVersionNumber: "الإصدار الجديد {{versionNumber}}",
    newYork: "نيويورك",
    nextMonth: "الشهر القادم",
    nextPage: "الصفحة التالية",
    nfpa: "الجمعية الوطنية للحماية من الحرائق (NFPA)",
    nfpaAdmin: "مسؤول NFPA",
    nfpaAdminEmail: "البريد الإلكتروني لمسؤول NFPA",
    nfpaAdminName: "اسم مسؤول NFPA",
    nfpaAdminPhone: "هاتف مسؤول NFPA",
    nfpaCatalog: "كتالوج NFPA",
    nfpaCatalogEvent: "حدث كتالوج NFPA",
    nfpaContact: "جهة اتصال NFPA",
    nfpaContactEmail: "البريد الإلكتروني لجهة اتصال NFPA",
    nfpaContactName: "اسم جهة اتصال NFPA",
    nfpaContactPhone: "هاتف جهة اتصال NFPA",
    nfpaCustomerSupport: "دعم عملاء NFPA",
    nfpaEvent: "حدث NFPA",
    nfpaId: "مُعرِّف NFPA",
    nfpaIsCommittedToProvidingYouWithHighqualityContentToHelpMeetYourLearningNeedsWeAppreciateYourFeedbackYourAnswersAndCommentsWillProvideValuableInputAsWeContinueToEnhanceOurOfferings:
        "تحرص NFPA على تزويدك بمحتوى عالي الجودة لتساهم في تلبية احتياجاتك التعليمية. نحن نقدر ملاحظاتك. ستوفر إجاباتك وتعليقاتك معلومات قيّمة تدعم عملية التحسين المستمر لعروضنا.",
    nfpaOperations: "عمليات NFPA",
    nfpaSupport: "دعم NFPA",
    no: "لا يوجد",
    noAssignmentsForThisTraining: "لا توجد مهام لهذا التدريب.",
    noAttemptDataForTheSelectedAttempt: "لا توجد بيانات تتعلق بالمحاولة المحددة.",
    noChangesWereMadeToThisSection: "لم يتم إجراء أي تغييرات على هذا القسم",
    noCoursesAdded: "لم تتم إضافة أي دورات دراسية.",
    noEnrollmentLimit: "لا يوجد حد للتسجيل",
    noEvaluationAdded: "لم تتم إضافة أي تقييم",
    noHostAssigned: "لم يتم تعيين مضيف",
    noInstructorAssigned: "لم يتم تعيين معلم",
    noInstructorsCouldBeFoundForThisProduct: "لا يمكن العثور على معلمين لهذا المنتج",
    noKeepEditing: "لا، استمر في التعديل",
    noLimitSetInvoiceWillBeIssuedFollowingTheEvent:
        "لم يتم تعيين حد. سيتم إصدار الفاتورة بعد الحدث.",
    noLocationAdded: "لم تتم إضافة أي موقع",
    noOrganizationSelected: "لم يتم تحديد أي مؤسسة",
    noOrganizationsFound: "لم يتم العثور على مؤسسات.",
    noProductAssigned: "لم يتم تعيين أي منتج",
    noProductSelected: "لم يتم تحديد أي منتج",
    noProviderSelected: "لم يتم تحديد موفر",
    noProvidersFound: "لم يتم العثور على موفرين.",
    noResultsFoundPleaseTryAgain: "لا توجد نتائج. حاول مرة أخرى.",
    northCarolina: "كارولاينا الشمالية",
    northDakota: "داكوتا الشمالية",
    noScheduleCreated: "لم يتم إنشاء جدول زمني",
    notApplicableShort: "لا ينطبق",
    notAValidCultureCode: "ليست قواعد ثقافية مقبولة.",
    noteAnInvitePendingLearnerCountsTowardsTheTotalEnrollmentNumberForTheTraining:
        'ملاحظة: يتم احتساب المتعلم الذي يمتلك "دعوة معلقة" ضمن العدد الإجمالي للمسجلين في التدريب.',
    noteBreakTimeIsNOTToBeIncludedInAdjustedHoursCEUs:
        "ملاحظة: لا يتم تضمين وقت الاستراحة في الساعات المعدلة/وحدات التعليم المستمر (CEU).",
    noteTheExpirationHistoryLogWillTrackEachTimeTheExpirationDateIsUpdated:
        "ملاحظة: سيتتبع سجل محفوظات انتهاء الصلاحية كل مرة يتم فيها تحديث تاريخ انتهاء الصلاحية.",
    noteYouDoNotNeedToIncludeTheEventType:
        "ملاحظة: لا تحتاج إلى تضمين نوع الحدث (افتراضي مباشر أو شخصي) في اسم الفصل حيث سيتم ملاحظة ذلك في اختيار نوع الحدث.",
    notFound: "لم يتم العثور عليه",
    noTrainingsFound: "لم يتم العثور على تدريبات.",
    notStarted: "لم يتم البدء",
    noUnitsAdded: "لم تتم إضافة أي وحدات.",
    noZipPackageAdded: "لم تتم إضافة أي حزمة مضغوطة.",
    number: "الرقم",
    ohio: "أوهايو",
    oklahoma: "أوكلاهوما",
    oneVeryUnlikelyOnAScaleOfOneToTen:
        "من المستبعد جدًا‬ الحصول على واحد على مقياس من واحد إلى عشرة",
    online: "عبر الإنترنت‬",
    onlineLearning: "التعلّم عبر الإنترنت",
    onlineLearningContracts: "تعاقدات التعلّم عبر الإنترنت",
    onlineLearningProductEnrollments: "عمليات تسجيل منتجات التعلّم عبر الإنترنت",
    onlineLearningTraining: "التدريب المتعلق بالتعلّم عبر الإنترنت",
    onlyAvailableForNFPAOLLTrainingProducts: "متوفر فقط لمنتجات التدريب NFPA OLL",
    optional: "اختياري",
    order: "طلب",
    oregon: "أوريغون",
    organization: "المؤسسة",
    organizationDefaultEventContact: "جهة اتصال الحدث الافتراضي للمؤسسة",
    organizationName: "اسم المؤسسة",
    organizations: "المؤسسات",
    organizationsNeedToBeAddedBeforeTheyCanBeAssignedToAUser:
        "يجب إضافة المؤسسات قبل أن يتم تعيينها لمستخدم.",
    organizationsNeedToBeAddedBeforeTheyCanBeAssociatedToAUser:
        "يجب إضافة المؤسسات قبل أن يتم ربطها بمستخدم.",
    organizationStatus: "حالة المؤسسة",
    organizedBy: "تم التنظيم بواسطة",
    original: "المواد الأصلية",
    OrSimplyMakeSureYourFileMeetsTheseRequirementsBeforeUploading:
        " أو تأكد ببساطة من أن ملفك يلبي هذه المتطلبات قبل التحميل:",
    orSimplyMakeSureYourFileMeetsTheseRequirementsBeforeUploading:
        "أو تأكد ببساطة من أن ملفك يلبي هذه المتطلبات قبل التحميل:",
    other: "غير ذلك",
    overview: "‏‫نظرة عامة",
    paginationNavigationNext: "التنقل بين الصفحات التالية",
    paginationNavigationPrevious: "التنقل بين الصفحات السابقة",
    participants: "المشاركون",
    participantsRequireAdditionalLearningTimeBasedOnInitialKnowledgeLevel:
        "يحتاج المشاركون إلى وقت تعلّم إضافي بناءً على مستوى المعرفة الأولي.",
    participantSummary: "موجز حول المشتركين",
    passed: "تم الاجتياز",
    password: "كلمة المرور",
    pastAnnouncementsWillDisplayHereOnceTheyHaveBeenArchived:
        "سيتم عرض الإعلانات السابقة هنا بمجرد أرشفتها.",
    pastTrainings: "التدريبات السابقة",
    payloadCannotBeNullOrUndefined: "لا يمكن أن تكون الحمولة فارغة أو غير محددة",
    pending: "قيد الانتظار",
    pennsylvania: "بنسلفانيا",
    phone: "هاتف",
    phoneNumber: "‏‫رقم الهاتف",
    physicalMaterials: "المواد المادية",
    please: "يُرجى",
    pleaseContactTheHostForStartAndEndTimes:
        "يُرجى التواصل مع المضيف لمعرفة أوقات البدء والانتهاء.",
    pleaseEnterAValidEmailAddress: "يُرجى إدخال عنوان بريد إلكتروني صالح.",
    pleaseEnterAValidPhoneNumber: "يُرجى إدخال رقم هاتف صالح",
    pleaseReviewBeforeReplacingYourENProvider:
        "يُرجى المراجعة قبل استبدال موفر الدورة الدراسية باللغة الإنجليزية‬ الخاص بك.",
    pleaseReviewBeforeReplacingYourOrganization: "يُرجى المراجعة قبل استبدال مؤسستك.",
    pleaseReviewBeforeReplacingYourProduct: "يُرجى المراجعة قبل استبدال منتجك.",
    pleaseReviewTheSummaryEvaluationResultsFromParticipantsAsItProvidesKeyInsightsAboutTheirTrainingExperienceResultsIncludeFeedbackConcerningTheInstructorTrainingContentAndTheLearningEnvironment:
        "يُرجى مراجعة نتائج التقييم الموجزة من المشاركين لأنها توفر رؤى أساسية حول تجربتهم التدريبية. وتشمل النتائج الملاحظات المتعلقة بالمعلم، ومحتوى التدريب، وبيئة التعلّم.",
    pleaseSelectAllThatApply: "يُرجى اختيار كل ما ينطبق.",
    pleaseSelectTheDateAndTimesOfTheEventScheduleDONOTIncludeBreaksIncludingLunchInTheStartAndEndTimesAsTheseTimesAreConsideredNoncontactHoursAndAreNotToBeCountedTowardsContinuingEducationCreditsCEUsForExampleAHourOnedayCourseCouldBeSetUpAs:
        "يُرجى تحديد التاريخ والأوقات من الجدول الزمني للحدث. لا تقم بتضمين فترات الراحة (بما في ذلك استراحة الغداء) في أوقات البداية والنهاية حيث تعتبر هذه الأوقات ساعات غير متصلة ولا يتم احتسابها ضمن وحدات التعليم المستمر (CEUs). على سبيل المثال، يمكن إعداد دورة دراسية مدتها 7 ساعات ليوم واحد على النحو التالي:",
    preferredLanguage: "اللغة المفضلة",
    preview: "معاينة",
    previewAssessment: "معاينة التقييم",
    previewEvaluation: "المعاينة الخاصة بالتقييم",
    previousMonth: "الشهر الماضي",
    previousPage: "الصفحة السابقة",
    problemLoadingAssociatedProducts: "حدثت مشكلة أثناء تحميل قائمة المنتجات المرتبطة.",
    problemLoadingAssociatedProductsList: "حدثت مشكلة أثناء تحميل قائمة المنتجات المرتبطة.",
    problemLoadingOrganizations: "حدثت مشكلة أثناء تحميل قائمة المؤسسات.",
    problemLoadingProducts: "حدثت مشكلة أثناء تحميل المنتجات.",
    problemLoadingProviders: "حدثت مشكلة أثناء تحميل قائمة الموفرين.",
    processing: "معالجة",
    product: "المنتج‬",
    productAudience: "الجمهور الذي يستهدفه المنتج",
    productDescription: "وصف المنتج",
    productDetails: "تفاصيل المنتج",
    productID: "مُعرِّف المنتج",
    productId: "مُعرِّف المنتج",
    productIDAndFileAreNeeded: "يلزم توفير مُعرِّف المنتج والملف.",
    productIdIsAutomaticallyGeneratedAndNotEditable:
        "يتم إنشاء مُعرِّف المنتج تلقائيًا وهو غير قابل للتعديل.",
    productInfo: "معلومات المنتج",
    productInformation: "معلومات المنتج",
    productIsNowActive: "المنتج نشط الآن.",
    productIsNowArchived: "تمت أرشفة المنتج الآن.",
    productIsNowDeactivated: "تم إلغاء تنشيط المنتج الآن.",
    productName: "اسم المنتج",
    products: "المنتجات",
    productType: "نوع المنتج",
    productTypeSelection: "اختيار نوع المنتج",
    profile: "الملف الشخصي",
    profileUpdateFailed: "فشل تحديث الملف الشخصي.",
    profileUpdateFailedAddingLanguage: "فشل تحديث الملف الشخصي في إضافة اللغة.",
    profileUpdateFailedAddingTeachingMethod: "فشل تحديث الملف الشخصي في إضافة طريقة التدريس.",
    profileUpdateFailedAddingTravelMethod: "فشل تحديث الملف الشخصي في إضافة طريقة الانتقال‬.",
    profileUpdateFailedRemovingLanguage: "فشل تحديث الملف الشخصي في إزالة اللغة.",
    profileUpdateFailedRemovingTeachingMethod: "فشل تحديث الملف الشخصي في إزالة طريقة التدريس.",
    profileUpdateFailedRemovingTravelMethod: "فشل تحديث الملف الشخصي في إزالة طريقة الانتقال‬.",
    progress: "التقدم",
    propstextNotSelected: "لم يتم تحديد {{propsText}}",
    propstextSelected: "تم تحديد {{propsText}}",
    provider: "الموفر",
    providerName: "اسم الموفر",
    publish: "نشر",
    publishCourse: "نشر الدورة الدراسية",
    published: "تم النشر",
    publishEvent: "نشر الحدث",
    publishingSummary: "ملخص النشر",
    question: "السؤال",
    questionBreakdown: "تفصيل السؤال",
    quickLinks: "روابط سريعة",
    register: "التسجيل",
    registration: "تسجيل",
    registrationInformation: "معلومات التسجيل",
    registrationInformationDoesNotApplyForContractEventsToViewMoreInformationRegardingTheContractVisitTheEventDetailsTab:
        "لا تنطبق معلومات التسجيل على أحداث التعاقد. لعرض المزيد من المعلومات المتعلقة بالتعاقد، قم بزيارة علامة تبويب تفاصيل الحدث.",
    registrationLink: "رابط التسجيل",
    registrationURL: "عنوان URL للتسجيل",
    remove: "إزالة",
    removeCourseFromAnyProducts: "إزالة الدورة الدراسية من أي منتجات",
    removeInstructor: "إزالة المعلم",
    replace: "استبدال",
    replaceAnENProvider: "استبدال ‏‫موفر دورة دراسية باللغة الإنجليزية",
    replaceAnOrganization: "استبدال مؤسسة",
    replaceContent: "استبدال المحتوى",
    replaceENProvider: "استبدال ‏‫موفر الدورة الدراسية باللغة الإنجليزية",
    replaceOrganization: "استبدال المؤسسة",
    replacePackage: "استبدال الحزمة",
    replaceProduct: "استبدال المنتج",
    reportDownloads: "تنزيلات التقرير",
    reportNameReport: "{{reportName}}",
    reports: "التقارير",
    reportType: "نوع التقرير",
    required: "مطلوب",
    requiredTrainings: "التدريبات المطلوبة",
    reset: "إعادة تعيين",
    resetALL: "إعادة تعيين الكل‬",
    resetForAllLanguages: "إعادة تعيين لجميع اللغات",
    resources: "الموارد",
    response: "الاستجابة",
    result: "النتيجة",
    resumeTraining: "استئناف التدريب",
    returnToHomepage: "العودة إلى الصفحة الرئيسية",
    reviewAssessmentResults: "مراجعة نتائج التقييم",
    reviewEvaluationResults: "المراجعة الخاصة بنتائج التقييم",
    reviewTraining: "مراجعة التدريب",
    reviewTrainingContent: "مراجعة محتوى التدريب",
    rhodeIsland: "رود آيلاند",
    role: "الدور",
    roles: "الأدوار",
    runAReport: "إنشاء تقرير",
    running: "تشغيل",
    runningLMSAppVersionProcessEnvREACTAPPBUILDVERSION:
        "تشغيل إصدار تطبيق LMS: {{processEnvREACTAPPBUILDVERSION}}",
    runReport: "إنشاء التقرير",
    salesType: "نوع المبيعات",
    saturdayAbbreviated: "السبت",
    save: "حفظ",
    saveAndExit: "حفظ وخروج",
    saveChanges: "حفظ التغييرات",
    saveContent: "حفظ المحتوى",
    saveCourseDetailsChanges: "حفظ تغييرات تفاصيل الدورة الدراسية",
    saveExpirationDate: "حفظ تاريخ الانتهاء",
    savePackage: "حفظ الحزمة",
    savingAnAttemptMayResultInAnUpdateToTheLearnersProgress:
        "قد يؤدي حفظ المحاولة إلى تحديث تقدم المتعلمين.",
    schedule: "جدولة",
    scheduleSummary: "ملخص الجدول الزمني",
    score: "الدرجة",
    search: "البحث",
    searchByBadgeName: "البحث حسب اسم الشارة",
    searchByEmailNameOrId: "البحث حسب البريد الإلكتروني أو الاسم أو المُعرِّف‬",
    searchByEventNameOrId: "البحث حسب اسم الحدث أو المُعرِّف‬",
    searchByName: "البحث حسب الاسم",
    searchByNameOrId: "البحث حسب الاسم أو المُعرِّف‬",
    searchByNameOrLocation: "البحث حسب الاسم أو الموقع",
    searchByOrganizationName: "البحث حسب اسم المؤسسة",
    searchByProviderName: "البحث حسب اسم الموفر",
    searchOrFilterToSeeResults: "البحث أو التصفية لمعرفة النتائج",
    select: "تحديد",
    selectABadgeToAssociateWithThisProduct: "تحديد شارة لربطها بهذا المنتج.",
    selectADate: "تحديد تاريخ",
    selectADateRange: "تحديد نطاق التاريخ",
    selectADayThatContainsAnInstructorLedTrainingToViewMoreInformation:
        "تحديد يوم يُعقد فيه تدريب بقيادة معلم لعرض المزيد من المعلومات.",
    selectAFile: "تحديد ملف",
    selectALanguage: "تحديد لغة",
    selectAllRolesThatWillApplyToThisUser: "تحديد كافة الأدوار التي ستنطبق على هذا المستخدم.",
    selectAnAssociatedProduct: "تحديد منتج مرتبط",
    selectAnENProvider: "تحديد ‏‫موفر دورة دراسية باللغة الإنجليزية",
    selectAnIssue: "تحديد مشكلة",
    selectAnOption: "تحديد خيار",
    selectAnOrganization: "تحديد مؤسسة",
    selectAProduct: "تحديد منتج",
    selectATime: "تحديد وقت",
    selectATopic: "تحديد موضوع",
    selectContract: "تحديد التعاقد",
    selectContracts: "تحديد التعاقدات",
    selectCourses: "تحديد الدورة الدراسية",
    selected: "تم التحديد",
    selectedInstructorWillAppearHere: "سيظهر هنا المعلم المحدد.",
    selectedProductWillAppearHere: "سيظهر هنا المنتج المحدد",
    selectENProvider: "تحديد ‏‫موفر الدورة الدراسية باللغة الإنجليزية",
    selectEnProvider: "تحديد ‏‫موفر الدورة الدراسية باللغة الإنجليزية",
    selectEvents: "تحديد الأحداث",
    selectFile: "تحديد الملف",
    selectForAnInPersonEvent: "تحديد لحدث شخصي",
    selectForAVirtualEvent:
        "تحديد لحدث افتراضي (على سبيل المثال، باستخدام Microsoft Teams أو Zoom)",
    selectInstructor: "تحديد المعلم",
    selectLearners: "تحديد المتعلمين",
    selectOption: "تحديد الخيار",
    selectOrganization: "تحديد المؤسسة",
    selectOrganizations: "تحديد المؤسسات",
    selectProduct: "تحديد المنتج",
    selectProducts: "تحديد المنتجات",
    selectTimeZone: "تحديد ‏‫المنطقة الزمنية‬",
    selectToViewAnAttempt: "تحديد خيار لعرض إحدى المحاولات",
    series: "السلسلة",
    session: "الجلسة",
    session1: "الجلسة 1",
    sessionEndTimeMayNotBePriorToStartTime: "يجب ألا يسبق وقت انتهاء الجلسة وقت البدء.",
    sessionExpiresAt: "تنتهي الجلسة في:",
    sessionIdx: "الجلسة {{idx}}",
    sessionPropsSessionNumberToString: "الجلسة {{propsSessionNumberToString}}",
    sessionTimesMayNotOverlap: "لا يجوز أن تتداخل أوقات الجلسة.",
    setEmailError: "يُرجى إدخال عنوان بريد إلكتروني صالح.",
    shareMyLocationInformation: "مشاركة معلومات موقعي",
    shortDateFormat: "DD/MM/YYYY",
    shouldThisCertificateHaveAnExpirationDate: "هل يجب أن يكون لهذه الشهادة تاريخ انتهاء صلاحية؟",
    sidebar: "الشريط الجانبي",
    skipToMainContent: "الانتقال إلى المحتوى الرئيسي",
    someLocation: "بعض المواقع",
    sortOrderColumn: "عمود بترتيب تصاعدي/تنازلي",
    southCarolina: "كارولينا الجنوبية",
    southDakota: "داكوتا الجنوبية",
    spaceAtSpace: " في ",
    spanish: "اللغة الإسبانية",
    startDate: "تاريخ البدء",
    startOver: "البدء من جديد",
    startTimeAmEndTimeAm: "وقت البدء: 8:00 صباحًا - وقت الانتهاء: 11:30 صباحًا",
    startTimePmEndTimePm: "وقت البدء: 1:00 مساءً - وقت الانتهاء: 4:30 مساءً",
    stateIsRequired: "الولاية مطلوبة",
    stateSlashProvinceSlashRegion: "الولاية/المقاطعة/المنطقة",
    status: "الحالة",
    submit: "إرسال",
    submitAQuestion: "إرسال سؤال",
    submitEvaluation: "إرسال تقييم",
    submitFeedback: "إرسال ملاحظات",
    submitted: "تم الإرسال",
    success: "نجاح",
    successfullyEnrolled: "تم التسجيل بنجاح",
    summary: "ملخص",
    sundayAbbreviated: "الأحد",
    support: "الدعم",
    switchProfile: "تبديل الملف الشخصي",
    syllabus: "المنهج",
    systemInformation: "معلومات النظام",
    takeAMomentToReviewYourChangesAndPopulateTheChangeLogBeforeSaving:
        "خذ دقيقة من وقتك لمراجعة تغييراتك وملء سجل التغييرات قبل الحفظ.",
    takeAMomentToReviewYourChangesBeforeSavingOnceYouSaveAllEventParticipantsWillBeNotified:
        "خذ دقيقة من وقتك لمراجعة التغييرات قبل حفظها. بمجرد الحفظ، سيتم إخطار جميع المشاركين في الحدث.",
    takeAMomentToReviewYourCourseBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "خذ دقيقة من وقتك لمراجعة دروتك الدراسية قبل التنشيط. إذا كنت تريد إجراء أي تغييرات، فارجع إلى الصفحة المناسبة من مسيرة الدورة الدراسية.",
    takeAMomentToReviewYourEventBeforePublishingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "خذ دقيقة من وقتك لمراجعة حدثك قبل النشر. إذا كنت تريد إجراء أي تغييرات، فارجع إلى الصفحة المناسبة من مسيرة الدورة الدراسية.",
    takeAMomentToReviewYourProductBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "خذ دقيقة من وقتك لمراجعة منتجك قبل التنشيط. إذا كنت تريد إجراء أي تغييرات، فارجع إلى الصفحة المناسبة من مسيرة الدورة الدراسية.",
    takeAttendanceDateUtilsFormatDatetruecontentItemEventDateToString:
        "تسجيل الحضور {{DateUtilsFormatDatetruecontentItemEventDateToString}}",
    takeEvent: "حضور الحدث",
    tcredithoursTcreditHourCEU: "{{tcreditHours}} (1 {{tcreditHour}} = 0.1 وحدة تعليم مستمر (CEU))",
    teachingCalendar: "تقويم عملية التدريس",
    teachingContactInformation: "معلومات الاتصال الخاصة بجهة التدريس",
    teachingHistory: "سجل التدريس",
    teachingLanguages: "لغات التدريس",
    teachingMethods: "طرق التدريس",
    teachingPreferences: "تفضيلات التدريس",
    template: "نموذج",
    templateSelection: "اختيار النموذج",
    tennessee: "تينيسي",
    tenVeryLikelyOnAScaleOfOneToTen:
        'يشير الرقم "عشرة" إلى "من المرجح جدًا" على مقياس من واحد إلى عشرة',
    texas: "تكساس",
    thatIsDisplayedAsA: "يتم عرض ذلك كـ...",
    theContentFromThePreviousVersionHasBeenBroughtOverToUseAsAStartingPoint:
        "تم جلب المحتوى من الإصدار السابق لاستخدامه كنقطة بداية.",
    theEvaluationWasNotSubmittedForThisTraining: "لم يتم إرسال التقييم لهذا التدريب.",
    theEvaluationWillBecomeAvailableFollowingTheCompletionOfTheSyllabus:
        "سيصبح التقييم متاحًا بعد الانتهاء من المنهج.",
    theLastDayOfTheTraining: "اليوم الأخير من التدريب",
    thePointOfContactForThisOrganizationInCaseLearnersHaveQuestions:
        "نقطة الاتصال الخاصة بهذه المؤسسة في حالة وجود أسئلة لدى المتعلمين بخصوص لوجستيات الحدث.",
    thePreviewIsNotAvailableYet: "المعاينة ليست متاحة بعد.",
    theRecommendedClassTimeForThisEventIsProductCreditHoursHoursAndShouldNotExceedMaxCreditHoursHours:
        "يبلغ وقت الدراسة الموصى به لهذا الحدث {{productCreditHours}} ساعة ويجب ألا يتجاوز {{maxCreditHours}} ساعة.",
    thereWasAnErrorDeletingEmulationSessionToken: "حدث خطأ أثناء حذف الرمز المميز لجلسة المحاكاة",
    thereWasAnErrorEndingEmulationSession: "حدث خطأ أثناء إنهاء جلسة المحاكاة",
    thereWasAnErrorUploadingTheScormPackagePleaseTryAgain:
        "حدث خطأ أثناء تحميل حزمة scorm.  حاول مرة أخرى.",
    thereWasAnIssueAddingTheCourse: "حدثت مشكلة أثناء إضافة الدورة الدراسية.",
    thereWasAnIssueAddingTheOrganization: "حدثت مشكلة أثناء إضافة المؤسسة.",
    thereWasAnIssueAuthenticating: "حدثت مشكلة في المصادقة.",
    thereWasAnIssueCalculatingThePercentCompletePleaseRefreshThePageToViewThePercentComplete:
        "حدثت مشكلة في حساب النسبة المئوية لاكتمال المهمة. يُرجى تحديث الصفحة لعرض النسبة المئوية لاكتمال المهمة. ",
    thereWasAnIssueChangingYourPreferredLanguage: "حدثت مشكلة أثناء تغيير لغتك المفضلة.",
    thereWasAnIssueCheckingForDuplicateContractNumbers:
        "حدثت مشكلة أثناء التحقق من أرقام التعاقدات المكررة.",
    thereWasAnIssueCreatingACourse: "حدثت مشكلة أثناء إنشاء دورة دراسية.",
    thereWasAnIssueCreatingAnEvent: "حدثت مشكلة أثناء إنشاء حدث.",
    thereWasAnIssueCreatingAProduct: "حدثت مشكلة أثناء إنشاء منتج.",
    thereWasAnIssueCreatingCourseContent: "حدثت مشكلة أثناء إنشاء محتوى الدورة الدراسية.",
    thereWasAnIssueCreatingTheContract: "حدثت مشكلة أثناء إنشاء التعاقد.",
    thereWasAnIssueCreatingTheContractInformation: "حدثت مشكلة أثناء إنشاء معلومات التعاقد.",
    thereWasAnIssueCreatingTheUnit: "حدثت مشكلة أثناء إنشاء الوحدة.",
    thereWasAnIssueCreatingTheUser: "حدثت مشكلة أثناء إنشاء المستخدم.",
    thereWasAnIssueCreatingTheUserRole: "حدثت مشكلة أثناء إنشاء دور المستخدم.",
    thereWasAnIssueDeletingACourse: "حدثت مشكلة أثناء حذف إحدى الدورات الدراسية.",
    thereWasAnIssueDeletingAUnit: "حدثت مشكلة أثناء حذف إحدى الوحدات.",
    thereWasAnIssueDeletingTheAssessment: "حدثت مشكلة أثناء حذف التقييم.",
    thereWasAnIssueDeletingTheCourseContent: "حدثت مشكلة أثناء حذف محتوى الدورة الدراسية.",
    thereWasAnIssueDeletingTheEvent: "حدثت مشكلة أثناء حذف الحدث.",
    thereWasAnIssueDeletingTheEventDay: "حدثت مشكلة أثناء حذف يوم الحدث.",
    thereWasAnIssueDeletingTheEventSession: "حدثت مشكلة أثناء حذف جلسة الحدث.",
    thereWasAnIssueDeletingTheProductContent: "حدثت مشكلة أثناء حذف محتوى المنتج.",
    thereWasAnIssueDeletingTheUnit: "حدثت مشكلة أثناء حذف الوحدة.",
    thereWasAnIssueDeletingTheUnitCourse: "حدثت مشكلة أثناء حذف الدورة الدراسية للوحدة.",
    thereWasAnIssueDeletingTheUserRole: "حدثت مشكلة أثناء حذف دور المستخدم.",
    thereWasAnIssueFetchingCourseProductData: "حدثت مشكلة أثناء جلب بيانات منتج الدورة الدراسية.",
    thereWasAnIssueGeneratingAPreviewLink: "حدثت مشكلة أثناء إنشاء رابط المعاينة.",
    thereWasAnIssueLaunchingThisCourse: "حدثت مشكلة أثناء إطلاق هذه الدورة الدراسية.",
    thereWasAnIssueLoadingAssessmentResults: "حدثت مشكلة أثناء تحميل نتائج التقييم.",
    thereWasAnIssueLoadingAssessments: "حدثت مشكلة أثناء تحميل التقييمات.",
    thereWasAnIssueLoadingAttempts: "حدثت مشكلة أثناء تحميل المحاولات.",
    thereWasAnIssueLoadingAttendance: "حدثت مشكلة أثناء تحميل الحضور.",
    thereWasAnIssueLoadingContracts: "حدثت مشكلة أثناء تحميل التعاقدات.",
    thereWasAnIssueLoadingCourseContents: "حدثت مشكلة أثناء تحميل محتويات الدورة الدراسية.",
    thereWasAnIssueLoadingCourseDetails: "حدثت مشكلة أثناء تحميل تفاصيل الدورة الدراسية.",
    thereWasAnIssueLoadingCourses: "حدثت مشكلة أثناء تحميل الدورات الدراسية.",
    thereWasAnIssueLoadingEnrollments: "حدثت مشكلة في تحميل عمليات التسجيل.",
    thereWasAnIssueLoadingEvaluationDetails: "حدثت مشكلة أثناء تحميل تفاصيل التقييم.",
    thereWasAnIssueLoadingEventContent: "حدثت مشكلة أثناء تحميل محتوى الحدث.",
    thereWasAnIssueLoadingEvents: "حدثت مشكلة أثناء تحميل الأحداث.",
    thereWasAnIssueLoadingInstructors: "حدثت مشكلة أثناء تحميل المعلمين.",
    thereWasAnIssueLoadingOrganizations: "حدثت مشكلة أثناء تحميل المؤسسات.",
    thereWasAnIssueLoadingProducts: "حدثت مشكلة أثناء تحميل المنتجات.",
    thereWasAnIssueLoadingProviders: "حدثت مشكلة أثناء تحميل مقدمي الخدمات.",
    thereWasAnIssueLoadingQuestionBreakdowns: "حدثت مشكلة أثناء تحميل تفاصيل الأسئلة.",
    thereWasAnIssueLoadingTheAssessment: "حدثت مشكلة أثناء تحميل التقييم.",
    thereWasAnIssueLoadingTheBadge: "حدثت مشكلة أثناء تحميل الشارة.",
    thereWasAnIssueLoadingTheBadgeTemplateDetails: "حدثت مشكلة أثناء تحميل تفاصيل نموذج الشارة.",
    thereWasAnIssueLoadingTheBadgeTemplates: "حدثت مشكلة أثناء تحميل نماذج الشارات.",
    thereWasAnIssueLoadingTheCertificate: "حدثت مشكلة أثناء تحميل الشهادة.",
    thereWasAnIssueLoadingTheCourse: "حدثت مشكلة أثناء تحميل الدورة الدراسية.",
    thereWasAnIssueLoadingTheCourseDetails: "حدثت مشكلة في تحميل تفاصيل الدورة الدراسية.",
    thereWasAnIssueLoadingTheCurrentAnnouncement: "حدثت مشكلة أثناء تحميل الإعلان الحالي.",
    thereWasAnIssueLoadingTheEnrollment: "حدثت مشكلة أثناء تحميل التسجيل.",
    thereWasAnIssueLoadingTheEvaluation: "حدثت مشكلة في تحميل التقييم.",
    thereWasAnIssueLoadingTheEvaluationTemplates: "حدثت مشكلة أثناء تحميل نماذج التقييم.",
    thereWasAnIssueLoadingTheEvent: "حدثت مشكلة في تحميل الحدث.",
    thereWasAnIssueLoadingTheLearnerChecklist:
        "حدثت مشكلة أثناء تحميل قائمة التحقق الخاصة بالمتعلم.",
    thereWasAnIssueLoadingTheProduct: "حدثت مشكلة أثناء تحميل المنتج.",
    thereWasAnIssueLoadingTheProductDetails: "حدثت مشكلة أثناء تحميل تفاصيل المنتج.",
    thereWasAnIssueLoadingTheRegistration: "حدثت مشكلة في تحميل التسجيل.",
    thereWasAnIssueLoadingTheReportDownloads: "حدثت مشكلة أثناء تحميل تنزيلات التقرير.",
    thereWasAnIssueLoadingTheScheduleAttendance:
        "حدثت مشكلة أثناء تحميل الحاضرين في البرنامج الدراسي.",
    thereWasAnIssueLoadingTheSeededUsers: "حدثت مشكلة أثناء تحميل المستخدمين المدرجين.",
    thereWasAnIssueLoadingTheUserRoles: "حدثت مشكلة أثناء تحميل أدوار المستخدمين.",
    thereWasAnIssueLoadingTheUsers: "حدثت مشكلة أثناء تحميل المستخدمين.",
    thereWasAnIssueLoadingUnits: "حدثت مشكلة أثناء تحميل الوحدات.",
    thereWasAnIssueLoadingUser: "حدثت مشكلة أثناء تحميل المستخدم.",
    thereWasAnIssueLoadingUserInfo: "حدثت مشكلة أثناء تحميل معلومات المستخدم.",
    thereWasAnIssueLoadingUserRoles: "حدثت مشكلة في تحميل أدوار المستخدمين.",
    thereWasAnIssueMarkingThisAssessmentComplete: "حدثت مشكلة أثناء وضع علامة اكتمال هذا التقييم.",
    thereWasAnIssueProductIDWasUndefined: "حدثت مشكلة. مُعرف المنتج غير محدد",
    thereWasAnIssuePublishingTheAnnouncement: "حدثت مشكلة في نشر الإعلان.",
    thereWasAnIssueRegisteringTheLearner: "حدثت مشكلة في تسجيل المتعلم.",
    thereWasAnIssueReplacingTheZipPackage: "حدثت مشكلة في استبدال الحزمة المضغوطة.",
    thereWasAnIssueSavingTheProductSelection: "حدثت مشكلة أثناء حفظ اختيار المنتج.",
    thereWasAnIssueSettingTheThisCourseHasNoContentToggle:
        'حدثت مشكلة أثناء تعيين مفتاح التبديل "لا تحتوي هذه الدورة الدراسية على محتوى".',
    thereWasAnIssueSettingTheThisProductHasNoContentToggle:
        'حدثت مشكلة أثناء تعيين مفتاح التبديل "لا يحتوي هذا المنتج على محتوى".',
    thereWasAnIssueSubmittingTheEvaluation: "حدثت مشكلة أثناء إرسال التقييم.",
    thereWasAnIssueSwitchingProfiles: "حدثت مشكلة أثناء تبديل الملفات الشخصية.",
    thereWasAnIssueTrackingAttendance: "حدثت مشكلة في تتبع الحضور.",
    thereWasAnIssueUpdatingEnrollments: "حدثت مشكلة أثناء تحديث عمليات التسجيل.",
    thereWasAnIssueUpdatingSortOrderAfterDeletingUnit:
        "حدثت مشكلة أثناء تحديث الترتيب التصاعدي/التنازلي بعد حذف الوحدة.",
    thereWasAnIssueUpdatingTheAssessment: "حدثت مشكلة أثناء تحديث التقييم.",
    thereWasAnIssueUpdatingTheBadgeTemplate: "حدثت مشكلة أثناء تحديث نموذج الشارة.",
    thereWasAnIssueUpdatingTheCertifiateExpiration:
        "حدثت مشكلة أثناء تحديث تاريخ انتهاء صلاحية الشهادة.",
    thereWasAnIssueUpdatingTheCEUs: "حدثت مشكلة أثناء تحديث وحدات التعليم المستمر (CEU).",
    thereWasAnIssueUpdatingTheContent: "حدثت مشكلة في تحديث المحتوى.",
    thereWasAnIssueUpdatingTheContract: "حدثت مشكلة في تحديث التعاقد.",
    thereWasAnIssueUpdatingTheCourse: "حدثت مشكلة أثناء تحديث الدورة الدراسية.",
    thereWasAnIssueUpdatingTheCourseName: "حدثت مشكلة أثناء تحديث اسم الدورة الدراسية.",
    thereWasAnIssueUpdatingTheEvaluationTemplate: "حدثت مشكلة أثناء تحديث نموذج التقييم.",
    thereWasAnIssueUpdatingTheEventDay: "حدثت مشكلة أثناء تحديث يوم الحدث.",
    thereWasAnIssueUpdatingTheEventsInstructor: "حدثت مشكلة أثناء تحديث معلم الحدث.",
    thereWasAnIssueUpdatingTheFieldName: "حدثت مشكلة أثناء تحديث {{fieldName}}.",
    thereWasAnIssueUpdatingTheOrganization: "حدثت مشكلة أثناء تحديث المؤسسة.",
    thereWasAnIssueUpdatingTheProduct: "حدثت مشكلة أثناء تحديث المنتج.",
    thereWasAnIssueUpdatingTheProductName: "حدثت مشكلة أثناء تحديث اسم المنتج.",
    thereWasAnIssueUpdatingTheProductWhileStartingOver:
        "حدثت مشكلة في تحديث المنتج أثناء البدء من جديد.",
    thereWasAnIssueUpdatingTheScheduleException: "حدثت مشكلة أثناء تحديث استثناء البرنامج الدراسي.",
    thereWasAnIssueUpdatingTheSortOrder: "حدثت مشكلة أثناء تحديث الترتيب التصاعدي/التنازلي.",
    thereWasAnIssueUpdatingTheUnit: "حدثت مشكلة أثناء تحديث الوحدة.",
    thereWasAnIssueUpdatingTheUnitReceivedAnUnexpectedResult:
        "حدثت مشكلة أثناء تحديث الوحدة. تم الحصول على نتيجة غير متوقعة.",
    thereWasAnIssueWithdrawingAnEnrollment: "حدثت مشكلة أثناء سحب التسجيل.",
    thereWasAProblemCreatingANewEnrollment: "حدثت مشكلة أثناء إنشاء تسجيل جديد.",
    thereWasAProblemCreatingTheReportDownload: "حدثت مشكلة أثناء إنشاء عملية تنزيل التقرير.",
    thereWasAProblemGettingAvailableReportDownloads:
        "حدثت مشكلة أثناء الحصول على تنزيلات التقارير المتوفرة.",
    thereWasAProblemGettingAvailableReports: "حدثت مشكلة أثناء الحصول على التقارير المتوفرة.",
    thereWasAProblemGettingProductAssessments: "حدثت مشكلة أثناء الحصول على تقييمات المنتج.",
    thereWasAProblemGettingRegistration: "حدثت مشكلة أثناء الحصول على التسجيل.",
    thereWasAProblemLoadingEnrollments: "حدثت مشكلة أثناء تحميل عمليات التسجيل.",
    thereWasAProblemLoadingTheAssociatedProductsList:
        "حدثت مشكلة أثناء تحميل قائمة المنتجات المرتبطة.",
    thereWasAProblemLoadingTheContract: "حدثت مشكلة أثناء تحميل التعاقد.",
    thereWasAProblemLoadingTheEvent: "حدثت مشكلة أثناء تحميل الحدث.",
    thereWasAProblemLoadingTheOrganization: "حدثت مشكلة أثناء تحميل المؤسسة.",
    thereWasAProblemLoadingTheProfile: "حدثت مشكلة أثناء تحميل الملف الشخصي.",
    thereWasAProblemLoadingTheReportDownloads: "حدثت مشكلة أثناء تحميل تنزيلات التقارير.",
    thereWasAProblemRemovingACourseFromTheUnitWhileStartingOver:
        "حدثت مشكلة أثناء إزالة الدورة الدراسية من الوحدة أثناء البدء من جديد.",
    thereWasAProblemReturningBadgeTemplates: "حدثت مشكلة أثناء إرجاع نماذج الشارات.",
    thereWasAProblemReturningContracts: "حدثت مشكلة أثناء إرجاع التعاقدات.",
    thereWasAProblemReturningContractSummaries: "حدثت مشكلة أثناء إرجاع ملخصات التعاقدات.",
    thereWasAProblemReturningCourses: "حدثت مشكلة أثناء إرجاع الدورات الدراسية.",
    thereWasAProblemReturningEnrollmentCount: "حدثت مشكلة في إرجاع عدد التسجيلات.",
    thereWasAProblemReturningEnrollments: "حدثت مشكلة في إرجاع التسجيلات.",
    thereWasAProblemReturningEventChangeLogs: "حدثت مشكلة أثناء إرجاع سجلات تغيير الأحداث.",
    thereWasAProblemReturningEvents: "حدثت مشكلة أثناء إرجاع الأحداث.",
    thereWasAProblemReturningNotifications: "حدثت مشكلة أثناء إرجاع الإشعارات.",
    thereWasAProblemReturningOrganizations: "حدثت مشكلة أثناء إرجاع المؤسسات.",
    thereWasAProblemReturningProducts: "حدثت مشكلة أثناء إرجاع المنتجات.",
    thereWasAProblemReturningProductTrainingContent:
        "حدثت مشكلة أثناء إرجاع محتوى التدريب على المنتج.",
    thereWasAProblemReturningProductUnits: "حدثت مشكلة أثناء إرجاع وحدات المنتجات.",
    thereWasAProblemReturningRoles: "حدثت مشكلة أثناء إرجاع الأدوار.",
    thereWasAProblemReturningTheChecklist: "حدثت مشكلة أثناء إرجاع قائمة التحقق.",
    thereWasAProblemSavingTheZipPackage: "حدثت مشكلة أثناء حفظ الحزمة المضغوطة.",
    thereWasAProblemUpdatingTheAnnouncement: "حدثت مشكلة أثناء تحديث الإعلان.",
    thereWasAProblemUpdatingTheChecklist: "حدثت مشكلة أثناء تحديث قائمة التحقق.",
    thereWasAProblemUpdatingTheContent: "حدثت مشكلة أثناء تحديث المحتوى.",
    thereWasAProblemUpdatingTheContract: "حدثت مشكلة أثناء تحديث التعاقد.",
    thereWasAProblemWithCreatingProductContent: "حدثت مشكلة تتعلق بإنشاء محتوى المنتج.",
    thereWasAProblemWithManagingContent: "حدثت مشكلة تتعلق بإدارة المحتوى.",
    thereWasAProblemWithProductContentManager: "حدثت مشكلة تتعلق بمدير محتوى المنتج.",
    thereWasProblemSavingBulkEnrollments: "حدثت مشكلة أثناء حفظ التسجيلات كبيرة الحجم.",
    thereWillBeNoImpactToTheseLearnersAndTheyWillRetainAccessToTheVersionTheyCompletedUntilItExpires:
        "لن يكون هناك أي تأثير على هؤلاء المتدربين وسيحتفظون بإمكانية الوصول إلى الإصدار الذي أكملوه حتى تنتهي صلاحيته.",
    theseEventsAreForContractsOnly: "هذه الأحداث مخصصة للتعاقدات فقط.",
    theseEventsAreOpenToAllAndRunByNFPA:
        "هذه الأحداث متاحة للجميع (عامة الناس) وتديرها الجمعية الوطنية للحماية من الحرائق (NFPA).",
    theseLearnersWillBeAbleToFinishThisProductAndRetainAccessToTheCurrentVersionAssignedToThemUntilItExpires:
        "سيتمكن هؤلاء المتعلمون من إنهاء هذا المنتج والاحتفاظ بإمكانية الوصول إلى الإصدار الحالي المخصص لهم حتى انتهاء صلاحيته.",
    theSelectedFileTypeIsNotPermitted: "نوع الملف المحدد غير مسموح به.",
    thisAttemptWasManuallyEnteredByNFPA: "تم إدخال هذه المحاولة يدويًا بواسطة NFPA.",
    thisContactInformationWillBeAvailableToEventParticipantsAs:
        "ستكون معلومات جهة الاتصال هذه متاحة للمشاركين في الحدث بصفتها",
    thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizer:
        "ستكون معلومات جهة الاتصال هذه متاحة للمشاركين في الحدث بصفتها الطريقة الأساسية للاتصال بمنظم الحدث.",
    thisCourseHasNoContent: "هذه الدورة الدراسية لا تشتمل على محتوى",
    thisDataWillStayInSyncWithAnyChangesMadeToYourOrganizationsDefaultContactInfo:
        "ستظل هذه البيانات متزامنة مع أي تغييرات يتم إجراؤها على معلومات جهة الاتصال الافتراضية لمؤسستك.",
    thisEventHasNoRegistrationLimit: "هذا الحدث ليست له حدود للتسجيل",
    thisEVENTHASNOREGISTRATIONLIMITS: "هذا الحدث ليست له حدود للتسجيل",
    thisEventIsA: "هذا الحدث هو:",
    thisEventIsCurrentlyLive: "هذا الحدث مباشر حاليًا",
    thisEventWillBeCanceledAndParticipantsWillBeNotifiedThisActionCannotBeUndoneYoullBeAbleToAccessTheEventPageButWontBeAbleToEditTheEvent:
        "سيتم إلغاء هذا الحدث وسيتم إخطار المشاركين. لا يمكن التراجع عن هذا الإجراء. ستتمكن من الوصول إلى صفحة الحدث ولكن لن تتمكن من تعديل الحدث.",
    thisFileIncludesAHeaderRow: "يتضمن هذا الملف صف عناوين ",
    thisInformationIsSpecificToThisEventAndWillNotChangeOrSyncWithTheOrganizationsDefaultEventContactInformation:
        "هذه المعلومات خاصة بهذا الحدث ولن يتم تغييرها أو مزامنتها مع معلومات جهة الاتصال الافتراضية الخاصة بالحدث في المؤسسة.",
    thisInstructorCurrentlyHasNoApprovedToTeachTrainings:
        "هذا المعلم غير معتمد حاليًا لتدريس التدريبات",
    thisInstructorCurrentlyHasNoAssignedRequiredTrainings:
        "لا توجد حاليًا تدريبات مطلوبة مخصصة لهذا المعلم",
    thisInstructorCurrentlyHasNoCompletedTrainings: "هذا المعلم لم يكمل حاليًا أي تدريبات",
    thisInstructorCurrentlyHasNoPastTrainings: "هذا المعلم لم يجرِ حتى الآن أي تدريبات سابقة.",
    thisNameIsUsedOnlyInTrainingManagement: "لا يُستخدَم هذا الاسم سوى في إدارة التدريبات.",
    thisProductAlreadyHasMultipleUnitsLearningPath:
        "يحتوي هذا المنتج بالفعل على وحدات متعددة. مسار التعلّم!",
    thisRegistrationEventWillBePublishedAdvertisedOnTheNFPACalendarWebsiteAndWillBeVisibleToTheGeneralPublic:
        "سيتم نشر (الإعلان عن) حدث التسجيل هذا على موقع ويب تقويم NFPA وسيكون مرئيًا لعامة الناس.",
    thisRegistrationEventWillNotBeIncludedOnTheNFPACalendarWebsite:
        "لن يتم تضمين حدث التسجيل هذا في موقع ويب تقويم NFPA.",
    thisTrainingExpiredWithIncompleteItems:
        "انتهت صلاحية هذا التدريب مع وجود عناصر غير مكتملة. راجع ملخص التدريب لمعرفة المزيد من التفاصيل.",
    thisTrainingHasBeenCanceled: "لقد تم إلغاء هذا التدريب.",
    thisUnitIsOptional: "هذه الوحدة اختيارية.",
    thisWillImmediatelyRemoveThemFromThisTraining:
        "سيؤدي هذا إلى إزالتها على الفور من هذا التدريب.",
    thursdayAbbreviated: "الخميس",
    time: "الوقت",
    timezone: "المنطقة الزمنية",
    toEnrollInAllOtherTrainingsNavigateToTheIndividualContractOrILTEventsEnrollmentSection:
        "للتسجيل في جميع التدريبات الأخرى، انتقل إلى قسم التسجيل الفردي الخاص بالتعاقد أو الحدث المتعلق بالتدريب تحت إشراف معلم (ILT).",
    topic: "الموضوع",
    topicColon: "الموضوع:",
    topics: "المواضيع",
    topicSelection: "اختيار الموضوع",
    total: "الإجمالي",
    totalCEUs: "إجمالي وحدات التعليم المستمر (CEU):",
    totalClassTime: "إجمالي وقت الفصل الدراسي",
    totalEnrollmentCountText: "الإجمالي: {{enrollmentCountText}}",
    totalEnrollmentsTotalLearnersParticipated: "إجمالي التسجيلات / إجمالي المتعلمين المشاركين",
    totalImported: "إجمالي الواردات",
    totalNumberOfCeusAwarded: "إجمالي عدد وحدات CEU الممنوحة",
    totalNumberOfLearnersAwardedCeus: "إجمالي عدد وحدات CEU الممنوحة للمتعلمين",
    totalTrainingsConducted: "إجمالي التدريبات التي تم إجراؤها",
    totalUsedEnrollmentCount: "الإجمالي: {{usedEnrollmentCount}}",
    trainingAccessExp: "انتهاء صلاحية الالتحاق بالتدريب",
    trainingAccessExpiration: "انتهاء صلاحية الالتحاق بالتدريب",
    trainingAccessExpirationDate: "تاريخ انتهاء صلاحية الالتحاق بالتدريب",
    trainingAccessExpirationHistory: "سجل انتهاء صلاحية الالتحاق بالتدريب",
    trainingAchievements: "إنجازات التدريب",
    trainingManager: "مدير التدريب",
    trainingMaterialsOutlineReport: "تقرير مخطط مواد التدريب",
    trainingName: "اسم التدريب",
    trainingOverview: "نظرة عامة على التدريب",
    trainingRequired: "التدريب المطلوب",
    trainings: "التدريبات",
    trainingStatistics: "إحصائيات التدريب",
    trainingSummary: "ملخص التدريب",
    trainingSummaryILT: "ملخص التدريب (ILT)",
    trainingType: "نوع التدريب",
    trainTheTrainer: "تدريب المدرب",
    travelPreferences: "تفضيلات السفر",
    tuesdayAbbreviated: "الثلاثاء",
    twelcomeLearnerFirstName: "{{twelcome}} ،{{learnerFirstName}}",
    twelcomeUserFirstName: "{{twelcome}} ،{{userFirstName}}",
    type: "إدخال",
    typeSearchByCourseNameOrId: "إدخال للبحث حسب اسم الدورة الدراسية أو المعرف الخاص بها",
    typeToSearchByContractNameOrID: "إدخال للبحث حسب اسم التعاقد أو المعرف الخاص به",
    typeToSearchByContractNumber: "إدخال للبحث حسب رقم التعاقد",
    typeToSearchByCourseNameOrID: "إدخال للبحث حسب اسم الدورة الدراسية أو المعرف الخاص بها",
    typeToSearchByEventNameOrID: "إدخال للبحث حسب اسم الحدث أو المعرف الخاص به",
    typeToSearchByName: "إدخال للبحث حسب الاسم",
    typeToSearchByNameOrId: "إدخال للبحث حسب الاسم أو المعرف",
    typeToSearchByOppNumber: "إدخال للبحث حسب رقم OPP",
    typeToSearchByProductNameOrID: "إدخال للبحث حسب اسم المنتج أو المعرف الخاص به",
    typeToSearchByProductNameOrId: "إدخال للبحث حسب اسم المنتج أو المعرف الخاص به",
    typeToSearchByTrainingName: "إدخال للبحث حسب اسم التدريب",
    typeToSearchProducts: "إدخال للبحث عن المنتجات",
    unableToCreateEmulationToken: "تعذّر إنشاء رمز محاكاة.",
    unableToLoginAsSubscriber: "تعذّر تسجيل الدخول كمشترك",
    unassigned: "غير مُعيَّن",
    unassignTraining: "إلغاء تعيين التدريب",
    unauthorized: "غير مُصرَّح به",
    unit: "الوحدة",
    unitAbbreviated: "وحدة",
    unitDescription: "وصف الوحدة",
    unitDetails: "تفاصيل الوحدة",
    unitEnrollmentUnitSortOrder: "الوحدة {{enrollmentUnitSortOrder}}",
    unitName: "اسم الوحدة",
    unitSettings: "إعدادات الوحدة",
    unitUnitSortOrder: "الوحدة {{unitSortOrder}}",
    unitUpdatedSuccessfully: "تم تحديث الوحدة بنجاح.",
    unknown: "غير معروف",
    upcomingEvents: "الأحداث القادمة",
    updateContent: "تحديث المحتوى",
    updatedbygetfirstandlastnameMessage: "{{updatedByGetFirstAndLastName}} | {{message}}",
    updateExpiration: "تحديث انتهاء الصلاحية",
    updateExpirationDate: "تحديث تاريخ انتهاء الصلاحية",
    updateYourContactInformationOnNfpaOrg: "تحديث معلومات جهة الاتصال الخاصة بك على www.nfpa.org",
    updateYourLocationOnNfpaOrg: "تحديث موقعك على www.nfpa.org",
    upload: "تحميل",
    uploadACSVFile: "تحميل ملف CSV:",
    uploadAProfilePicture: "تحميل صورة الملف الشخصي",
    uploadResumeOrCV: "تحميل السيرة الذاتية أو CV",
    useDefault: "استخدام الإعداد الافتراضي",
    userHasBeenAddedSuccessfully: "تمت إضافة المستخدم بنجاح.",
    userHasBeenUpdatedSuccessfully: "تم تحديث بيانات المستخدم بنجاح.",
    userManagement: "إدارة المستخدم",
    userPreferredLanguage: "اللغة المفضلة لدى المستخدم",
    userRoles: "أدوار المستخدمين",
    users: "المستخدمون",
    useThisTemplate: "استخدم هذا القالب",
    utah: "يوتا",
    vermont: "فيرمونت",
    version: "الإصدار",
    versionVersionNumber: "الإصدار {{versionNumber}}",
    veryLIKELY: "من المحتمل جدًا",
    veryUNLIKELY: "من المستبعد جدًا",
    viewAchievements: "عرض الإنجازات",
    viewAENProvider: "عرض مزود AEN",
    viewAll: "عرض الكل",
    viewAssessmentDetail: "عرض تفاصيل التقييم",
    viewCatalog: "عرض الكتالوج",
    viewContract: "عرض التعاقد",
    viewCourse: "عرض الدورة الدراسية",
    viewENProvider: "عرض موفر الدورة الدراسية باللغة الإنجليزية",
    viewEvent: "عرض الحدث",
    viewOrganization: "عرض المؤسسة",
    viewProvider: "عرض المزود",
    viewResults: "عرض النتائج",
    viewSummary: "عرض الملخص",
    viewTheChangeHistory: "عرض سجل التغيير",
    viewTheVersionHistory: "عرض سجل الإصدار",
    viewTraining: "عرض التدريب",
    viewUnit: "عرض الوحدة",
    viewUserInfo: "عرض معلومات المستخدم",
    viewYourProfile: "عرض الملف الشخصي الخاص بك",
    virginia: "فرجينيا",
    wantToTeachMoreTopics: "هل تريد تدريس مواضيع أخرى؟",
    warning: "تحذير",
    washington: "واشنطن",
    wednesdayAbbreviated: "الأربعاء",
    welcome: "مرحبًا",
    WeNoticedThisIsAContractInPersonEvent:
        'لقد لاحظنا أن هذا حدث تعاقدي شخصي. استخدم مفتاح التبديل هذا لضبط وقت الجلسة على "طوال اليوم" عندما تكون أوقات الجلسة غير معروفة.',
    westVirginia: "فرجينيا الغربية",
    wisconsin: "ويسكونسن",
    withdraw: "استبعاد",
    withdrawALearner: "استبعاد متعلم",
    withdrawEnrollmentSuccessful: "تمت إزالة التسجيل بنجاح.",
    withdrawLearner: "استبعاد المتعلم",
    withdrawn: "مستبعَد",
    wouldYouLikeThisEventPublishedInTheNFPACalendar: "هل ترغب في نشر هذا الحدث في تقويم NFPA؟",
    wyoming: "وايومنغ",
    yes: "نعم",
    yesCancel: "نعم، أرغب في إلغاء الحدث",
    yesChange: "نعم، أرغب في تغيير الحدث",
    yesIWantToCancelThisEventAndUnderstandThisActionCannotBeUndone:
        "نعم، أريد إلغاء هذا الحدث وأدرك أنه لا يمكن التراجع عن هذا الإجراء. ",
    yesLeave: "نعم، أرغب في مغادرة الحدث",
    yesRemove: "نعم، أرغب في إزالة الحدث",
    youAreDeactivatingTheProductPleaseReviewCarefullyBeforeDeactivating:
        "أنت تقوم بإلغاء تنشيط المنتج. يرجى المراجعة بعناية قبل إلغاء التنشيط.",
    youAreEditingTheCourseDetails: "أنت تقوم بتعديل تفاصيل الدورة الدراسية.",
    youAreEmulating: "أنت تحاكي:",
    youAreGoingToCreateVersionVersionNumber: "ستقوم بإنشاء إصدار {{versionNumber}}",
    youAreGoingToEditTheCourseDetails: "ستقوم بتعديل تفاصيل الدورة الدراسية.",
    youAreGoingToEditVersionVersionNumber: "ستقوم بتعديل إصدار {{versionNumber}}",
    youAreLoggedInWithYourIdentityRoleNameRole:
        "لقد قمت بتسجيل الدخول باستخدام دور {{identityRoleName}}.",
    youAreNowEditingALiveEvent: "أنت الآن تقوم بتعديل حدث مباشر",
    youAreViewingADraftEvent: "أنت تشاهد مسودة حدث.",
    youAreViewingAnArchivedCourse: "أنت تشاهد دورة دراسية مؤرشفة.",
    youAreWithdrawingEnrollmentName: "أنت تقوم باستبعاد {{enrollmentName}}.",
    youCanAddAShortCancellationMessageThisMessageWillBeCommunicatedToParticipantsAndDisplayedInTheLMS:
        "يمكنك إضافة رسالة إلغاء قصيرة. سيتم إرسال هذه الرسالة إلى المشاركين وعرضها في نظام إدارة التعلم (LMS).",
    youCanEditTheCoursesContent: "يمكنك تعديل محتوى الدورة الدراسية.",
    youDoNotHaveAccessToThisPageBasedOnYourNFPALMSRoleTypeDisplayNamescurrentRoleProfile:
        "ليس لديك حق الوصول إلى هذه الصفحة بناءً على الملف الشخصي {{RoleTypeDisplayNamescurrentRole}} الخاص بك في NFPA LMS",
    youHaveBeenAddedOnBehalfOfContractOrganizationNameYourDataMayBeSharedWithThisOrganization:
        "لقد تمت إضافتك نيابة عن {{contractOrganizationName}}. قد تتم مشاركة بياناتك مع هذه المؤسسة.",
    youHaveBeenAddedOnBehalfOfEventOrganizationNameYourDataMayBeSharedWithThisOrganization:
        "لقد تمت إضافتك نيابة عن {{eventOrganizationName}}. قد تتم مشاركة بياناتك مع هذه المؤسسة.",
    youHaveExceededTheRecommendedAmountOfClassTime: "لقد تجاوزت مقدار وقت الفصل الدراسي الموصى به.",
    youHaveNoCurrentAnnouncements: "ليس لديك أي إعلانات حالية.",
    youHaveUnsavedChanges: "لم تحفظ التغييرات.",
    youMustChangesToZIPPackageAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheZIPPackageYouMustCreateANewCourseVersion:
        "يجب ألا يتم إجراء تغييرات على الحزمة المضغوطة عند تعديل إصدار الدورة الدراسية الحالي. لتغيير الحزمة المضغوطة، يجب عليك إنشاء إصدار جديد للدورة الدراسية.",
    youMustContentChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "يجب أن يتم تطبيق تغييرات المحتوى على جميع المتعلمين في هذا الإصدار، بغض النظر عن حالة الإكمال، ولن تؤثر على تقدم المتعلم أو تؤدي إلى بدء الدورة الدراسية من جديد.",
    youMustUploadACSVFile: "يجب عليك تحميل ملف CSV.",
    youMustUploadAJPGSmallerThan3MB: "يجب عليك تحميل ملف JPG بحجم أصغر من 3 ميجابايت.",
    youMustUploadAFileTypeSmallerThanXMB:
        "يجب عليك تحميل ملف {{FileTypes}} بحجم أصغر من {{MaxFileSize}} ميجابايت.",
    youMustUploadANewZIPPackage: "يجب عليك تحميل حزمة مضغوطة جديدة.",
    youMustUploadAPDFSmallerThan5MB: "يجب عليك تحميل ملف PDF بحجم أصغر من 5 ميجابايت.",
    youMustUploadAZIPFile: "يجب عليك تحميل ملف مضغوط.",
    youMustUploadAZipFile: "يجب عليك تحميل ملف مضغوط.",
    yourCertificateOfCompletionWith: "شهادة الإنجاز الخاصة بك لدى",
    yourOrganization: "مؤسستك",
    yourProfileAndInformationWillBeSharedWithLearnersAsAPointOfContact:
        "ستتم مشاركة الملف الشخصي الخاص بك ومعلوماتك مع المتعلمين بصفتك جهة اتصال.",
    yourReportIsRunningYoullReceiveANotificationWhenYourReportIsReady:
        "تقريرك قيد الإنشاء. ستتلقى إشعارًا عندما يكون تقريرك جاهزًا.",
    yourResponses: "إجاباتك",
    yourRole: "دورك",
    youveBeenGrantedAnExceptionToExceedMaxCreditHoursHoursForYourEvent:
        "لقد تم منحك استثناءً يتيح لك تجاوز مدة الـ {{maxCreditHours}} ساعة المخصصة لحدثك.",
    youveEnteredCEUs: "لقد قمت بإدخال 0.0 من وحدات CEU.",
    youveEnteredCeusCEUs: "لقد قمت بإدخال {{ceus}} من وحدات CEU.",
    youWereWithdrawnFromThisTrainingOn: "لقد تم استبعادك من هذا التدريب بتاريخ {{date}}.",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToLearnersThatAreCurrentlyInProgressChoosingToApplyThisVersionWillResultInResettingTheirProgress:
        "سيكون لديك خيار بشأن كيفية تطبيق هذا الإصدار الجديد على المتعلمين الذين هم قيد التقدم حاليًا. سيؤدي اختيار تطبيق هذا الإصدار إلى بدء الدورة الدراسية من جديد.",
    zipCodeIsRequired: "الرمز البريدي مطلوب",
    zipCodeSlashPostalCode: "الرمز البريدي/رمز المنطقة‬",
    zipPackage: "حزمة مضغوطة",
};

const arabicTranslations: CultureResources = {
    ...professionalArabicTranslations,
    ...placeholderArabicTranslations,
};

// #endregion Translations

// -------------------------------------------------------------------------------------------------
// #region Culture
// -------------------------------------------------------------------------------------------------

const Arabic: Culture<CultureResources> = cultureFactory(CultureCodes.ARABIC, arabicTranslations);

// #endregion Culture

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Arabic };

// #endregion Exports
