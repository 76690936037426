import React, { useEffect, useMemo, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Button, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { CountryConstants } from "constants/country-constants";
import { E164Number } from "libphonenumber-js";
import { FormPhoneInput } from "components/form/form-phone-input/form-phone-input";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { Provider } from "models/interfaces/providers/provider";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { ProviderService } from "utilities/services/providers/provider-service";
import { RouteUtils } from "utilities/route-utils";
import { StringUtils } from "andculturecode-javascript-core";
import { SelectOption } from "components/form/inputs/select/select";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { allCountries } from "country-region-data";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useLocation, useNavigate } from "react-router-dom";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useProviders } from "utilities/hooks/models/providers/use-providers";
import { useTimeZoneOptions } from "utilities/hooks/use-timezone-options";
import "./user-providers-new-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserProvidersNewPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-providers-new-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserProvidersNewPage: React.FC<UserProvidersNewPageProps> = validatePageAccess(
    AccessControlKeys.UserProvidersNewPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const navigate = useNavigate();
    const { state } = useLocation();
    const { create: createProviderApi } = ProviderService.useCreate();
    const [provider, setProvider] = useState<ProviderRecord>(new ProviderRecord());
    const [emailError, setEmailError] = useState<string>("");
    const [hasPopulatedFieldsFromApplication, setHasPopulatedFieldsFromApplication] =
        useState(false);
    const [stateRegionConstants, setStateRegionConstants] = useState<SelectOption[]>([]);
    const { timeZoneOptions: timeZones } = useTimeZoneOptions();

    // -------------------------------------------------------------------------------------------------
    // #region Validate Unique Provider Details
    // -------------------------------------------------------------------------------------------------

    const [providerNameError, setProviderNameError] = useState<string>("");
    const [netsuiteIdError, setNetsuiteIdError] = useState<string>("");
    const [nameToCheck, setNameToCheck] = useState<string>("");
    const [netsuiteIdToCheck, setNetsuiteIdToCheck] = useState<string>("");

    const { providers: providersWithName, isLoading: nameCheckInProgress } = useProviders({
        name: nameToCheck,
        searchCriteriaReady: StringUtils.hasValue(nameToCheck),
        skip: 0,
        take: 1,
    });

    const { providers: providersWithNetsuiteId, isLoading: netsuiteIdCheckInProgress } =
        useProviders({
            netsuiteId: netsuiteIdToCheck,
            searchCriteriaReady: StringUtils.hasValue(netsuiteIdToCheck),
            skip: 0,
            take: 1,
        });

    const onProviderNameBlur = (): void => {
        if (StringUtils.hasValue(provider.name)) {
            setNameToCheck(provider.name);
        }
    };

    const onNetsuiteIdBlur = (): void => {
        if (StringUtils.hasValue(provider.netsuiteId)) {
            setNetsuiteIdToCheck(provider.netsuiteId);
        }
    };

    useEffect(() => {
        if (!hasPopulatedFieldsFromApplication) {
            updateProvider({ ...state });
            setHasPopulatedFieldsFromApplication(true);
        }
    }, [hasPopulatedFieldsFromApplication, state]);

    useEffect(() => {
        if (providersWithName.length > 0) {
            setProviderNameError(t("aProviderAlreadyExistsWithThisName"));
        } else {
            setProviderNameError("");
        }
    }, [providersWithName]);

    useEffect(() => {
        if (providersWithNetsuiteId.length > 0) {
            setNetsuiteIdError(t("aProviderAlreadyExistsWithThisNetsuiteId"));
        } else {
            setNetsuiteIdError("");
        }
    }, [providersWithNetsuiteId]);

    // #endregion Validate Unique Provider Details

    // -------------------------------------------------------------------------------------------------
    // #region Event Handlers
    // -------------------------------------------------------------------------------------------------

    const handleEmailValidation = (): void => {
        validateEmail(provider?.email ?? "");
    };

    const updateProvider = (values: Partial<Provider>): void => {
        setProvider(provider.with(values));
    };

    const handleOrganizationNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProvider({ name: event.target.value });
    };

    const handleNetsuiteIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProvider({ netsuiteId: event.target.value });
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        updateProvider({ email: e.target.value });
    };

    const handlePhoneChange = (value?: E164Number | undefined): void => {
        const valueToSet = value === undefined ? "" : value;
        updateProvider({ phoneNumber: valueToSet });
    };

    const handleLocationNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProvider({ locationName: event.target.value });
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateProvider({ country: event.target.value, state: undefined });
    };

    const handleAddrOneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProvider({ addressLineOne: event.target.value });
    };

    const handleAddrTwoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProvider({ addressLineTwo: event.target.value });
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProvider({ city: event.target.value });
    };

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateProvider({ state: event.target.value });
    };

    const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProvider({ zipCode: event.target.value });
    };

    const handleTimeZoneChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateProvider({ timeZone: event.target.value });
    };

    useEffect(() => {
        const stateRegionSelectOptions: SelectOption[] = [];

        if (provider.country) {
            const stateRegions = allCountries.find((c) => c[0] === provider.country);

            stateRegions![2].forEach((region) =>
                stateRegionSelectOptions.push({ text: region[0], value: region[0] })
            );
        }

        setStateRegionConstants(stateRegionSelectOptions);
    }, [provider.country]);

    const handleAddProvider = async (): Promise<void> => {
        try {
            const createProviderResponse = await createProviderApi(provider);
            const createProviderResult = createProviderResponse?.result;
            if (createProviderResult?.resultObject == null || createProviderResult.hasErrors()) {
                throw new Error();
            }
            navigate(RouteUtils.localizePath(sitemap.admin.userManagement.enProviders.list));
            ToastManager.success(t("providerHasBeenAddedSuccessfully"));
        } catch (error) {
            ToastManager.error(t("thereWasAnIssueCreatingTheProvider"));
        }
    };

    // #endregion Event Handlers

    // -------------------------------------------------------------------------------------------------
    // #region Validation
    // -------------------------------------------------------------------------------------------------

    const validateEmail = (email: string): void => {
        if (StringUtils.hasValue(email) && !StringUtils.isValidEmail(email)) {
            setEmailError(t("pleaseEnterAValidEmailAddress"));
        } else {
            setEmailError("");
        }
    };

    const providerIsValid = useMemo(() => {
        return (
            StringUtils.isValidEmail(provider.email) &&
            StringUtils.hasValue(provider.name) &&
            emailError === "" &&
            providerNameError === "" &&
            netsuiteIdError === "" &&
            !nameCheckInProgress &&
            !netsuiteIdCheckInProgress
        );
    }, [
        emailError,
        nameCheckInProgress,
        netsuiteIdCheckInProgress,
        netsuiteIdError,
        provider.email,
        provider.name,
        providerNameError,
    ]);

    // #endregion Validation

    return (
        <SkipNavContent>
            <div className={CSS_CLASS_NAME}>
                <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                    {t("newENProvider")}
                </Heading>
                <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("basicInformation")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section__grid`}>
                        <FormTextInput
                            ariaLabelledBy={t("nameOfOrganization")}
                            autoFocus={true}
                            errorMessage={providerNameError}
                            formFieldName="organizationName"
                            id="organizationName"
                            label={t("nameOfOrganization")}
                            maxLength={60}
                            onBlur={onProviderNameBlur}
                            onChange={handleOrganizationNameChange}
                            placeholder={t("enterOrganizationName")}
                            required={true}
                            type={InputTypes.Text}
                            value={provider.name}
                        />
                        <FormTextInput
                            ariaLabelledBy={t("netsuiteID")}
                            errorMessage={netsuiteIdError}
                            formFieldName="netsuiteId"
                            id="netsuiteId"
                            label={t("netsuiteID")}
                            maxLength={60}
                            onBlur={onNetsuiteIdBlur}
                            onChange={handleNetsuiteIdChange}
                            placeholder={t("enterNetsuiteId")}
                            type={InputTypes.Text}
                            value={provider.netsuiteId}
                        />
                    </div>
                </Card>
                <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("defaultEventInformation")}
                    </Heading>
                    <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                        {t("defaultEventContactInformation")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section__grid`}>
                        <FormTextInput
                            ariaLabelledBy={t("email")}
                            errorMessage={emailError}
                            formFieldName="email"
                            id="email"
                            label={t("email")}
                            maxLength={100}
                            onBlur={handleEmailValidation}
                            onChange={handleEmailChange}
                            placeholder={t("enterEmail")}
                            required={true}
                            type={InputTypes.Email}
                            value={provider.email}
                        />
                        <FormPhoneInput
                            ariaLabelledBy={t("phone")}
                            formFieldName={t("phone")}
                            id={t("phone")}
                            label={t("phone")}
                            onChange={handlePhoneChange}
                            placeholder="(000) 000-0000"
                            type={InputTypes.Phone}
                            value={provider.phoneNumber}
                        />
                    </div>
                    <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                        {t("defaultEventLocation")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section__grid`}>
                        <FormTextInput
                            ariaLabelledBy={t("locationName")}
                            formFieldName="locationName"
                            id="locationName"
                            label={t("locationName")}
                            maxLength={100}
                            onChange={handleLocationNameChange}
                            placeholder={t("enterLocationName")}
                            type={InputTypes.Text}
                            value={provider.locationName}
                        />
                        <FormSelect
                            ariaLabelledBy={t("country")}
                            formFieldName="country"
                            id="country"
                            label={t("country")}
                            onChange={handleCountryChange}
                            options={CountryConstants}
                            placeholder={t("selectOption")}
                            value={provider.country}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section__grid`}>
                        <FormTextInput
                            ariaLabelledBy={t("addressLine1")}
                            formFieldName="addressLine1"
                            id="addressLine1"
                            label={t("addressLine1")}
                            maxLength={255}
                            onChange={handleAddrOneChange}
                            placeholder={t("enterAddressLine1")}
                            type={InputTypes.Text}
                            value={provider.addressLineOne}
                        />
                        <FormTextInput
                            ariaLabelledBy={t("addressLine2")}
                            formFieldName="addressLine2"
                            id="addressLine2"
                            label={t("addressLine2")}
                            maxLength={255}
                            onChange={handleAddrTwoChange}
                            placeholder={t("enterAddressLine2")}
                            required={false}
                            type={InputTypes.Text}
                            value={provider.addressLineTwo}
                        />
                        <FormTextInput
                            ariaLabelledBy={t("citySlashTown")}
                            formFieldName="cityTown"
                            id="cityTown"
                            label={t("citySlashTown")}
                            maxLength={255}
                            onChange={handleCityChange}
                            placeholder={t("enterCitySlashTown")}
                            type={InputTypes.Text}
                            value={provider.city}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section__grid`}>
                        <FormSelect
                            ariaLabelledBy={t("stateSlashProvinceSlashRegion")}
                            disabled={provider.country == null}
                            formFieldName="stateProvinceRegion"
                            id="stateProvinceRegion"
                            label={t("stateSlashProvinceSlashRegion")}
                            onChange={handleStateChange}
                            options={stateRegionConstants}
                            placeholder={t("selectOption")}
                            value={provider.state}
                        />
                        <FormTextInput
                            ariaLabelledBy={t("zipCodeSlashPostalCode")}
                            formFieldName="zipPostal"
                            id="zipPostal"
                            label={t("zipCodeSlashPostalCode")}
                            maxLength={10}
                            onChange={handleZipCodeChange}
                            placeholder={t("enterZipcodeSlashPostalCode")}
                            type={InputTypes.Text}
                            value={provider.zipCode}
                        />
                    </div>
                    <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                        {t("defaultEventTimezone")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section__grid`}>
                        <FormSelect
                            ariaLabelledBy={t("timezone")}
                            formFieldName="timeZone"
                            id="timeZone"
                            label={t("timezone")}
                            onChange={handleTimeZoneChange}
                            options={timeZones}
                            placeholder={t("selectTimeZone")}
                            value={provider.timeZone}
                        />
                    </div>
                </Card>
            </div>
            <div className={`${CSS_CLASS_NAME}__actions`}>
                <Button
                    linkPath={sitemap.admin.userManagement.enProviders.list}
                    style={ButtonStyle.Secondary}
                    text={t("cancel")}
                    type={ButtonType.Link}
                />
                <Button
                    disabled={!providerIsValid}
                    onClick={handleAddProvider}
                    style={ButtonStyle.Primary}
                    text={t("addEnProvider")}
                />
            </div>
        </SkipNavContent>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserProvidersNewPage };

// #endregion Exports
